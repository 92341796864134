import React from "react";
import LoadingScreen from "./LoadingScreen";
import { withTranslation } from "react-i18next";

class IndexList extends React.Component {
  constructor() {
    super();

    this.state = {
      projects: [],
      showLoading: false
    };

    this.fetchProjects = this.fetchProjects.bind(this);
    this.handleIndexCheck = this.handleIndexCheck.bind(this);
  }

  componentDidMount() {
    this.fetchProjects();
  }

  fetchProjects = () => {
    fetch("../api/project/registers", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      }
    })
      .then(response => {
        response.json().then(data => {
          this.setState({
            projects: data
          });
        });
      })
      .catch(error => {
        console.log(error);
      });
  };

  createIndex = () => {
    this.setState({
      showLoading: true
    });

    let indexs = [];

    this.state.projects.forEach(project => {
      let prjct = {
        projectID: project.id_proyecto,
        maya: project.index_maya,
        //spanish: project.index_español,
        glosa: project.index_glosado
      };

      indexs.push(prjct);
    });

    let payload = {
      projectIndex: indexs
    };

    fetch("../api/lucene/projects/index/v1", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(payload)
    })
      .then(response => {
        response.json().then(data => {
          console.log(data);
          this.setState({
            showLoading: false
          });
        });
      })
      .catch(error => {
        console.log(error);
        this.setState({
          showLoading: false
        });
      });
  };

  handleIndexCheck = (id, indexName) => evt => {
    let projects = [...this.state.projects];
    let project = projects.find(projct => projct.id_proyecto === id);
    project[indexName] = evt.target.checked;

    this.setState({
      projects: projects
    });
  };

  render() {
    return (
      <div className="w-full p-6  overflow-auto">
        {this.state.showLoading ? (
          <LoadingScreen title="Guardando..." message="Espere un momento" />
        ) : null}

        <div className="flex flex-wrap bg-white">
          <div className="flex items-center justify-end w-full sm:px-6 lg:px-8 py-2">
            <button
              className={`font-bold rounded bg-infokab-gray hover:bg-infokab-gray/90 text-white ${
                this.props.isFontBig
                  ? "text-xl px-5 py-3"
                  : "text-base px-4 py-2"
              }`}
              onClick={this.createIndex}
            >
              Indexar
            </button>
          </div>
          <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
            <div className="overflow-hidden">
              <table className="w-full">
                <thead className="bg-slate-900 border-b">
                  <tr>
                    <th
                      scope="col"
                      className={`${
                        this.props.isFontBig ? "text-xl" : "text-base"
                      } font-extrabold text-white px-6 py-4 text-center`}
                    >
                      Proyecto
                    </th>
                    <th
                      scope="col"
                      className={`${
                        this.props.isFontBig ? "text-xl" : "text-base"
                      } font-extrabold text-white px-6 py-4 text-center`}
                    >
                      Maya
                    </th>
                    {/* <th
                      scope="col"
                      className="text-base font-extrabold text-white px-6 py-4 text-center"
                    >
                      Español
                    </th> */}
                    <th
                      scope="col"
                      className={`${
                        this.props.isFontBig ? "text-xl" : "text-base"
                      } font-extrabold text-white px-6 py-4 text-center`}
                    >
                      Glosado
                    </th>
                    <th
                      scope="col"
                      className={`${
                        this.props.isFontBig ? "text-xl" : "text-base"
                      } font-extrabold text-white px-6 py-4 text-center`}
                    >
                      Estado
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.projects.map((project, index) => (
                    <tr
                      key={index}
                      className={` ${
                        index % 2 === 0
                          ? "bg-white border-b"
                          : "bg-gray-100 border-b"
                      } `}
                    >
                      <td
                        className={`${
                          this.props.isFontBig ? "text-lg" : "text-sm"
                        } text-gray-900 font-light px-6 py-4 whitespace-normal text-center`}
                      >
                        {project.nombre_proyecto}
                      </td>
                      <td
                        className={`${
                          this.props.isFontBig ? "text-lg" : "text-sm"
                        } text-gray-900 font-light px-6 py-4 whitespace-normal text-center`}
                      >
                        <input
                          type="checkbox"
                          name="maya"
                          onChange={this.handleIndexCheck(
                            project.id_proyecto,
                            "index_maya"
                          )}
                          defaultChecked={project.index_maya}
                        />
                      </td>
                      {/* <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-normal text-center">
                        <input
                          type="checkbox"
                          name="spanish"
                          onChange={this.handleIndexCheck(
                            project.id_proyecto,
                            "index_español"
                          )}
                          defaultChecked={project.index_español}
                        />
                      </td> */}
                      <td
                        className={`${
                          this.props.isFontBig ? "text-lg" : "text-sm"
                        } text-gray-900 font-light px-6 py-4 whitespace-normal text-center`}
                      >
                        <input
                          type="checkbox"
                          name="glosa"
                          onChange={this.handleIndexCheck(
                            project.id_proyecto,
                            "index_glosado"
                          )}
                          defaultChecked={project.index_glosado}
                        />
                      </td>
                      <td
                        className={`${
                          this.props.isFontBig ? "text-lg" : "text-sm"
                        } text-gray-900 font-light px-6 py-4 whitespace-normal text-center`}
                      >
                        {project.en_proceso ? "En proceso" : "Listo"}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(IndexList);

import React, { useState, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';

function DragNDrop(props) {

  const [myFiles, setMyFiles] = useState([])
  const [rejectedFiles, setRejectedFiles] = useState([])

  const onDrop = useCallback(acceptedFiles => {
    setRejectedFiles([])
    setMyFiles([...myFiles, ...acceptedFiles])
  }, [myFiles])

  const onDropRejected = useCallback(fileRejections => {
    setRejectedFiles([...rejectedFiles, ...fileRejections])
  }, [rejectedFiles])

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    onDropRejected,
    accept: {
      'image/*': ['png', 'jpg', 'jpeg'],
      'video/mp4': ['mp4'],
    },
    maxFiles: 5,
  })

  const removeFile = index => () => {
    console.log(myFiles[index])

    let data = [...myFiles]
    if (data.length > 0) {
      data.splice(index, 1);
      setMyFiles(data)
    }
  }

  const createURL = (element) => {
    const objectUrl = URL.createObjectURL(element)
    return objectUrl
  }

  const borrar = () => {
    setRejectedFiles([])
  }


  const files = myFiles.map((file, index) => (
    <div key={index} className="w-full relative bg-slate-50 border-2 items-center justify-center rounded mt-6">
      {file.type.split('/')[0] === 'image' ? (
        <>
          <img key={index} alt="" className="object-contain h-20 w-96  p-1 " src={createURL(file)} />
          <div className=" flex justify-center items-center select-none text-black ">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-4 h-4 ">
              <path strokeLinecap="round" strokeLinejoin="round" d="M6.827 6.175A2.31 2.31 0 015.186 7.23c-.38.054-.757.112-1.134.175C2.999 7.58 2.25 8.507 2.25 9.574V18a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18V9.574c0-1.067-.75-1.994-1.802-2.169a47.865 47.865 0 00-1.134-.175 2.31 2.31 0 01-1.64-1.055l-.822-1.316a2.192 2.192 0 00-1.736-1.039 48.774 48.774 0 00-5.232 0 2.192 2.192 0 00-1.736 1.039l-.821 1.316z" />
              <path strokeLinecap="round" strokeLinejoin="round" d="M16.5 12.75a4.5 4.5 0 11-9 0 4.5 4.5 0 019 0zM18.75 10.5h.008v.008h-.008V10.5z" />
            </svg>
          </div>
          <button type="button" className="absolute rounded-full -top-1 -right-1 focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium text-sm dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900" onClick={removeFile(index)}>
            <svg className="h-3 w-3" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </>
      ) : null
      }
      {file.type.split('/')[0] === 'video' ? (
        <>
          <video className=" h-20 w-96  p-1">
            <source src={createURL(file)} type="video/mp4" />
          </video>
          <div className=" flex justify-center items-center select-none text-black ">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-4 h-4">
              <path strokeLinecap="round" d="M15.75 10.5l4.72-4.72a.75.75 0 011.28.53v11.38a.75.75 0 01-1.28.53l-4.72-4.72M4.5 18.75h9a2.25 2.25 0 002.25-2.25v-9a2.25 2.25 0 00-2.25-2.25h-9A2.25 2.25 0 002.25 7.5v9a2.25 2.25 0 002.25 2.25z" />
            </svg>
          </div>
          <button type="button" className="absolute rounded-full -top-1 -right-1 focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium text-sm dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900" onClick={removeFile(index)}>
            <svg className="h-3 w-3" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </>
      ) : null

      }

    </div >
  ));

  /*const rejected = rejectedFiles.map((file, index) => (
    
    <div key={index} className="w-full relative bg-red-50 border-2 items-center justify-center rounded mt-6">
      fsfdsfd

    </div >
  ));*/

  //const rejected = rejectedFiles.length;

  const rejected = () => {
    if (rejectedFiles.length > 0) {
      console.log(rejectedFiles.length, "rechazados")
      return rejectedFiles.length
    }
    else {
      console.log("ningun rechazado")
      return "Ningun rechazado"
    }
  }


  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative my-6 mx-auto  w-96">
          {/*content*/}
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            {/* CloseButton */}
            <button type="button" className="absolute rounded-tr-lg z-10 top-0 right-0 focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium text-md dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900" onClick={props.handleClose}>
              <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>

            {/* CloseButton */}
            {/* Body */}
            <div className="relative p-6 flex-auto">

              <div {...getRootProps({ className: 'dropzone' })}>
                <input {...getInputProps()} onClick={borrar} />
                <div className=" flex justify-center items-center w-full ">
                  <label htmlFor="dropzone-file" className="flex flex-col justify-center items-center w-full h-64  rounded-lg border-2 border-gray-300 border-dashed cursor-pointer bg-white">
                    <div className="flex flex-col justify-center items-center pt-5 pb-6">
                      <svg aria-hidden="true" className={`mb-3 ${props.isFontBig ? "w-12 h-12" : "w-10 h-10"} text-gray-400`} fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path></svg>
                      <p className={`mb-2 ${props.isFontBig ? "text-lg" : "text-sm"} text-gray-500 dark:text-gray-400`}><span className="font-semibold">Click o Arrastre y suelte </span></p>
                      <p className={`${props.isFontBig ? "text-base" : "text-xs"} text-gray-500 dark:text-gray-400`}>Imágenes o Videos</p>
                    </div>

                  </label>
                </div>
              </div>
              <div className="lex justify-center items-center w-full border-current bg-white  rounded p-2 h-30 ">
                <div className="container grid grid-cols-5 gap-2 ">
                  {files}
                </div>
              </div>


              {rejectedFiles.length > 0 || myFiles.length > 5 ? (
                <span className='text-red-600 font-medium'>SOLO PUEDE SELECCIONAR HASTA 5 IMAGENES O VIDEOS</span>
              ) : null}



            </div>

            {myFiles.length > 0 ? (
              <button onClick={() => props.acceptedImages(myFiles)} className="bg-blue-500 disabled:bg-gray-500 dark:focus:ring-[#4285F4]/55 rounded-b-lg bg-cdmx-blue-light hover:bg-cdmx-blue-light/90 focus:ring-4 focus:outline-none focus:ring-[#4285F4]/50 dark:focus:ring-[#4285F4]/55 text-white font-bold py-2 px-4"
                disabled={myFiles.length > 5 ? true : false}
              >

                Aceptar
              </button>
            ) : null}


            {/* Body */}
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
}

export default DragNDrop;
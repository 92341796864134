import * as React from "react";
import { useParams } from "react-router-dom";

function Download() {
  const { file } = useParams();
  let dateStr = file.split("_").slice(-1)[0];
  let fileDate = new Date(
    dateStr.replace(/^(\d{4})(\d\d)(\d\d)(\d\d)(\d\d)$/, "$4:$5 $2/$3/$1")
  );
  let todayDate = new Date();

  const days = (date_1, date_2) => {
    let difference = date_1.getTime() - date_2.getTime();
    let TotalDays = Math.ceil(difference / (1000 * 3600 * 24));
    return TotalDays;
  };

  let numberOfDays = days(todayDate, fileDate);

  return (
    <div className="relative flex w-full px-4 py-3 h-full bg-cdmx-gray items-center justify-center">
      {numberOfDays < 20 ? (
        <div className="bg-white w-3/6 py-8 rounded shadow-lg flex flex-col items-center justify-center">
          <h1
            className="text-2xl font-semibold tracking-wider pb-2
        "
          >
            Descargar archivos
          </h1>
          <div className="flex items-center justify-center mb-2">
            <span className="flex items-center text-lg pr-2 text-cdmx-green font-medium tracking-wide">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 96 960 960"
                className="h-6 w-6 fill-current"
              >
                <path d="M160 896q-33 0-56.5-23.5T80 816V336q0-33 23.5-56.5T160 256h240l80 80h320q33 0 56.5 23.5T880 416v400q0 33-23.5 56.5T800 896H160Zm480-80h160V416H640v80h80v80h-80v80h80v80h-80v80Zm-480 0h400v-80h80v-80h-80v-80h80v-80h-80v-80H447l-80-80H160v480Zm0-400v-80 480-400Z" />
              </svg>
              <span className="pl-2">{file}.zip</span>
            </span>
            <a
              className="bg-infokab-gray hover:bg-infokab-gray/90 text-white font-bold px-4 py-2 rounded shadow"
              href={`../api/multimedia/Descargas/${file}.zip`}
              download
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 96 960 960"
                className="h-6 w-6 fill-current"
              >
                <path d="M240 896q-33 0-56.5-23.5T160 816V696h80v120h480V696h80v120q0 33-23.5 56.5T720 896H240Zm240-160L280 536l56-58 104 104V256h80v326l104-104 56 58-200 200Z" />
              </svg>
            </a>
          </div>
        </div>
      ) : (
        <div className="bg-white w-3/6 py-8 rounded shadow-lg flex flex-col items-center justify-center">
          <h1
            className="text-2xl font-semibold tracking-wider pb-2
        "
          >
            El enlace ha caducado
          </h1>
          <div className="flex items-center justify-center mb-2">
            <span className="flex items-center text-lg pr-2 text-cdmx-green font-medium tracking-wide">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 96 960 960"
                className="h-12 w-12 fill-current"
              >
                <path d="m40 936 440-760 440 760H40Zm138-80h604L480 336 178 856Zm302-40q17 0 28.5-11.5T520 776q0-17-11.5-28.5T480 736q-17 0-28.5 11.5T440 776q0 17 11.5 28.5T480 816Zm-40-120h80V496h-80v200Zm40-100Z" />
              </svg>
            </span>
          </div>
        </div>
      )}
    </div>
  );
}

export default Download;

import React from "react";
import { Link } from "react-router-dom";
import { format } from "date-fns";
import Modal from "./Modal";
import ModalMultimedia from "./ModalMultimedia";
import LoadingScreen from "./LoadingScreen";
import { withTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
//import "./SwiperStyles.css";

// import required modules
import { Navigation } from "swiper";
import DragNDrop from "./DragNDrop";
import { LazyLoadImage } from "react-lazy-load-image-component";

class Projects extends React.Component {
  constructor() {
    super();

    this.state = {
      projects: [],
      modalShow: false,
      errorList: [],
      showModalEaf: false,
      projectSelected: null,
      eaf: null,
      showModalMultimedia: false,
      multimedia: null,
      images: [],
      showMultimedia: false,
      multimediaShow: "",
      multimediaType: "",
      projectToShow: [],
      showModalReportMedia: false,
      showModalAlertIndexProject: false,
      showModalAlertDeleteProject: false,
      showModalAlertGlosaToEafProject: false,
      message_success: "",
      message_loading: "",
      reportMediaMsg: "",
      permisos: JSON.parse(localStorage.getItem("permisos")),
      rol_user: localStorage.getItem("rol"),
      showLoading: false,
      showModalAlertSuccessIndex: false
    };

    this.fetchProjects = this.fetchProjects.bind(this);
    this.showModal = this.showModal.bind(this);
    this.hideModal = this.hideModal.bind(this);
  }

  componentDidMount() {
    console.log(this.state);
    this.fetchProjects();
    //this.fetchGallery();
  }

  fetchProjects = () => {
    fetch("../api/project/registers", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      }
    })
      .then(response => {
        response.json().then(data => {
          console.log({ data });
          data.forEach(project => {
            project.collapsed = false;
          });
          this.setState({
            projects: data
          });
        });
      })
      .catch(error => {
        console.log(error);
      });
  };

  formatDate = dateProject => {
    const date = new Date(dateProject);
    const dateOffset = new Date(
      date.valueOf() + date.getTimezoneOffset() * 60 * 1000
    );

    return format(dateOffset, "dd/MM/yyyy");
  };

  formatCDate = dateProject => {
    const date = new Date(dateProject);
    const dateOffset = new Date(
      date.valueOf() - date.getTimezoneOffset() * 60 * 1000
    );

    return format(dateOffset, "dd/MM/yyyy");
  };

  collapseProject = projectId => () => {
    let projects = [...this.state.projects];
    let project = projects.find(projct => projct.id_proyecto === projectId);
    project.collapsed = !project.collapsed;

    this.setState({
      projects: projects
    });
  };

  fetchErrorList = projectId => () => {
    let payload = {
      id: projectId
    };

    fetch("../api/report/registers", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(payload)
    })
      .then(response => {
        response.json().then(data => {
          /* data.registers.push({
            fecha_creacion: "2022-10-26 13:12:27.211327",
            id: "2",
            id_proyecto: "10",
            reporte: "Prueba",
            titulo: "Error prueba",
          }); */
          this.setState({
            errorList: data.registers
          });
          this.showModal();
        });
      })
      .catch(error => {
        console.log(error);
      });
  };

  showModal = () => {
    this.setState({ modalShow: true });
  };

  hideModal = () => {
    this.setState({ modalShow: false });
  };

  actionOne = () => {
    console.log("action 1");
  };

  actionTwo = () => {
    console.log("action 2");
  };

  formatErrorDate = dateProject => {
    const date = new Date(dateProject);
    const dateOffset = new Date(
      date.valueOf() - date.getTimezoneOffset() * 60 * 1000
    );

    return format(dateOffset, "dd/MM/yyyy hh:mm a");
  };

  parseFileUrl = (path, file, type) => {
    let basePath = path.split("Files")[1];
    return basePath + file + "." + type;
    // if (type === "eaf") {
    //   return basePath + file + ".eaf";
    // } else {
    //   return basePath + file + ".wav";
    // }
  };

  playMedia = project => () => {
    let projects = [...this.state.projects];
    let projectSelected = projects.find(
      projct => projct.id_proyecto === project.id_proyecto
    );

    projectSelected.isPlaying = true;

    this.setState({
      projects: projects
    });

    let audio = document.getElementById(projectSelected.nombre_proyecto);
    audio.play();
  };

  pauseMedia = project => () => {
    let projects = [...this.state.projects];
    let projectSelected = projects.find(
      projct => projct.id_proyecto === project.id_proyecto
    );

    projectSelected.isPlaying = false;

    this.setState({
      projects: projects
    });

    let audio = document.getElementById(projectSelected.nombre_proyecto);
    audio.pause();
  };

  openEafModal = project => () => {
    this.setState({
      showModalEaf: true,
      projectSelected: project
    });
  };

  hideEafModal = () => {
    this.setState({
      showModalEaf: false,
      projectSelected: null
    });
  };

  handleEafChange = event => {
    this.setState({
      eaf: event.target.files[0]
    });
  };

  updateEafFile = () => () => {
    this.setState({
      showLoading: true,
      showModalEaf: false
    });

    const formData = new FormData();
    formData.append("eaf", this.state.eaf);
    formData.append("projectName", this.state.projectSelected.nombre_proyecto);
    formData.append("uuid", this.state.projectSelected.id_usuario);
    formData.append("id", this.state.projectSelected.id_proyecto);

    const requestOptions = {
      method: "POST",
      body: formData
    };

    fetch("../api/upload/update/eaf", requestOptions)
      .then(response => {
        response.json().then(data => {
          this.setState({
            showLoading: false,
            showModalAlertSuccessIndex: true,
            message_success: "Archivo eaf actualizado"
          });

          console.log(data);
          this.hideEafModal();
        });
      })
      .catch(error => {
        console.log(error);
      });
  };

  openMultimediaModal = project => () => {
    this.setState({
      showModalMultimedia: true,
      projectSelected: project
    });
  };

  hideMultimediaModal = () => {
    this.setState({
      showModalMultimedia: false,
      projectSelected: null
    });
  };

  handleMultimediaChange = event => {
    this.setState({
      multimedia: event.target.files[0]
    });
  };

  updateMultimediaFile = () => () => {
    this.setState({
      showLoading: true,
      showModalMultimedia: false
    });

    const formData = new FormData();
    formData.append("multimedia", this.state.multimedia);
    formData.append("projectName", this.state.projectSelected.nombre_proyecto);
    formData.append("uuid", this.state.projectSelected.id_usuario);
    formData.append("id", this.state.projectSelected.id_proyecto);

    const requestOptions = {
      method: "POST",
      body: formData
    };

    fetch("../api/upload/update/multimedia", requestOptions)
      .then(response => {
        response.json().then(data => {
          this.setState({
            showLoading: false,
            showModalAlertSuccessIndex: true,
            message_success: "Archivo multimedia actualizado"
          });
          this.hideMultimediaModal();
        });
      })
      .catch(error => {
        console.log(error);
      });
  };

  handleOpenModalImage = project => () => {
    console.log("HNADLER");
    if (this.state.images.length < 5) {
      this.setState({
        showModalImage: true,
        projectSelected: project
      });
    } else {
      this.setState(
        {
          message: "Solo puede seleccionar 5 imágenes"
        },
        () => {
          this.setState({
            showModal: true,
            showModalImage: false,
            projectSelected: null
          });
        }
      );
    }
  };

  handleCloseModalImage = () => {
    this.setState({ showModalImage: false });
  };

  getImageFolder = (ruta_trabajo, imagen) => {
    let src = ruta_trabajo.split("Files")[1] + "Images/" + imagen;
    return src;
  };

  getVideoFolder = (ruta_trabajo, video) => {
    let src = ruta_trabajo.split("Files")[1] + "Video/" + video;
    return src;
  };

  showFullImage = (src, type) => {
    //console.log(src, index, project);
    console.log(src, type);
    this.setState(
      {
        multimediaShow: src,
        multimediaType: type
        //projectToShow: project,
      },
      () => {
        this.setState({
          showMultimedia: true
        });
      }
    );
  };

  hideGalleryMultimediaModal = () => {
    this.setState({
      multimediaShow: "",
      multimediaType: "",
      projectToShow: [],
      showMultimedia: false
    });
  };

  acceptedImages = incomingImages => {
    this.setState({
      showLoading: true,
      showModalImage: false
    });

    const formData = new FormData();
    formData.append("projectName", this.state.projectSelected.nombre_proyecto);
    formData.append("uuid", this.state.projectSelected.id_usuario);
    formData.append("id", this.state.projectSelected.id_proyecto);
    for (let key of Object.keys(incomingImages)) {
      if (key !== "length") {
        formData.append("images", incomingImages[key]);
      }
    }

    const requestOptions = {
      method: "POST",
      body: formData
    };

    fetch("../api/upload/update/images", requestOptions)
      .then(response => {
        response.json().then(data => {
          this.setState(
            {
              showLoading: false,
              showModalAlertSuccessIndex: true,
              message_success: "Archivo multimedia guardado"
            },
            () => {
              this.fetchProjects();
            }
          );
        });
      })
      .catch(error => {
        console.log(error);
      });
  };

  deleteGallery = dir => {
    let payload = {
      route: dir
    };
    fetch("../api/images/deleteImage", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(payload)
    })
      .then(response => {
        response.json().then(data => {
          if (data) {
            this.fetchProjects();
          }
        });
      })
      .catch(error => {
        console.log(error);
      });
  };

  downloadErrorFile = () => {
    console.log(this.state.errorList);

    let project = this.state.projects.find(
      projct => projct.id_proyecto === this.state.errorList[0].id_proyecto
    );

    let wordErrors = this.state.errorList.filter(
      error => error.tipo === "word"
    );

    let wErrors = [];

    wordErrors.forEach(error => {
      const index = error.reporte.lastIndexOf(".");
      let report = error.reporte.slice(0, index).split(" ");
      console.log(report);

      let obj = {
        documento: project.nombre_proyecto,
        anotacion: report[9],
        palabra: report[2],
        comentario: error.comentario
      };

      wErrors.push(obj);
    });

    let docErrors = this.state.errorList.filter(
      error => error.tipo === "document"
    );

    let dErrors = [];

    docErrors.forEach(error => {
      let obj = {
        documento: project.nombre_proyecto,
        comentario: error.comentario
      };

      dErrors.push(obj);
    });

    let annotErrors = this.state.errorList.filter(
      error => error.tipo === "annotation"
    );

    let aErrors = [];

    annotErrors.forEach(error => {
      const index = error.reporte.lastIndexOf(".");
      let report = error.reporte.slice(0, index).split(" ");
      let obj = {
        anotacion: report[4],
        original: error.anotacion,
        modificado: error.comentario
      };

      aErrors.push(obj);
    });

    let mediaErrors = this.state.errorList.filter(
      error => error.tipo === "media"
    );

    let mErrors = [];

    mediaErrors.forEach(error => {
      let obj = {
        multimedia: project.nombre_proyecto,
        comentario: error.comentario
      };

      mErrors.push(obj);
    });

    let downloadObj = {
      [project.nombre_proyecto]: {
        palabra: wErrors,
        documento: dErrors,
        multimedia: mErrors,
        anotacion: aErrors
      }
    };

    const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
      JSON.stringify(downloadObj, undefined, 4)
    )}`;

    const link = document.createElement("a");
    link.href = jsonString;
    link.download = `errores_${project.nombre_proyecto}.json`;

    link.click();
  };

  showReporMediaModal = project => () => {
    this.setState({
      showModalReportMedia: true,
      projectSelected: project
    });
  };

  hideReportMediaModal = () => {
    this.setState({
      showModalReportMedia: false,
      projectSelected: null
    });
  };

  updateReportMediaMsg = e => {
    this.setState({
      reportMediaMsg: e.target.value
    });
  };

  saveMultimediaReport = () => {
    let payload = {
      id_proyecto: this.state.projectSelected.id_proyecto,
      titulo: "REPORTE DE ERROR EN MULTIMEDIA",
      reporte: `El archivo ${this.state.projectSelected.nombre_proyecto}.wav ha sido reportado.`,
      comentario: this.state.reportMediaMsg,
      tipo: "media"
    };

    fetch("../../../api/report/create", {
      method: "POST",
      body: JSON.stringify(payload),
      headers: {
        "Content-Type": "application/json"
      }
    })
      .then(response => {
        response.json().then(data => {
          this.hideReportMediaModal();
          this.setState({
            showLoading: false
          });
        });
      })
      .catch(error => {
        console.log(error);
        this.setState({
          showLoading: false
        });
      });
  };

  showAlertIndexProject = project => evt => {
    this.setState(
      {
        projectSelected: project
      },
      () => {
        this.setState({
          showModalAlertIndexProject: true
        });
      }
    );
  };

  hideAlertIndexModal = () => {
    this.setState({
      showModalAlertIndexProject: false,
      showModalAlertGlosaToEafProject: false,
      showModalAlertDeleteProject: false,
      projectSelected: null
    });
  };

  indexProjectRegister = evt => {
    this.setState({
      showLoading: true,
      showModalAlertIndexProject: false,
      message_loading: "Creando indice..."
    });

    let body = {
      projectID: this.state.projectSelected.id_proyecto
    };

    fetch(`../api/lucene/index`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(body)
    })
      .then(response => {
        response.json().then(data => {
          this.setState(
            {
              showLoading: false,
              message_success: "Finalizado el indexado",
              message_loading: ""
            },
            () => {
              this.showAlertSucessIndexProject();
            }
          );
        });
      })
      .catch(error => {
        console.log(error);
      });
  };

  showAlertSucessIndexProject = evt => {
    this.setState(
      {
        projectSelected: null,
        showModalAlertIndexProject: false
      },
      () => {
        this.setState({
          showModalAlertSuccessIndex: true
        });
      }
    );
  };

  hideAlertSuccessIndexModal = () => {
    this.setState({
      showModalAlertSuccessIndex: false,
      projectSelected: null
    });
  };

  showAlertDeleteProject = project => evt => {
    this.setState(
      {
        projectSelected: project
      },
      () => {
        this.setState({
          showModalAlertDeleteProject: true
        });
      }
    );
  };

  hideAlertDeleteModal = () => {
    this.setState({
      showModalAlertDeleteProject: false,
      projectSelected: null
    });
  };

  deleteProjectRegister = evt => {
    this.setState({
      showLoading: true,
      showModalAlertDeleteProject: false,
      message_loading: "Eliminando proyecto..."
    });

    let body = {
      projectID: this.state.projectSelected.id_proyecto,
      projectName: this.state.projectSelected.nombre_proyecto
    };

    console.log("ELIMINAR PROYECTO with ", body);
    fetch(`../api/project/delete`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(body)
    })
      .then(response => {
        response.json().then(data => {
          this.setState(
            {
              showLoading: false,
              message_success: "Se elimino correctamente el proyecto",
              message_loading: ""
            },
            () => {
              this.showAlertSucessIndexProject();
              this.fetchProjects();
            }
          );
        });
      })
      .catch(error => {
        console.log(error);
      });
  };

  showAlertGlosaToEafProject = project => evt => {
    this.setState(
      {
        projectSelected: project
      },
      () => {
        this.setState({
          showModalAlertGlosaToEafProject: true
        });
      }
    );
  };

  hideAlertGlosaToEafModal = () => {
    this.setState({
      showModalAlertGlosaToEafProject: false,
      projectSelected: null
    });
  };

  glosaToEafProjectRegister = evt => {
    this.setState({
      showLoading: true,
      showModalAlertGlosaToEafProject: false
    });

    let body = {
      projectID: this.state.projectSelected.id_proyecto,
      filePath:
        this.state.projectSelected.ruta_trabajo +
        this.state.projectSelected.nombre_proyecto +
        ".eaf"
    };

    console.log("GLOSA TO EAF ", body);
    fetch(`../api/glosa/saved/annotation/eaf`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(body)
    })
      .then(response => {
        response.json().then(data => {
          this.setState(
            {
              showLoading: false,
              message_success: "Se descargo la información correctamente",
              message_loading: "Descargando información al eaf..."
            },
            () => {
              this.showAlertSucessIndexProject();
            }
          );
        });
      })
      .catch(error => {
        console.log(error);
      });
  };

  formatProjectName = project => {
    return project.slice(0, project.lastIndexOf("_"));
  };

  getType = (ruta, element, index) => {
    //console.log(element.includes("image"))
    if (element.includes("image")) {
      return (
        <SwiperSlide key={index} className="w-full h-full p-1">
          <div className="border-2 relative">
            <LazyLoadImage
              key={index}
              alt=""
              className="object-contain h-20 w-full p-1 cursor-pointer"
              src={`../api/multimedia${this.getImageFolder(ruta, element)}`}
              onClick={() => {
                this.showFullImage(
                  `../api/multimedia${this.getImageFolder(ruta, element)}`,
                  "imagen"
                );
              }}
            />
            {/* <img
              key={index}
              alt=""
              className="object-contain h-20 w-full  p-1"
              src={`../api/multimedia${this.getImageFolder(ruta, element)}`}
              onClick={() => {
                this.showFullImage(
                  `../api/multimedia${this.getImageFolder(ruta, element)}`
                );
              }}
            /> */}
            <div className="flex justify-center items-center select-none text-black">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-4 h-4 "
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6.827 6.175A2.31 2.31 0 015.186 7.23c-.38.054-.757.112-1.134.175C2.999 7.58 2.25 8.507 2.25 9.574V18a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18V9.574c0-1.067-.75-1.994-1.802-2.169a47.865 47.865 0 00-1.134-.175 2.31 2.31 0 01-1.64-1.055l-.822-1.316a2.192 2.192 0 00-1.736-1.039 48.774 48.774 0 00-5.232 0 2.192 2.192 0 00-1.736 1.039l-.821 1.316z"
                />
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M16.5 12.75a4.5 4.5 0 11-9 0 4.5 4.5 0 019 0zM18.75 10.5h.008v.008h-.008V10.5z"
                />
              </svg>
            </div>
            <button
              type="button"
              className="absolute rounded-full -top-1 -right-1 focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium text-sm dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900"
              onClick={() =>
                this.deleteGallery(
                  `../api/multimedia${this.getImageFolder(ruta, element)}`
                )
              }
            >
              <svg
                className="h-3 w-3"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
        </SwiperSlide>
      );
    }

    if (element.includes("video")) {
      return (
        <SwiperSlide key={index} className="w-full h-full p-1">
          <div className="border-2 relative">
            <video
              className="object-contain h-20 w-full  p-1 "
              onClick={() => {
                this.showFullImage(
                  `../api/multimedia${this.getVideoFolder(ruta, element)}`,
                  "video"
                );
              }}
            >
              <source
                src={`../api/multimedia${this.getVideoFolder(ruta, element)}`}
                type="video/mp4"
              />
            </video>
            <div className=" flex justify-center items-center select-none text-black ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-4 h-4"
              >
                <path
                  strokeLinecap="round"
                  d="M15.75 10.5l4.72-4.72a.75.75 0 011.28.53v11.38a.75.75 0 01-1.28.53l-4.72-4.72M4.5 18.75h9a2.25 2.25 0 002.25-2.25v-9a2.25 2.25 0 00-2.25-2.25h-9A2.25 2.25 0 002.25 7.5v9a2.25 2.25 0 002.25 2.25z"
                />
              </svg>
            </div>
            <button
              type="button"
              className="absolute rounded-full -top-1 -right-1 focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium text-sm dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900"
              onClick={() =>
                this.deleteGallery(
                  `../api/multimedia${this.getVideoFolder(ruta, element)}`
                )
              }
            >
              <svg
                className="h-3 w-3"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
        </SwiperSlide>
      );
    }
  };

  render() {
    const { t } = this.props;
    return (
      <div className="w-full px-4 py-3 h-full overflow-auto ">
        {this.state.showLoading ? (
          <LoadingScreen
            title={this.state.message_loading}
            message="Esto tomará unos segundos, por favor no cierre esta página."
          />
        ) : null}

        <div className="flex flex-wrap">
          {this.state.projects.map((project, index) => {
            return (
              <div
                key={index}
                className="px-2 mb-4 w-full md:w-4/12 break-all "
              >
                <div
                  className={`bg-white shadow-lg rounded-md px-4 py-4 ${
                    project.total_de_reportes > 0
                      ? "border-2 border-cdmx-red"
                      : ""
                  }`}
                >
                  <div
                    className={`font-medium tracking-wide pb-2 ${
                      this.props.isFontBig ? "text-2xl" : "text-lg"
                    } text-cdmx-green-dark`}
                  >
                    <span>
                      {this.formatProjectName(project.nombre_proyecto)}
                    </span>
                  </div>
                  <div className="flex flex-col pb-2">
                    <div
                      className={`flex justify-end space-x-2 font-medium tracking-wide ${
                        this.props.isFontBig ? "text-lg" : "text-sm"
                      } pb-1`}
                    >
                      <span>
                        {t(
                          "proyectos.titulos.fecha_de_archivo",
                          "Fecha de archivo"
                        )}
                        :
                      </span>
                      <span>{this.formatDate(project.fecha_archivo)}</span>
                    </div>
                    <div
                      className={`flex justify-end space-x-2 font-medium tracking-wide ${
                        this.props.isFontBig ? "text-lg" : "text-sm"
                      } pb-2`}
                    >
                      <span>
                        {t(
                          "proyectos.titulos.fecha_de_subida",
                          "Fecha de subida"
                        )}
                        :
                      </span>
                      <span>{this.formatCDate(project.fecha_creacion)}</span>
                    </div>
                    <div className="flex justify-between mb-1">
                      <span
                        className={`${
                          this.props.isFontBig ? "text-xl" : "text-base"
                        } font-medium tracking-wide text-gray-700`}
                      >
                        {t("proyectos.titulos.progreso", "Progreso")}
                      </span>
                      <span
                        className={`${
                          this.props.isFontBig ? "text-xl" : "text-base"
                        } font-medium tracking-wide text-gray-700`}
                      >
                        {project.anotaciones_guardadas > 0
                          ? (
                              (project.anotaciones_guardadas * 100) /
                              project.total_de_anotaciones
                            ).toFixed(2)
                          : "0"}
                        %
                      </span>
                    </div>
                    <div className="w-full bg-gray-700 rounded-full h-3">
                      <div
                        className="bg-cdmx-green h-3 rounded-full"
                        style={{
                          width: `${
                            project.anotaciones_guardadas > 0
                              ? (project.anotaciones_guardadas * 100) /
                                project.total_de_anotaciones
                              : 0
                          }%`
                        }}
                      ></div>
                    </div>
                  </div>
                  <div
                    className={`flex flex-col pb-2 ${
                      this.props.isFontBig ? "text-lg" : "text-sm"
                    }`}
                  >
                    <div className="flex justify-between break-normal text-center">
                      <div className="flex items-center">
                        <span className="text-cdmx-blue-dark">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="w-6 h-6 fill-current"
                          >
                            <path d="M10.275 12q-.7 0-1.15-.525-.45-.525-.325-1.225l.3-1.8q.2-1.075 1.013-1.762Q10.925 6 12 6q1.1 0 1.913.688.812.687 1.012 1.762l.3 1.8q.125.7-.325 1.225-.45.525-1.15.525Zm.6-2h2.275l-.2-1.225q-.05-.35-.325-.563Q12.35 8 12 8t-.613.212q-.262.213-.312.563ZM3.1 12.975q-.575.025-.987-.225-.413-.25-.538-.775-.05-.225-.025-.45t.125-.425q0 .025-.025-.1-.05-.05-.25-.6-.05-.3.075-.575.125-.275.325-.475l.05-.05q.05-.475.388-.8.337-.325.837-.325.075 0 .475.1l.075-.025q.125-.125.325-.188Q4.15 8 4.375 8q.275 0 .488.087.212.088.337.263.025 0 .038.013.012.012.037.012.35.025.613.212.262.188.387.513.05.175.037.338-.012.162-.062.312 0 .025.025.1.175.175.275.387.1.213.1.438 0 .1-.15.525-.025.05 0 .1l.05.4q0 .525-.437.9-.438.375-1.063.375ZM20 13q-.825 0-1.413-.588Q18 11.825 18 11q0-.3.087-.562.088-.263.238-.513l-.7-.625q-.25-.2-.087-.5.162-.3.462-.3h2q.825 0 1.413.587Q22 9.675 22 10.5v.5q0 .825-.587 1.412Q20.825 13 20 13ZM0 18v-1.575q0-1.1 1.113-1.763Q2.225 14 4 14q.325 0 .625.012.3.013.575.063-.35.5-.525 1.075-.175.575-.175 1.225V18Zm6 0v-1.625q0-1.625 1.663-2.625 1.662-1 4.337-1 2.7 0 4.35 1 1.65 1 1.65 2.625V18Zm14-4q1.8 0 2.9.662 1.1.663 1.1 1.763V18h-4.5v-1.625q0-.65-.163-1.225-.162-.575-.487-1.075.275-.05.563-.063Q19.7 14 20 14Zm-8 .75q-1.425 0-2.55.375-1.125.375-1.325.875H15.9q-.225-.5-1.338-.875Q13.45 14.75 12 14.75ZM12 16Zm.025-7Z" />
                          </svg>
                        </span>
                        <span className="ml-2">
                          {JSON.parse(project.hablantes).length}{" "}
                          {t("proyectos.titulos.hablantes", "hablantes")}
                        </span>
                      </div>
                      <div className="flex items-center">
                        <span className="text-cdmx-blue-dark">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="w-6 h-6 fill-current"
                          >
                            <path d="M8 17q.425 0 .713-.288Q9 16.425 9 16t-.287-.713Q8.425 15 8 15t-.713.287Q7 15.575 7 16t.287.712Q7.575 17 8 17Zm0-4q.425 0 .713-.288Q9 12.425 9 12t-.287-.713Q8.425 11 8 11t-.713.287Q7 11.575 7 12t.287.712Q7.575 13 8 13Zm0-4q.425 0 .713-.288Q9 8.425 9 8t-.287-.713Q8.425 7 8 7t-.713.287Q7 7.575 7 8t.287.712Q7.575 9 8 9Zm3 8h6v-2h-6Zm0-4h6v-2h-6Zm0-4h6V7h-6ZM5 21q-.825 0-1.413-.587Q3 19.825 3 19V5q0-.825.587-1.413Q4.175 3 5 3h14q.825 0 1.413.587Q21 4.175 21 5v14q0 .825-.587 1.413Q19.825 21 19 21Zm0-2h14V5H5v14ZM5 5v14V5Z" />
                          </svg>
                        </span>
                        <span className="ml-2">
                          {project.anotaciones_guardadas} /{" "}
                          {project.total_de_anotaciones}{" "}
                          {t("proyectos.titulos.anotaciones", "anotaciones")}
                        </span>
                      </div>
                      <div
                        className="flex cursor-pointer items-center"
                        onClick={this.fetchErrorList(project.id_proyecto)}
                      >
                        <span className="text-cdmx-red">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="w-6 h-6 fill-current"
                          >
                            <path d="M12 17q.425 0 .713-.288Q13 16.425 13 16t-.287-.713Q12.425 15 12 15t-.712.287Q11 15.575 11 16t.288.712Q11.575 17 12 17Zm-1-4h2V7h-2Zm1 9q-2.075 0-3.9-.788-1.825-.787-3.175-2.137-1.35-1.35-2.137-3.175Q2 14.075 2 12t.788-3.9q.787-1.825 2.137-3.175 1.35-1.35 3.175-2.138Q9.925 2 12 2t3.9.787q1.825.788 3.175 2.138 1.35 1.35 2.137 3.175Q22 9.925 22 12t-.788 3.9q-.787 1.825-2.137 3.175-1.35 1.35-3.175 2.137Q14.075 22 12 22Zm0-2q3.35 0 5.675-2.325Q20 15.35 20 12q0-3.35-2.325-5.675Q15.35 4 12 4 8.65 4 6.325 6.325 4 8.65 4 12q0 3.35 2.325 5.675Q8.65 20 12 20Zm0-8Z" />
                          </svg>
                        </span>
                        <span className="ml-2">
                          {project.total_de_reportes}{" "}
                          {t("proyectos.titulos.errores", "errores")}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col pb-2">
                    <span
                      className={`mb-1 font-bold tracking-wider text-gray-700 ${
                        this.props.isFontBig ? "text-xl" : "text-base"
                      }`}
                    >
                      {t("proyectos.titulos.archivos", "Archivos")}:
                    </span>
                    <div className="flex text-sm items-center justify-between">
                      <div className="flex items-center">
                        <span className="text-gray-500">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="w-6 h-6 fill-current"
                          >
                            <path d="M5 19h14V9.825L14.175 5H5v14Zm0 2q-.825 0-1.413-.587Q3 19.825 3 19V5q0-.825.587-1.413Q4.175 3 5 3h10l6 6v10q0 .825-.587 1.413Q19.825 21 19 21Zm2-4h10v-2H7Zm0-4h10v-2H7Zm0-4h7V7H7ZM5 19V5v14Z" />
                          </svg>
                        </span>
                        <span
                          className={`ml-2 font-medium text-cdmx-blue-light underline ${
                            this.props.isFontBig ? "text-xl" : "text-base"
                          }`}
                        >
                          {/* {project.nombre_proyecto}.eaf */}
                          <a
                            href={`../api/multimedia${this.parseFileUrl(
                              project.ruta_trabajo,
                              project.nombre_proyecto,
                              "eaf"
                            )}`}
                            download
                          >
                            {this.formatProjectName(project.nombre_proyecto)}
                            .eaf
                          </a>
                        </span>
                      </div>
                      <div className="self-start">
                        <button
                          className={`text-teal-600 background-transparent font-bold uppercase px-6 py-1 ${
                            this.props.isFontBig ? "text-lg" : "text-sm"
                          } outline-none focus:outline-none ease-linear transition-all duration-150 break-normal`}
                          onClick={this.openEafModal(project)}
                        >
                          {t("proyectos.acciones.actualizar", "Actualizar")}
                        </button>
                      </div>
                    </div>
                    <div className="flex text-sm items-center justify-between">
                      <div className="flex items-center">
                        <span className="text-gray-500">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="w-6 h-6 fill-current"
                          >
                            <path d="M10.75 19q.95 0 1.6-.65.65-.65.65-1.6V13h3v-2h-4v3.875q-.275-.2-.587-.288-.313-.087-.663-.087-.95 0-1.6.65-.65.65-.65 1.6 0 .95.65 1.6.65.65 1.6.65ZM6 22q-.825 0-1.412-.587Q4 20.825 4 20V4q0-.825.588-1.413Q5.175 2 6 2h8l6 6v12q0 .825-.587 1.413Q18.825 22 18 22Zm7-13V4H6v16h12V9ZM6 4v5-5 16V4Z" />
                          </svg>
                        </span>
                        <span
                          className={`ml-2 font-medium text-cdmx-blue-light underline ${
                            this.props.isFontBig ? "text-xl" : "text-base"
                          }`}
                        >
                          {/* {project.nombre_proyecto}.wav */}
                          {project.mime_type  == "audio/wav" ? (
                            <a
                              href={`../api/multimedia${this.parseFileUrl(
                                project.ruta_trabajo,
                                project.nombre_proyecto,
                                "wav"
                              )}`}
                              download
                            >
                              {this.formatProjectName(project.nombre_proyecto)}
                              .wav
                            </a>
                          ) : null}

                          {project.mime_type == "video/mp4" ? (
                            <a
                              href={`../api/multimedia${this.parseFileUrl(
                                project.ruta_trabajo,
                                project.nombre_proyecto,
                                "mp4"
                              )}`}
                              download
                            >
                              {this.formatProjectName(project.nombre_proyecto)}
                              .mp4
                            </a>
                          ) : null}
                        </span>
                      </div>
                      <div className="self-start">
                        <button
                          className={`text-teal-600 background-transparent font-bold uppercase px-6 py-1 ${
                            this.props.isFontBig ? "text-lg" : "text-sm"
                          } outline-none focus:outline-none ease-linear transition-all duration-150 break-normal`}
                          onClick={this.openMultimediaModal(project)}
                        >
                          {t("proyectos.acciones.actualizar", "Actualizar")}
                        </button>
                      </div>
                      {/* <div className="ml-2">
                        {project.isPlaying ? (
                          <button onClick={this.pauseMedia(project)}>
                            <span className="text-cdmx-purple-light">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="w-6 h-6 fill-current"
                              >
                                <path d="M9 16h2V8H9Zm4 0h2V8h-2Zm-1 6q-2.075 0-3.9-.788-1.825-.787-3.175-2.137-1.35-1.35-2.137-3.175Q2 14.075 2 12t.788-3.9q.787-1.825 2.137-3.175 1.35-1.35 3.175-2.138Q9.925 2 12 2t3.9.787q1.825.788 3.175 2.138 1.35 1.35 2.137 3.175Q22 9.925 22 12t-.788 3.9q-.787 1.825-2.137 3.175-1.35 1.35-3.175 2.137Q14.075 22 12 22Zm0-2q3.35 0 5.675-2.325Q20 15.35 20 12q0-3.35-2.325-5.675Q15.35 4 12 4 8.65 4 6.325 6.325 4 8.65 4 12q0 3.35 2.325 5.675Q8.65 20 12 20Zm0-8Z" />
                              </svg>
                            </span>
                          </button>
                        ) : (
                          <button onClick={this.playMedia(project)}>
                            <span className="text-cdmx-gray">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="w-6 h-6 fill-current"
                              >
                                <path d="m9.5 16.5 7-4.5-7-4.5ZM12 22q-2.075 0-3.9-.788-1.825-.787-3.175-2.137-1.35-1.35-2.137-3.175Q2 14.075 2 12t.788-3.9q.787-1.825 2.137-3.175 1.35-1.35 3.175-2.138Q9.925 2 12 2t3.9.787q1.825.788 3.175 2.138 1.35 1.35 2.137 3.175Q22 9.925 22 12t-.788 3.9q-.787 1.825-2.137 3.175-1.35 1.35-3.175 2.137Q14.075 22 12 22Zm0-2q3.35 0 5.675-2.325Q20 15.35 20 12q0-3.35-2.325-5.675Q15.35 4 12 4 8.65 4 6.325 6.325 4 8.65 4 12q0 3.35 2.325 5.675Q8.65 20 12 20Zm0-8Z" />
                              </svg>
                            </span>
                          </button>
                        )}
                      </div> */}
                    </div>
                    <div className="flex items-center mt-2 mb-1 justify-between">
                      {/* <audio
                        id={project.nombre_proyecto}
                        controls
                        controlsList="nodownload noplaybackrate"
                        className=""
                        onPlay={() => {
                          this.showFullImage(
                            `../../api/streaming/audioV3${this.parseFileUrl(
                              project.ruta_trabajo,
                              project.nombre_proyecto,
                              "wav"
                            )}`, 'audio'
                          );
                        }}
                      >
                        <source
                          src={`../../api/streaming/audioV3${this.parseFileUrl(
                            project.ruta_trabajo,
                            project.nombre_proyecto,
                            "wav"
                          )}`}
                          type="audio/wav"
                        ></source>
                      </audio> */}
                      <div
                        onClick={() => {
                          console.log("project.mime_type!!!!!1", project);
                          if (project.mime_type == "audio/wav") {
                            this.showFullImage(
                              `../../api/streaming/audioV3${this.parseFileUrl(
                                project.ruta_trabajo,
                                project.nombre_proyecto,
                                "wav"
                              )}`,
                              "audio"
                            );
                          } else {
                            this.showFullImage(
                              `../../api/streaming/videoV2${this.parseFileUrl(
                                project.ruta_trabajo,
                                project.nombre_proyecto,
                                "mp4"
                              )}`,
                              "video"
                            );
                          }
                        }}
                        className="cursor-pointer"
                      >
                        <svg
                          className={`${
                            this.props.isFontBig ? "w-14 h-14" : "w-10 h-10"
                          }`}
                          viewBox="0 0 21 21"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g
                            fill="none"
                            fillRule="evenodd"
                            stroke="#000000"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            transform="translate(2 5)"
                          >
                            <path d="m2.49368982.53498937 11.99999998-.03787142c1.0543566-.00331643 1.9207298.80983192 2.0003436 1.84444575l.0059666.15555425v6.00288205c0 1.1045695-.8954305 2-2 2h-12c-1.1045695 0-2-.8954305-2-2v-5.96502059c0-1.10210602.89158929-1.9965128 1.99368982-1.99999004z" />
                            <path d="m7.5 7.5 3-2-3-2z" fill="#000000" />
                          </g>
                        </svg>
                      </div>
                      <div>
                        <button
                          className={`text-red-500 background-transparent font-bold uppercase px-6 py-2 ${
                            this.props.isFontBig ? "text-lg" : "text-sm"
                          } outline-none focus:outline-none ease-linear transition-all duration-150 break-normal`}
                          onClick={this.showReporMediaModal(project)}
                        >
                          {t(
                            "proyectos.acciones.reportar_multimedia",
                            "Reportar multimedia"
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-wrap">
                    <button
                      className={`bg-infokab-gray hover:bg-infokab-gray/90 text-white font-bold px-4 py-2 rounded shadow mb-2 ${
                        this.props.isFontBig ? "text-xl" : "text-base"
                      }`}
                    >
                      {/* <Link to={`/editor/${project}`}>Editar</Link> */}
                      <Link
                        to={`editor/${project.id_proyecto}`}
                        className="text-white"
                      >
                        {t("proyectos.mensajes.editar_glosa", "Editar Glosa")}
                      </Link>
                    </button>

                    {this.state.rol_user === "1" ||
                    (this.state.permisos.hasOwnProperty("dev") &&
                      this.state.permisos.dev === true) ? (
                      <button
                        className={`bg-infokab-gray hover:bg-infokab-gray/90 text-white font-bold px-4 py-2 rounded shadow mb-2 ml-2 ${
                          this.props.isFontBig ? "text-xl" : "text-base"
                        }`}
                        onClick={this.showAlertGlosaToEafProject(project)}
                      >
                        Guardar glosa a eaf
                      </button>
                    ) : null}
                    {/* <button className="bg-red-500 hover:bg-red-600 text-white font-bold px-4 py-2 rounded shadow mb-2 ml-2">
                      Reportar
                    </button> */}
                  </div>
                  <div className="flex flex-wrap w-full h-full">
                    <div className=" relative bg-slate-50 h-40 w-full border-2 p-2">
                      <Swiper
                        slidesPerView={3}
                        spaceBetween={30}
                        modules={[Navigation]}
                        navigation={true}
                        className="mySwiper w-full"
                      >
                        {project.filesList != null ? (
                          project.filesList.map((element, index) => {
                            return this.getType(
                              project.ruta_trabajo,
                              element,
                              index
                            );
                          })
                        ) : (
                          <div className="w-full h-20">
                            <h2 className=" mt-4 text-center h-full w-full">
                              {t(
                                "proyectos.mensajes.sin_imagenes_agregadas",
                                "SIN IMÁGENES AGREGADAS"
                              )}
                            </h2>
                          </div>
                        )}
                      </Swiper>

                      <button
                        onClick={this.handleOpenModalImage(project)}
                        type="button"
                        className={`bottom-1 right-1 z-10 absolute dark:focus:ring-[#4285F4]/55 text-white bg-cdmx-gray hover:bg-cdmx-gray/90 focus:ring-4 focus:outline-none focus:ring-[#4285F4]/50 font-medium rounded-lg ${
                          this.props.isFontBig ? "text-base" : "text-xs"
                        } px-2 py-1 text-center inline-flex items-center dark:focus:ring-[#4285F4]/55`}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          className="mr-2 -ml-1 w-4 h-4"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M6.827 6.175A2.31 2.31 0 015.186 7.23c-.38.054-.757.112-1.134.175C2.999 7.58 2.25 8.507 2.25 9.574V18a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18V9.574c0-1.067-.75-1.994-1.802-2.169a47.865 47.865 0 00-1.134-.175 2.31 2.31 0 01-1.64-1.055l-.822-1.316a2.192 2.192 0 00-1.736-1.039 48.774 48.774 0 00-5.232 0 2.192 2.192 0 00-1.736 1.039l-.821 1.316z"
                          />
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M16.5 12.75a4.5 4.5 0 11-9 0 4.5 4.5 0 019 0zM18.75 10.5h.008v.008h-.008V10.5z"
                          />
                        </svg>
                        {t(
                          "proyectos.acciones.subir_imagenes",
                          "Subir Imagenes"
                        )}
                      </button>
                    </div>
                  </div>

                  <div className="flex justify-end mt-2 break-normal">
                    {this.state.rol_user === "1" ||
                    (this.state.permisos.hasOwnProperty("dev") &&
                      this.state.permisos.dev === true) ? (
                      <button
                        className={`bg-infokab-gray hover:bg-infokab-gray/90 text-white font-bold px-4 py-2 rounded shadow mb-2 ml-2 ${
                          this.props.isFontBig ? "text-xl" : "text-base"
                        }`}
                        onClick={this.showAlertIndexProject(project)}
                      >
                        Indexar Proyecto
                      </button>
                    ) : null}

                    {this.state.rol_user === "1" ||
                    (this.state.permisos.hasOwnProperty("dev") &&
                      this.state.permisos.dev === true) ? (
                      <button
                        className={`bg-infokab-gray hover:bg-infokab-gray/90 text-white font-bold px-4 py-2 rounded shadow mb-2 ml-2 ${
                          this.props.isFontBig ? "text-xl" : "text-base"
                        }`}
                        onClick={this.showAlertDeleteProject(project)}
                      >
                        Eliminar proyecto
                      </button>
                    ) : null}
                  </div>
                </div>
              </div>
            );
          })}
        </div>

        <Modal
          show={this.state.modalShow}
          handleClose={this.hideModal}
          size="w-2/5"
          actions={
            this.state.errorList.length > 0 ? [this.downloadErrorFile] : []
          }
          labels={["Descargar"]}
          isFontBig={this.props.isFontBig}
        >
          <div className="flex flex-col items-center justify-center pb-4">
            {this.state.errorList.length > 0 ? (
              <>
                {this.state.errorList.map(error => {
                  return (
                    <div
                      key={error.id}
                      className="flex flex-col border-b-2 border-gray-400 pb-2 px-4 w-4/5 leading-loose"
                    >
                      <span
                        className={`${
                          this.props.isFontBig ? "text-2xl" : "text-lg"
                        } font-bold tracking-wider`}
                      >
                        {error.titulo}
                      </span>
                      <span
                        className={`font-medium ${
                          this.props.isFontBig ? "text-base" : "text-xs"
                        } text-right`}
                      >
                        {this.formatErrorDate(error.fecha_creacion)}
                      </span>
                      <span
                        className={`font-semibold ${
                          this.props.isFontBig ? "text-xl" : "text-base"
                        }`}
                      >
                        {error.comentario}
                      </span>
                    </div>
                  );
                })}
              </>
            ) : (
              <div className="flex flex-col border-b-2 border-gray-400 pb-2 px-4 w-4/5 leading-loose">
                <span
                  className={`${
                    this.props.isFontBig ? "text-2xl" : "text-lg"
                  } font-bold tracking-wider`}
                >
                  No se han encontrado errores.
                </span>
              </div>
            )}
            {/* {this.state.errorList.map(error => {
              return (
                <div
                  key={error.id}
                  className="flex flex-col border-b-2 border-gray-400 pb-2 px-4 w-3/5 leading-loose"
                >
                  <span className="text-xl font-bold tracking-wider">
                    {error.titulo}
                  </span>
                  <span className="font-medium text-xs text-right">
                    {this.formatErrorDate(error.fecha_creacion)}
                  </span>
                  <span className="font-semibold">{error.comentario}</span>
                </div>
              );
            })} */}
          </div>
        </Modal>

        <Modal
          show={this.state.showModalEaf}
          handleClose={this.hideEafModal}
          size={`${this.props.isFontBig ? "w-1/3" : "w-1/5"}`}
          actions={[this.updateEafFile()]}
          labels={[t("proyectos.acciones.enviar", "Enviar")]}
          isFontBig={this.props.isFontBig}
        >
          <div className="flex flex-col items-center justify-center pb-4">
            <form
              className="px-8 pt-6 pb-8 mb-4 content-center w-full"
              ref={el => (this.myFormRef = el)}
            >
              <label
                className={`block mb-2 ${
                  this.props.isFontBig ? "text-lg" : "text-sm"
                } font-medium text-black`}
                htmlFor="eaf"
              >
                {t(
                  "subir_archivos.titulos.archivo_de_anotacion",
                  "Archivo de anotación"
                )}
              </label>
              <input
                className={`block w-full border-2 rounded-l-full ${
                  this.props.isFontBig
                    ? "text-lg file:text-lg"
                    : "text-sm file:text-sm"
                } text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:font-semibold file:bg-cdmx-green file:text-white hover:file:bg-cdmx-purple-light file:cursor-pointer`}
                id="eaf"
                type="file"
                name="eaf"
                accept=".eaf"
                onChange={this.handleEafChange}
                defaultValue={this.state.eaf}
              />
            </form>
          </div>
        </Modal>

        <Modal
          show={this.state.showModalMultimedia}
          handleClose={this.hideMultimediaModal}
          size={`${this.props.isFontBig ? "w-1/3" : "w-1/5"}`}
          actions={[this.updateMultimediaFile()]}
          labels={[t("proyectos.acciones.enviar", "Enviar")]}
          isFontBig={this.props.isFontBig}
        >
          <div className="z-50 flex flex-col items-center justify-center pb-4">
            <form
              className="px-8 pt-6 pb-8 mb-4 content-center w-full"
              ref={el => (this.myFormRef = el)}
            >
              <label
                className={`block mb-2 ${
                  this.props.isFontBig ? "text-lg" : "text-sm"
                } font-medium text-black`}
                htmlFor="multimedia"
              >
                {t("subir_archivos.titulos.multimedia", "Multimedia")}
              </label>
              <input
                className={`block w-full border-2 rounded-l-full ${
                  this.props.isFontBig
                    ? "text-lg file:text-lg"
                    : "text-sm file:text-sm"
                } text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:font-semibold file:bg-cdmx-green file:text-white hover:file:bg-cdmx-purple-light file:cursor-pointer`}
                id="multimedia"
                type="file"
                name="multimedia"
                accept="video/mp4, audio/wav"
                onChange={this.handleMultimediaChange}
                defaultValue={this.state.multimedia}
              />
            </form>
          </div>
        </Modal>

        <ModalMultimedia
          show={this.state.showMultimedia}
          handleClose={this.hideGalleryMultimediaModal}
          size="w-1/4"
        >
          <div className="flex flex-wrap justify-center content-center h-3/4">
            {this.state.multimediaType == "imagen" ? (
              <LazyLoadImage
                src={this.state.multimediaShow}
                className="flex flex-wrap object-contain w-full h-full p-2"
                alt=""
              />
            ) : null}
            {/* <img
              src={this.state.imagetoShow}
              className="flex flex-wrap object-contain w-full p-2"
              alt=""
            /> */}

            {this.state.multimediaType == "audio" ? (
              <audio
                controls
                controlsList="nodownload noplaybackrate"
                className="w-full"
              >
                <source
                  src={this.state.multimediaShow}
                  type="audio/wav"
                ></source>
              </audio>
            ) : null}

            {this.state.multimediaType == "video" ? (
              <video
                className="object-contain h-full w-full  p-1 h-full"
                controls
              >
                <source src={this.state.multimediaShow} type="video/mp4" />
              </video>
            ) : null}
          </div>
        </ModalMultimedia>

        <Modal
          show={this.state.showModalReportMedia}
          handleClose={this.hideReportMediaModal}
          size={`${this.props.isFontBig ? "w-1/3" : "w-1/4"}`}
          actions={[this.saveMultimediaReport]}
          labels={["Enviar reporte"]}
          isFontBig={this.props.isFontBig}
        >
          <div className="flex flex-col">
            <span
              className={`${
                this.props.isFontBig ? "text-2xl" : "text-xl"
              } font-bold tracking-wider mb-2`}
            >
              Reporte de archivo multimedia
            </span>
            <div className="flex flex-col mb-4">
              <label
                htmlFor="error-msg"
                className={`block ${
                  this.props.isFontBig ? "text-xl" : "text-base"
                } font-medium text-gray-700`}
              >
                Motivo de reporte:
              </label>
              <textarea
                name="error-msg"
                className={`mt-2 px-3 py-1.5 ${
                  this.props.isFontBig ? "text-xl" : "text-base"
                } font-normal border border-solid border-gray-300 rounded resize-none`}
                cols="30"
                rows="6"
                value={this.state.reportMediaMsg}
                onChange={this.updateReportMediaMsg}
              ></textarea>
            </div>
          </div>
        </Modal>

        <Modal
          show={this.state.showModalAlertIndexProject}
          handleClose={this.hideAlertIndexModal}
          size={`${this.props.isFontBig ? "w-3/12" : "w-1/5"}`}
          actions={[this.indexProjectRegister]}
          labels={[t("proyectos.acciones.indexar", "Indexar")]}
          isFontBig={this.props.isFontBig}
        >
          <h2
            className={`font-semibold text-gray-800 pb-4 ${
              this.props.isFontBig ? "text-xl" : "text-base"
            }`}
          >
            {t(
              "proyectos.mensajes.pregunta_para_indexar_proyecto",
              "¿Seguro que desea indexar este proyecto?"
            )}
          </h2>
        </Modal>

        <Modal
          show={this.state.showModalAlertDeleteProject}
          handleClose={this.hideAlertIndexModal}
          size={`${this.props.isFontBig ? "w-3/12" : "w-1/5"}`}
          actions={[this.deleteProjectRegister]}
          labels={["Eliminar"]}
          isFontBig={this.props.isFontBig}
        >
          <h2
            className={`font-semibold text-gray-800 pb-4 ${
              this.props.isFontBig ? "text-xl" : "text-base"
            }`}
          >
            {t(
              "proyectos.mensajes.pregunta_para_eliminar_proyecto",
              "¿Seguro que desea eliminar este proyecto?"
            )}
          </h2>
        </Modal>

        <Modal
          show={this.state.showModalAlertGlosaToEafProject}
          handleClose={this.hideAlertIndexModal}
          size={`${this.props.isFontBig ? "w-3/12" : "w-1/5"}`}
          actions={[this.glosaToEafProjectRegister]}
          labels={["Descargar Glosa"]}
          isFontBig={this.props.isFontBig}
        >
          <h2
            className={`font-semibold text-gray-800 pb-4 ${
              this.props.isFontBig ? "text-xl" : "text-base"
            }`}
          >
            {t(
              "menu_diccionarios.mensajes.glosado_to_eaf",
              "¿Seguro deseas agregar el glosado al eaf?"
            )}
          </h2>
        </Modal>

        <Modal
          show={this.state.showModalAlertSuccessIndex}
          handleClose={this.hideAlertSuccessIndexModal}
          size={`${this.props.isFontBig ? "w-3/12" : "w-1/5"}`}
          isFontBig={this.props.isFontBig}
        >
          <h2
            className={`font-semibold text-gray-800 pb-4 ${
              this.props.isFontBig ? "text-xl" : "text-base"
            }`}
          >
            {this.state.message_success}
          </h2>
        </Modal>

        {this.state.showModalImage ? (
          <DragNDrop
            acceptedImages={this.acceptedImages}
            handleClose={this.handleCloseModalImage}
            isFontBig={this.props.isFontBig}
          />
        ) : null}
      </div>
    );
  }
}

//export default Projects;
export default withTranslation()(Projects);

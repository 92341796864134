import "./App.css";
import { BrowserRouter as Router, Routes, Route, Link, Navigate } from "react-router-dom";
import { useState } from "react";
import Login from "./component/Login";
import Search from "./component/Search";
import SearchMap from "./component/SearchMap";
import MapProjects from "./component/MapProjects.js";
import CardsApps from "./component/CardsApps.js";
import Soundex from "./component/Soundex";
import GuardedRoute from "./component/GuardedRoute";
import Home from "./component/Home";
import Info from "./component/Info";
import About from "./component/About";
import Contact from "./component/Contact";
import Storage from "./component/Storage";
import AdminPanel from "./component/AdminPanel";
import { useTranslation } from "react-i18next";
import Download from "./component/Download";
import AccesibilityMenu from "./component/AccesibilityMenu";
import UserManual from "./component/UserManual";

function App() {
  const { t, i18n } = useTranslation("translations");
  let select_language = i18n.language;
  const changeLanguage = lng => {
    i18n.changeLanguage(lng);
    select_language = lng;
  };

  const [navbar, setNavbar] = useState(false);

  const [isFontBig, setIsFontBig] = useState(false);
  const [isContrast, setIsContrast] = useState(false);

  const verifyAuthenticated = () => {
    let uuid = localStorage.getItem("uuid");

    if (uuid != null) {
      return true;
    } else {
      return false;
    }
  };

  function onAuthChange() {
    setIsAuthenticated(verifyAuthenticated());
    setHasPermissions(Permited());
  }

  const Permited = () => {
    let permissions = JSON.parse(localStorage.getItem("permisos"));

    if (permissions === null) {
      return false;
    } else {
      const onePermission = Object.values(permissions).some(el => el >= true);
      return onePermission;
    }
  };

  function clearStorage() {
    console.log("clear");
    localStorage.clear();
    setIsAuthenticated(false);
  }

  /*useEffect(() => {
    setPermissions(JSON.parse(localStorage.getItem("permisos")));
  }, []);*/

  //console.log({ permissions })

  const [isAuthenticated, setIsAuthenticated] = useState(verifyAuthenticated());
  const [hasPermissions, setHasPermissions] = useState(Permited());

  /* const contextMenu = document.querySelector(".context-wrapper");
  document.addEventListener("click", () => {
    if (contextMenu != null) {
      contextMenu.style.visibility = "hidden";
    }
  }); */

  function changeFontSize() {
    setIsFontBig(!isFontBig);
  }

  function changeContrast() {
    setIsContrast(!isContrast);
  }

  return (
    <Router>
      {/* <div className="absolute top-8 left-10 z-[100]">
        <div className=" flex item-center flex-wrap justify-center">
          <div>
            <button
              className="px-2 text-black hover:text-gray-800  hover:border-gray-100"
              type="button"
              onClick={() => changeLanguage("en")}
            >
              <span
                className={`${select_language === "en" ? "underline" : ""}`}
              >
                en
              </span>
            </button>
          </div>
          <div className="underline">
            <button
              className="px-2 text-black hover:text-gray-800  hover:border-gray-100"
              type="button"
              onClick={() => changeLanguage("es")}
            >
              <span
                className={`${select_language === "es" ? "underline" : ""}`}
              >
                es
              </span>
            </button>
          </div>
          <div className="underline">
            <button
              className="px-2 text-black hover:text-gray-800  hover:border-gray-100"
              type="button"
              onClick={() => changeLanguage("maya")}
            >
              <span
                className={`${select_language === "maya" ? "underline" : ""}`}
              >
                maya
              </span>
            </button>
          </div>
        </div>
      </div> */}

      <div className="bg-white">
        <div className="h-screen overflow-hidden">
          <Routes>
            <Route exact path="/landing" element={<Home />}></Route>
            <Route exact path="/info" element={<Info />}></Route>
            <Route exact path="/about" element={<About />}></Route>
            <Route exact path="/contact" element={<Contact />}></Route>
            <Route
              exact
              path="/login"
              element={
                <Login
                  auth={isAuthenticated}
                  onAuthChange={onAuthChange}
                  isFontBig={isFontBig}
                  isContrast={isContrast}
                />
              }
            ></Route>
            <Route
              exact
              path="/"
              element={<Search isFontBig={isFontBig} isContrast={isContrast} />}
            ></Route>
            {/* <GuardedRoute auth={isAuthenticated}
                haspermissions={hasPermissions}>
                <Search isFontBig={isFontBig} isContrast={isContrast} />
              </GuardedRoute> */}
            <Route exact path="/searchMap" element={<SearchMap />}></Route>
            <Route exact path="/proyectos" element={<MapProjects />}></Route>
            <Route exact path="/soundex" element={<Soundex />}></Route>
            <Route exact path="/apps" element={<CardsApps />}></Route>
            <Route
              path="/admin/*"
              element={
                <GuardedRoute
                  auth={isAuthenticated}
                  haspermissions={hasPermissions}
                >
                  <AdminPanel
                    isFontBig={isFontBig}
                    isContrast={isContrast}
                    permissions={hasPermissions}
                    onAuthChange={onAuthChange}
                  />
                </GuardedRoute>
              }
            />
            <Route exact path="/download/:file" element={<Download />}></Route>
            <Route exact path="/manual" element={<UserManual />}></Route>
            <Route exact path="/storage" element={<Storage />}></Route>
            <Route path="*" element={<Navigate to="/" replace />}></Route>
          </Routes>
        </div>
      </div>

      <AccesibilityMenu
        isFontBig={isFontBig}
        isContrast={isContrast}
        changeFontSize={changeFontSize}
        changeContrast={changeContrast}
      ></AccesibilityMenu>
    </Router>
  );
}

export default App;

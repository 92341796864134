/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { createRef } from "react";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import pdfLegal from "../INDAUTOR-Taantsil.pdf";

class Soundex extends React.Component {
  constructor(props) {
    super(props);
    const { t, i18n } = props;

    this.state = {
      searchValue: "",
      focusValue: "",
      originalWord: "",
      priorClass1: "",
      priorClass2: "",
      priorClass3: "",
      scrap: "",
      startCode: "",
      codeWithoutRepeating: "",
      endCode: "",
    };

    this.fetchDocs = this.fetchDocs.bind(this);
  }

  fetchDocs() {
    let payload = {
      text: this.state.searchValue,
    };

    this.setState(
      {
        documents: [],
        focusValue: this.state.searchValue,
      },
      () => {
          fetch("../api/soundex/annotation", {
            method: "POST",
            body: JSON.stringify(payload),
            headers: {
              "Content-Type": "application/json"
            }
          })
            .then(response => {
              response.json().then(res => {
                console.log(res);
                this.setState(
                  {
                    originalWord: res.originalWord,
                    endCode: res.endCode,
                  }
                );
              });
            })
            .catch(error => {
              console.log(error.cause);
            });
      }
    );
  }

  handleSearchChange = event => {
    this.setState({
      searchValue: event.target.value
    });
  };

  render() {
    const { t } = this.props;

    return (
      <div
        className={`relative w-full px-4 py-3 h-full bg-cdmx-gray flex flex-col ${
          this.props.isContrast ? "invert" : "invert-0"
        }`}
      >
        <div className="flex flex-col">
          <div className="flex flex-col items-center justify-center w-full">
            <img src="blanco gris BCBEC0.png" className={`h-16 self-end pr-4 ${this.state.hasSearched
                ? "lg:h-24 lg:pr-8 "
                : "lg:h-64 lg:self-center"
                }`}
              alt="" 
            />
            <div className="w-full flex items-center justify-center py-2">
              <input
                className={`w-1/3 outline-0 bg-white  border-cdmx-green border-b-2 border-t-2 border-r-2 px-2 ${
                  this.props.isFontBig ? "py-2.5" : "py-2"
                } leading-tight`}
                type="text"
                name="search"
                id="search"
                value={this.state.searchValue}
                onChange={this.handleSearchChange}
                onKeyDown={this.handleKeyDown}
              ></input>
              <button
                className={`px-4 py-2 bg-cdmx-green text-white font-bold ${
                  this.props.isFontBig ? "text-lg" : "text-base"
                }`}
                type="button"
                onClick={this.fetchDocs}
              >
                {t("buscador.acciones.buscar", "Buscar")}
              </button>
            </div>
          </div>
          <div>
            <div class="flex flex-wrap w-full items-center justify-center">
              <div class="w-full flex">
                <span className="text-center w-full">{this.state.originalWord}</span>
              </div>
              <div class="w-full flex">
                <span className="text-center w-full">{this.state.endCode}</span>
              </div>
            </div>
          </div>
        </div>
              
        <div className="flex-grow">
          
        </div>

        {/* Footer */}
        <div className="flex justify-between flex-col lg:flex-row">
          <div
            className={`text-gray-200 space-x-2 ${this.props.isFontBig ? " text-lg" : "text-base"
              }`}
          >
            <Link to="/search">Search</Link>
            <span>|</span>
            <Link to="/apps">apps</Link>
          </div>

          <div
            className={`text-gray-200 ${this.props.isFontBig ? "text-base lg:text-lg" : "text-xs lg:text-base"
              }`}
          >
            <span>
              <a href={pdfLegal} target="_blank">
                © T'aantsil 2023.{" "}
                {t(
                  "buscador.acciones.todos_los_derechos_reservados",
                  "All rights reserved"
                )}
                . Centrogeo
              </a>
            </span>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(Soundex);

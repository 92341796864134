import React from "react";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import "./scrollbar.css";
class Info extends React.Component {
  constructor() {
    super();
    this.state = {
      lng: ""
    };
  }

  componentDidMount() {
    this.setState({
      lng: localStorage.getItem("i18nextLng")
    });
  }

  render() {
    const { t } = this.props;
    return (
      <main className="main w-full h-full flex bg-gray-300 flex-col flex-grow-ml-64 md:ml-0 transition-all duration-150 ease-in overflow-auto">
        <div className=" bg-cdmx-green">
          <section className="text-tertiary-700 mt-20 ">
            <div className="container flex flex-col px-5 py-8 mx-auto md:items-center ">
              <div className="flex flex-col items-center w-full mb-20 text-left lg:text-center ">
                <img src="logo3.png" className="w-6/12 object-center" alt="" />
              </div>
            </div>
          </section>
        </div>
        {/* SVG */}
        <div className="relative -mt-12 lg:-mt-28 bg-cdmx-green">
          <svg
            viewBox="0 0 1428 174"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <g
                transform="translate(-2.000000, 44.000000)"
                fill="#FFFFFF"
                fillRule="nonzero"
              >
                <path
                  d="M0,0 C90.7283404,0.927527913 147.912752,27.187927 291.910178,59.9119003 C387.908462,81.7278826 543.605069,89.334785 759,82.7326078 C469.336065,156.254352 216.336065,153.6679 0,74.9732496"
                  opacity="0.100000001"
                ></path>
                <path
                  d="M100,104.708498 C277.413333,72.2345949 426.147877,52.5246657 546.203633,45.5787101 C666.259389,38.6327546 810.524845,41.7979068 979,55.0741668 C931.069965,56.122511 810.303266,74.8455141 616.699903,111.243176 C423.096539,147.640838 250.863238,145.462612 100,104.708498 Z"
                  opacity="0.100000001"
                ></path>
                <path
                  d="M1046,51.6521276 C1130.83045,29.328812 1279.08318,17.607883 1439,40.1656806 L1439,120 C1271.17211,77.9435312 1140.17211,55.1609071 1046,51.6521276 Z"
                  id="Path-4"
                  opacity="0.200000003"
                ></path>
              </g>
              <g
                transform="translate(-4.000000, 76.000000)"
                fill="#FFFFFF"
                fillRule="nonzero"
              >
                <path d="M0.457,34.035 C57.086,53.198 98.208,65.809 123.822,71.865 C181.454,85.495 234.295,90.29 272.033,93.459 C311.355,96.759 396.635,95.801 461.025,91.663 C486.76,90.01 518.727,86.372 556.926,80.752 C595.747,74.596 622.372,70.008 636.799,66.991 C663.913,61.324 712.501,49.503 727.605,46.128 C780.47,34.317 818.839,22.532 856.324,15.904 C922.689,4.169 955.676,2.522 1011.185,0.432 C1060.705,1.477 1097.39,3.129 1121.236,5.387 C1161.703,9.219 1208.621,17.821 1235.4,22.304 C1285.855,30.748 1354.351,47.432 1440.886,72.354 L1441.191,104.352 L1.121,104.031 L0.457,34.035 Z"></path>
              </g>
            </g>
          </svg>
        </div>
        {/* SVG */}
        <section className="bg-white border-b py-8">
          <div className="container max-w-5xl mx-auto m-8">
            <h2 className="w-full my-2 text-5xl mb-16 font-bold leading-tight text-center text-gray-800">
              {this.state.lng === "en"
                ? "About the Project"
                : "Información General Sobre el proyecto"}
            </h2>

            <h3 className="font-bold mb-1 mt-5">
              {this.state.lng === "en" ? "Title:" : "Nombre del proyecto:"}
            </h3>
            <p>
              {this.state.lng === "en"
                ? "Development of Information Technologies for the Linguistic Corpus of the Maya Language of Yucatan"
                : "Desarrollo de tecnologías de la información para el corpus lingüístico del maya yucateco."}
            </p>
            <p className="mt-1 mb-1">
              <b>
                {this.state.lng === "en"
                  ? "W.K. Kellogg Foundation number:"
                  : "Núm W.K. Kellogg Foundation:"}
              </b>{" "}
              P-6005156-2021
            </p>
            <p>
              <b>
                {this.state.lng === "en"
                  ? "Project Duration:"
                  : "Tiempo de ejecución del proyecto:"}
              </b>{" "}
              {this.state.lng === "en"
                ? "18 months (March 1, 2022, to August 31, 2023)"
                : "18 meses (1 de Marzo de 2022 hasta de 31 de Agosto de 2023)"}
            </p>
            <p>
              <b>
                {this.state.lng === "en"
                  ? "Legal Organization Name:"
                  : "Nombre legal de la organización:"}
              </b>{" "}
              {this.state.lng === "en"
                ? "Center for Research in Geospatial Information Sciences"
                : "Centro de Investigación en Ciencias de Información Geoespacial"}
            </p>
            <p>
              <b>
                {this.state.lng === "en"
                  ? "Project Director:"
                  : "Director de Proyecto:"}
              </b>{" "}
              Alejandro Molina Villegas
            </p>
            <p>
              <b>{this.state.lng === "en" ? "Contact:" : "Contacto:"}</b>{" "}
              amolina@centrogeo.edu.mx
            </p>
            <h2 className="w-full my-2 text-3xl mb-5 mt-5 font-bold leading-tight text-leff text-gray-800">
              {this.state.lng === "en" ? "Summary" : "Resumen"}
            </h2>
            <p className="text-justify ident-8">
              {" "}
              {this.state.lng === "en"
                ? "The overarching goal of the project is to contribute to the revitalization of the Maya language and culture within the Maya-speaking community of the Yucatan Peninsula (Quintana Roo, Campeche, and Yucatan). To achieve this, we will construct the digital framework for the Maya language through three specific objectives: 1)Increase systematic linguistic documentation of the Maya language in the Yucatan Peninsula, integrated into a reference corpus. 2)Enhance the availability and access to digital resources, automated processing tools, and Maya language technologies. 3)Empower the use, utilization, and continuity of the reference Maya corpus as a digital tool for the Maya language in the Yucatan Peninsula."
                : "El objetivo general es contribuir a revitalizar la lengua y cultura maya en la comunidad maya hablante de la Península de Yucatán (Quintana Roo, Campeche y Yucatán). Para ello construiremos el andamiaje digital de la lengua maya mediante tres objetivos específicos: 1) Incrementar la documentación lingüística sistematizada del maya de la península de Yucatán e integrada en un corpus de referencia; 2) Aumentar la disponibilidad y el acceso a recursos digitales, herramientas de procesamiento automático y tecnologías de la lengua maya y 3) Potencializar el uso, aprovechamiento y continuidad del corpus maya de referencia, como herramienta digital de la lengua maya en la península de Yucatán."}
            </p>

            <p className="mt-5 text-justify">
              {this.state.lng === "en"
                ? "In addition to extensive collaboration with the Maya-speaking community (documenters and speakers), achieving the stated objectives involves the integration of knowledge from two major disciplines: linguistics and computer science. As such, the project will be executed through two project proposals submitted to the WKKF (P-6005154-2021, P-6005156-2021). Project P-6005154-2021, led by the Linguistics team at the Institutional Language Center (CIL) of the Autonomous University of Yucatan (UADY), will focus on the execution of the first specific objective, which involves training and literacy for the team of Maya speakers responsible for collecting and transcribing hours of audiovisual material directly in the communities. Project P-6005156-2021, led by the computer science team at the Center for Research in Geospatial Information Sciences (CentroGeo), will handle the execution of the second specific objective, which entails the creation of a software platform designed for the optimal utilization of the Maya corpus through specialized language processing modules."
                : "Además de la amplia colaboración de la comunidad maya hablante (documentadores y hablantes), lograr los objetivos antes enunciados supone la interacción y la integración de saberes de dos grandes disciplinas que son la lingüística y la computación. Ante lo cual el proyecto será ejecutado mediante dos propuestas de proyecto ante la WKKF (P-6005154-2021, P-6005156-2021). El proyecto P-6005154-2021 coordinado por el equipo de lingüística del Centro Institucional de Lenguas (CIL) de la Universidad Autónoma de Yucatán (UADY), se encargará de la ejecución del primer objetivo específico, que corresponde a la parte de capacitación y alfabetización del equipo de maya hablantes encargados de ir directamente a las comunidades a recopilar y transcribir varias horas de material audiovisual. El proyecto P-6005156-2021 a cargo del equipo de computación del Centro de Investigación en Ciencias de Información Geoespacial (CentroGeo), se encargará de la ejecución del segundo objetivo específico, que corresponde a la creación de una plataforma de software que permitirá el aprovechamiento óptimo del corpus maya a través de módulos especializados en el procesamiento de esta lengua."}
            </p>

            <h2 className="w-full my-2 text-3xl mb-2 mt-5 font-bold leading-tight text-leff text-gray-800">
              {this.state.lng === "en" ? "Team" : "Equipo de trabajo"}
            </h2>
            <p>
              {this.state.lng === "en"
                ? "Development of Information Technologies for the Yucatecan Maya Linguistic Corpus"
                : "Desarrollo de tecnologías de la información para el corpus lingüístico del maya yucateco"}
            </p>

            <h3 className="font-bold mt-5">
              {this.state.lng === "en"
                ? "Direction and Conception:"
                : "Dirección y Concepción"}
            </h3>
            <ul className="list-none">
              <li>Dr. Alejandro Molina Villegas</li>
              <li>Dr. Oscar Gerardo Sanchez Siordia</li>
            </ul>

            <h3 className="font-bold mt-5">
              {this.state.lng === "en"
                ? "Software Development"
                : "Desarrollo de software"}
            </h3>
            <ul className="list-none">
              <li>M.C.C. Irving David Sánchez Machay</li>
              <li>Ing. Ulises Morales Ramírez</li>
              <li>Ing. José Luis Uc Pérez</li>
              <li>Ing. Sergio Adrián Góngora Euan</li>
            </ul>

            <h3 className="font-bold mt-5">
              {this.state.lng === "en"
                ? "Technical-academic Support"
                : "Soporte técnico-académico"}
            </h3>
            <ul className="list-none">
              <li>Ing. Alvin Santiago Argáez Hernández</li>
              <li>Ing. Mario Gonzalo Chirinos</li>
            </ul>

            <h3 className="font-bold mt-5">
              {this.state.lng === "en"
                ? "Lingüístic Support"
                : "Soporte lingüístico"}
            </h3>
            <ul className="list-none">
              <li>Ling. César David Can Canul</li>
              <li>Ling. Samuel Canul Yah</li>
            </ul>

            <div className="bg-indigo-300 mt-6  border-4 border-cdmx-green">
              <img src="equipoCorpus.jpg" className="w-full" />
            </div>
          </div>
        </section>

        <section className="bg-white border-b py-8">
          <div className="container max-w-5xl mx-auto m-8">
            <h2 className="w-full my-2 text-5xl font-bold leading-tight text-center text-gray-800">
              {this.state.lng === "en"
                ? "Sponsors and Collaborators"
                : "Patrocinadores y Colaboradores"}
            </h2>
            <div className="w-full mb-4">
              <div className="h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t"></div>
            </div>

            {/*  Conacyt  */}
            <div className="flex flex-wrap fade-in-out">
              <div className="w-5/6 sm:w-1/2 p-6">
                <h3 className="text-xl text-gray-800 font-bold leading-none mb-3">
                  <br />
                  {this.state.lng === "en"
                    ? "National Council for Humanities, Sciences, and Technologies"
                    : "Consejo Nacional de Humanidades Ciencias y Tecnologías"}
                </h3>
                <p className="text-xs text-gray-600 mb-8">
                  {this.state.lng === "en"
                    ? "Carried out under the framework of the “Researchers for Mexico” program (formerly Cátedras Conacyt). Project Number 999: “Territorial Intelligence through Remote Sensors and Distributed Collaborative Data.”"
                    : "Realizado en el marco del programa Investigadoras e Investigadores por México (antes Cátedras Conacyt). Proyecto Número 999 “Inteligencia Territorial a través de sensores remotos y datos colaborativos distribuidos”"}
                  <br />
                  <br />
                  URL:{" "}
                  <a href="https://conahcyt.mx/" target="_blank">
                    https://conahcyt.mx/
                  </a>
                </p>
              </div>
              <div className="w-full flex sm:w-1/2 p-6 justify-center items-center">
                <img src="logo_conacyt_con_sintagma_azul_completo.svg" />
              </div>
            </div>
            {/*  Conacyt  */}

            {/*  Centrogeo  */}
            <div className="flex flex-wrap flex-col-reverse sm:flex-row">
              <div className="w-full flex sm:w-1/2 p-6 justify-center items-center">
                <img src="CGEO-MER_Logo-H.png" className="w-5/6"></img>
              </div>
              <div className="w-full sm:w-1/2 p-6 mt-6">
                <div className="align-middle">
                  <h3 className="text-xl text-gray-800 font-bold leading-none mb-3">
                    {this.state.lng === "en"
                      ? "Center for Research in Geospatial Information Sciences"
                      : "Centro de Investigación en Ciencias de Información Geoespacial"}
                  </h3>
                  <p className="text-xs text-gray-600 mb-8">
                    {this.state.lng === "en"
                      ? "Developed by the Center for Research in Geospatial Information Sciences, Yucatan branch."
                      : "Desarrollado por el Centro de Investigación en Ciencias de Información Geoespacial Sede Yucatán."}
                    <br />
                    <br />
                    URL:{" "}
                    <a href="https://www.centrogeo.org.mx/" target="_blank">
                      https://www.centrogeo.org.mx/
                    </a>
                  </p>
                </div>
              </div>
            </div>
            {/*  Centrogeo  */}

            {/*  Kellogs  */}
            <div className="flex flex-wrap">
              <div className="w-5/6 sm:w-1/2 p-6">
                <h3 className="text-xl text-gray-800 font-bold leading-none mb-3">
                  <br />
                  W. K. Kellogg Foundation
                </h3>
                <p className="text-xs text-gray-600 mb-8">
                  {this.state.lng === "en"
                    ? "Funded by the W.K. Kellogg Foundation through Project P-6005156-2021, “Development of Information Technologies for the Linguistic Corpus of the Maya Language of Yucatan.”"
                    : "Financiado por W.K. Kellogg Foundation mediante el proyecto P-6005156-2021 “Desarrollo de tecnologías de la información para el corpus lingüístico del maya yucateco.”"}
                  <br />
                  <br />
                  URL:{" "}
                  <a href="https://www.wkkf.org/" target="_blank">
                    https://www.wkkf.org/
                  </a>
                </p>
              </div>
              <div className="w-full flex sm:w-1/2 p-6 justify-center items-center">
                <img
                  src="W.K._Kellogg_Foundation_Logo.png"
                  className="w-5/6"
                ></img>
              </div>
            </div>
            {/*  Kellogs  */}

            {/*  GeoInt  */}
            <div className="flex flex-wrap flex-col-reverse sm:flex-row">
              <div className="w-full flex sm:w-1/2 p-6 justify-center items-center">
                <img className="w-4/6" src="GeoInt-Logo-High.png"></img>
              </div>
              <div className="w-full sm:w-1/2 p-6 mt-6">
                <div className="align-middle">
                  <h3 className="text-xl text-gray-800 font-bold leading-none mb-3">
                    {this.state.lng === "en"
                      ? "Laboratory National of GeoInteligence"
                      : "Laboratorio Nacional de GeoInteligencia"}
                  </h3>
                  <p className="text-xs text-gray-600 mb-8">
                    {this.state.lng === "en"
                      ? "With the support of the National GeoIntelligence Laboratory."
                      : "Con el apoyo del Laboratorio Nacional de Geointeligencia."}
                    <br />
                    <br />
                    URL:{" "}
                    <a href="http://geoint.mx/" target="_blank">
                      http://geoint.mx/
                    </a>
                  </p>
                </div>
              </div>
            </div>
            {/*  GeoInt  */}

            {/*  UADY  */}
            <div className="flex flex-wrap">
              <div className="w-5/6 sm:w-1/2 p-6">
                <h3 className="text-xl text-gray-800 font-bold leading-none mb-3">
                  <br />
                  {this.state.lng === "en"
                    ? "Autonomous University of Yucatan"
                    : "Universidad Autónoma de Yucatán"}
                </h3>
                <p className="text-xs text-gray-600 mb-8">
                  {this.state.lng === "en"
                    ? "Developed in coordination with the Institutional Language Center (CIL) of the Autonomous University of Yucatan (UADY), funded by the W. K. Kellogg Foundation through Project P-6005154-2021."
                    : "Desarrollado en coordinación con el Centro Institucional de Lenguas (CIL) de la Universidad Autónoma de Yucatán (UADY); bajo el proyecto P-6005154-2021 financiado por W. K. Kellogg Foundation."}
                  <br />
                  <br />
                  URL:{" "}
                  <a href="https://uady.mx/" target="_blank">
                    https://uady.mx/
                  </a>
                </p>
              </div>
              <div className="w-full flex sm:w-1/2 p-6 justify-center items-center">
                <img src="logo_uady.svg" className="w-2/3"></img>
              </div>
            </div>
            {/*  UADY  */}

            {/*  SEDECULTA  */}
            <div className="flex flex-wrap flex-col-reverse sm:flex-row">
              <div className="w-full flex sm:w-1/2 p-6 justify-center items-center">
                <img src="logo_sedeculta.svg"></img>
              </div>
              <div className="w-full sm:w-1/2 p-6 mt-6">
                <div className="align-middle">
                  <h3 className="text-xl text-gray-800 font-bold leading-none mb-3">
                    {this.state.lng === "en"
                      ? "Secretary of Culture and the Arts"
                      : "Secretaría de la Cultura y las Artes"}
                  </h3>
                  <p className="text-xs text-gray-600 mb-8">
                    {this.state.lng === "en"
                      ? "With the support of the Secretary of Culture and the Arts of the Government of the State of Yucatan."
                      : "Con el apoyo de la Secretaría de la Cultura y las Artes del Gobierno del estado de Yucatán."}
                    <br />
                    <br />
                    URL:{" "}
                    <a href="https://cultura.yucatan.gob.mx/" target="_blank">
                      https://cultura.yucatan.gob.mx/
                    </a>
                  </p>
                </div>
              </div>
            </div>
            {/*  SEDECULTA  */}
          </div>
        </section>

        <div className="fixed top-8 left-4">
          <Link
            className="bg-infokab-gray px-4 py-2 rounded text-white tracking-wider font-semibold shadow"
            to="/login"
          >
            {t("login.mensajes.login", "Iniciar sesión")}{" "}
          </Link>
        </div>
      </main>
    );
  }
}

export default withTranslation()(Info);

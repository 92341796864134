import React from "react";
import LoadingScreen from "./LoadingScreen";
import Modal from "./Modal";
import Multiselect from "multiselect-react-dropdown";
import { withTranslation } from "react-i18next";

class GlosaEditor extends React.Component {
  constructor() {
    super();

    this.state = {
      glosado: [],
      showLoading: true,
      modalShow: false,
      reportDocMsg: "",
      confirmModal: false,
      reportWordMsg: "",
      reportedWord: null,
      selectedWord: {
        word: null,
        annotation: ""
      },
      editModal: false,
      annotationEdit: "",
      path: "",
      showDictionaryModal: false,
      codeOptions: [
        {
          name: "INTERJ"
        },
        {
          name: "ADJ"
        },
        {
          name: "CLF"
        },
        {
          name: "PREP"
        },
        {
          name: "PRON"
        },
        {
          name: "PART"
        },
        {
          name: "NOUN"
        },
        {
          name: "VT"
        },
        {
          name: "CONJ"
        },
        {
          name: "ADV"
        },
        {
          name: "VI"
        }
      ],
      selectedCodeValue: [],
      valueClave: "",
      valueCode: "",
      valueDescription: "",
      valueTraduction: "",
      showAlertSuccess: false
    };

    this.fetchGlosa = this.fetchGlosa.bind(this);
    this.updateSelectValue = this.updateSelectValue.bind(this);
    this.glosaAnalysis = this.glosaAnalysis.bind(this);
    this.saveGlosa = this.saveGlosa.bind(this);

    this.showModal = this.showModal.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.saveDocumentReport = this.saveDocumentReport.bind(this);
    this.updateReportDocMsg = this.updateReportDocMsg.bind(this);
    this.showConfirmModal = this.showConfirmModal.bind(this);
    this.hideConfirmModal = this.hideConfirmModal.bind(this);
    this.saveWordReport = this.saveWordReport.bind(this);

    this.handleContextMenu = this.handleContextMenu.bind(this);

    this.showEditModal = this.showEditModal.bind(this);
    this.hideEditModal = this.hideEditModal.bind(this);
    this.updateAnnotationEdit = this.updateAnnotationEdit.bind(this);
    this.saveEditAnnotation = this.saveEditAnnotation.bind(this);

    this.handleChangeClave = this.handleChangeClave.bind(this);
    this.handleChangeCode = this.handleChangeCode.bind(this);
    this.handleChangeDescription = this.handleChangeDescription.bind(this);
    this.handleChangeTraduction = this.handleChangeTraduction.bind(this);

    this.fetchSavedGlosa = this.fetchSavedGlosa.bind(this);
  }

  componentDidMount() {
    let path = window.location.pathname;
    let projectId = path.split("/")[4];
    this.fetchProject(projectId);
  }

  fetchProject = projectId => {
    let payload = {
      project: projectId
    };

    fetch("../../../api/project/register", {
      method: "POST",
      body: JSON.stringify(payload),
      headers: {
        "Content-Type": "application/json"
      }
    })
      .then(response => {
        response.json().then(data => {
          this.fetchSavedGlosa(data);
        });
      })
      .catch(error => {
        console.log(error);
        this.setState({
          showLoading: false
        });
      });
  };

  fetchGlosa = (project, savedGlosa) => {
    let path = project.ruta_trabajo.slice(2);

    path += `${project.nombre_proyecto}.eaf`;

    let payload = {
      project: project.id_proyecto,
      filePath: path
    };

    fetch("../../../api/annotation/registers", {
      method: "POST",
      body: JSON.stringify(payload),
      headers: {
        "Content-Type": "application/json"
      }
    })
      .then(response => {
        response.json().then(data => {
          data.forEach(glosa => {
            let saved = savedGlosa.find(
              glos => glos.annotation_TIER_REF === glosa.ANNOTATION_ID
            );

            if (saved !== undefined) {
              glosa.glosas = saved.steps;
              glosa.saved = true;
            } else {
              glosa.glosas = [];
              glosa.saved = false;
            }

            glosa.collapsed = true;
          });
          this.setState({
            idProject: project.id_proyecto,
            project: project.nombre_proyecto,
            glosado: data,
            showLoading: false,
            path: path
          });
        });
      })
      .catch(error => {
        console.log(error);
        this.setState({
          showLoading: false
        });
      });
  };

  fetchSavedGlosa = project => {
    let payload = {
      project: project.id_proyecto
    };

    fetch("../../../api/glosa/saved/annotation/list", {
      method: "POST",
      body: JSON.stringify(payload),
      headers: {
        "Content-Type": "application/json"
      }
    })
      .then(response => {
        response.json().then(data => {
          this.fetchGlosa(project, data);
        });
      })
      .catch(error => {
        console.log(error);
        this.setState({
          showLoading: false
        });
      });
  };

  updateSelectValue = (glosaId, stepId) => evt => {
    let glosado = [...this.state.glosado];
    let glosa = glosado.find(glosa => glosa.ANNOTATION_ID === glosaId);
    let step = glosa.glosas.find(step => step.id === stepId);
    step.select = evt.target.value;

    this.setState({
      glosado: glosado
    });
  };

  collapseHandler = (glosaId, newAnlys) => () => {
    this.glosaAnalysis(glosaId, newAnlys);
  };

  glosaAnalysis = (glosaId, newAnlys) => {
    let glosado = [...this.state.glosado];
    let glosa = glosado.find(glosa => glosa.ANNOTATION_ID === glosaId);

    if ((newAnlys && glosa.collapsed) || !newAnlys) {
      this.setState({
        showLoading: true
      });

      if (newAnlys) {
        glosa.collapsed = !glosa.collapsed;
      }

      let payload = {
        text: glosa.ANNOTATION_VALUE,
        uuid: localStorage.getItem("uuid")
      };

      fetch("../../../api/glosa/analysis", {
        method: "POST",
        body: JSON.stringify(payload),
        headers: {
          "Content-Type": "application/json"
        }
      })
        .then(response => {
          response.json().then(data => {
            let steps = data[0].steps;
            if (glosa.saved) {
              steps.forEach((step, index) => {
                step.select = glosa.glosas[index].select;
              });
            }
            glosa.glosas = steps;

            this.setState({
              glosado: glosado,
              showLoading: false
            });
          });
        })
        .catch(error => {
          console.log(error);
          this.setState({
            showLoading: false
          });
        });
    } else {
      glosa.collapsed = !glosa.collapsed;

      this.setState({
        glosado: glosado
      });
    }
  };

  saveGlosa = glosaId => () => {
    this.setState({
      showLoading: true
    });
    let glosado = [...this.state.glosado];
    let glosa = glosado.find(glosa => glosa.ANNOTATION_ID === glosaId);

    let annotationData = {
      projectID: this.state.idProject,
      filePath: this.state.path,
      isNew: !glosa.saved,
      ANNOTATION_ID: glosa.ANNOTATION_ID,
      ANNOTATION_REF: glosa.ANNOTATION_REF,
      steps: glosa.glosas
    };

    fetch("../../../api/glosa/saved/annotation/v3", {
      method: "POST",
      body: JSON.stringify(annotationData),
      headers: {
        "Content-Type": "application/json"
      }
    })
      .then(response => {
        response.json().then(data => {
          if (data) {
            glosa.collapsed = true;
            glosa.saved = true;
          }
          this.setState({
            glosado: glosado,
            showLoading: false
          });
        });
      })
      .catch(error => {
        console.log(error);
        this.setState({
          showLoading: false
        });
      });
  };

  showModal = () => {
    this.setState({ modalShow: true });
  };

  hideModal = () => {
    this.setState({ modalShow: false });
  };

  saveDocumentReport = () => {
    let payload = {
      id_proyecto: this.state.idProject,
      titulo: "REPORTE DE ERROR EN DOCUMENTO",
      reporte: `El documento ${this.state.project}.eaf ha sido reportado.`,
      comentario: this.state.reportDocMsg,
      tipo: "document"
    };

    fetch("../../../api/report/create", {
      method: "POST",
      body: JSON.stringify(payload),
      headers: {
        "Content-Type": "application/json"
      }
    })
      .then(response => {
        response.json().then(data => {
          this.hideModal();
          this.setState({
            showLoading: false
          });
        });
      })
      .catch(error => {
        console.log(error);
        this.setState({
          showLoading: false
        });
      });
  };

  updateReportDocMsg = e => {
    this.setState({
      reportDocMsg: e.target.value
    });
  };

  showConfirmModal = (word, annotation) => () => {
    let reported = {
      word: word,
      annotation: annotation
    };

    this.setState({
      reportedWord: reported,
      confirmModal: true
    });
  };

  hideConfirmModal = () => {
    this.setState({ confirmModal: false });
  };

  updateReportWordMsg = e => {
    this.setState({
      reportWordMsg: e.target.value
    });
  };

  saveWordReport = () => {
    let payload = {
      id_proyecto: this.state.idProject,
      titulo: "REPORTE DE PALABRA EN ANOTACIÓN",
      reporte: `La palabra ${this.state.reportedWord.word} ha sido reportada. En la anotación ${this.state.reportedWord.annotation}.`,
      comentario: this.state.reportWordMsg,
      tipo: "word"
    };

    fetch("../../../api/report/create", {
      method: "POST",
      body: JSON.stringify(payload),
      headers: {
        "Content-Type": "application/json"
      }
    })
      .then(response => {
        response.json().then(data => {
          console.log(data);
          this.hideConfirmModal();
          this.setState({
            showLoading: false
          });
        });
      })
      .catch(error => {
        console.log(error);
        this.setState({
          showLoading: false
        });
      });
  };

  handleContextMenu = selectedWord => e => {
    e.preventDefault();

    const contextMenu = document.querySelector(".context-wrapper");

    let x = e.clientX,
      y = e.clientY,
      winWidth = window.innerWidth,
      winHeight = window.innerHeight,
      cmWidth = contextMenu.offsetWidth,
      cmHeight = contextMenu.offsetHeight;

    x = x > winWidth - cmWidth ? winWidth - cmWidth : x;
    y = y > winHeight - cmHeight ? winHeight - cmHeight : y;

    contextMenu.style.left = `${x}px`;
    contextMenu.style.top = `${y}px`;

    this.props.handleContext();

    this.setState({
      selectedWord: selectedWord
    });
  };

  showEditModal = annotation => () => {
    annotation.ANNOTATION_ORIGINAL = annotation.ANNOTATION_VALUE;
    this.setState({
      editModal: true,
      annotationEdit: annotation
    });
  };

  hideEditModal = () => {
    this.setState({
      editModal: false
    });
  };

  updateAnnotationEdit = e => {
    let annot = this.state.annotationEdit;
    annot.ANNOTATION_VALUE = e.target.value;

    this.setState({
      annotationEdit: annot
    });
  };

  saveEditAnnotation = () => {
    let payload = {
      filePath: this.state.path,
      ANNOTATION_ID: this.state.annotationEdit.ANNOTATION_ID,
      ANNOTATION_REF: this.state.annotationEdit.ANNOTATION_REF,
      ANNOTATION_ORIGINAL: this.state.annotationEdit.ANNOTATION_ORIGINAL,
      ANNOTATION_VALUE: this.state.annotationEdit.ANNOTATION_VALUE,
      projectID: this.state.idProject,
      title: "REPORTE DE ANOTACIÓN",
      report: `La anotación con ID ${this.state.annotationEdit.ANNOTATION_ID} ha sido modificada.`,
      type: "annotation"
    };

    fetch("../../../api/annotation/update", {
      method: "POST",
      body: JSON.stringify(payload),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        response.json().then((data) => {
          this.hideEditModal();
          this.glosaAnalysis(this.state.annotationEdit.ANNOTATION_ID, false);
          this.setState({
            showLoading: false,
          });
        });
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          showLoading: false,
        });
      });
  };

  showDicModal = base => () => {
    this.setState({
      selectedCodeValue: [],
      valueCode: "",
      valueDescription: "",
      valueTraduction: "",
      valueClave: base,
      showDictionaryModal: true
    });
  };

  hideDicRegister = () => {
    this.setState({
      showDictionaryModal: false
    });
  };

  onSelect = evt => {
    let selectValuecode = [];
    evt.forEach(data => {
      selectValuecode.push(data.name);
    });

    let valueCode = selectValuecode.join("/");
    this.setState({
      valueCode: valueCode
    });
  };

  onRemove = evt => {
    let selectValuecode = [];
    evt.forEach(data => {
      selectValuecode.push(data.name);
    });

    let valueCode = selectValuecode.join("/");
    this.setState({
      valueCode: valueCode
    });
  };

  handleChangeClave = event => {
    this.setState({ valueClave: event.target.value });
  };

  handleChangeCode = event => {
    this.setState({ valueCode: event.target.value });
  };

  handleChangeDescription = event => {
    this.setState({ valueDescription: event.target.value });
  };

  handleChangeTraduction = event => {
    this.setState({ valueTraduction: event.target.value });
  };

  saveNewRegister = () => {
    let register = {
      clave: this.state.valueClave,
      code: this.state.valueCode,
      description: this.state.valueDescription,
      traduction: this.state.valueTraduction,
      extra: ""
    };

    if (this.validateForm()) {
      fetch(`../../../api/dictionary/bases/create`, {
        method: "POST",
        body: JSON.stringify(register),
        headers: {
          "Content-Type": "application/json"
        }
      })
        .then(response => {
          response.json().then(data => {
            this.showAlertSuccess();
          });
        })
        .catch(error => {
          console.log(error);
        });
    }
  };

  validateForm() {
    const reg_spanish = new RegExp("^[a-zA-ZñÑÁÉÍÓÚáéíóúÜü0-9-.,/ ]+$");
    const reg_maya = new RegExp(
      "^(a|aa|áa|a'|a'a|b|ch|ch'|e|ee|ée|e'|e'e|i|ii|íi|i'|i'i|j|k|k'|l|m|n|o|oo|óo|o'|o'o|p|p'|r|s|t|t'|ts|ts'|u|uu|úu|u'|u'u|w|x|y)+$"
    );

    let clave = this.state.valueClave;
    let code = this.state.valueCode;
    let description = this.state.valueDescription;
    let errorClave = clave === "" ? true : false;
    let errorValidateClave =
      !errorClave && !reg_maya.test(clave) ? true : false;
    let errorCode = code === "" ? true : false;
    let errorDescription = description === "" ? true : false;
    let errorValidateDescription =
      !errorDescription && !reg_spanish.test(description) ? true : false;

    this.setState({
      errorClave: errorClave,
      errorCode: errorCode,
      errorDescription: errorDescription,
      errorValidateDescription: errorValidateDescription,
      errorValidateClave: errorValidateClave
    });

    if (
      errorClave ||
      errorValidateClave ||
      errorCode ||
      errorDescription ||
      errorValidateDescription
    ) {
      return false;
    }

    return true;
  }

  showAlertSuccess = () => {
    this.setState({
      showDictionaryModal: false,
      showAlertSuccess: true
    });
  };

  hideAlertSuccess = () => evt => {
    this.setState({
      showAlertSuccess: false
    });
  };

  renderModal() {
    const { t } = this.props;
    return (
      <>
        {this.state.showDictionaryModal ? (
          <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
              <div className="relative my-6 mx-auto max-w-2xl w-1/4">
                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                  <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                    <h3 className="text-3xl font-semibold">
                      <>
                        {t(
                          "menu_diccionarios.mensajes.nuevo_registro",
                          "Nuevo Registro"
                        )}{" "}
                      </>
                    </h3>
                    <button
                      className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                      type="button"
                      onClick={this.hideDicRegister}
                    >
                      <svg
                        className="h-6 w-6"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </button>
                  </div>

                  <div className="relative p-6 flex-auto">
                    <div className="flex flex-wrap -mx-3">
                      <div className="w-full px-3 mb-6 md:mb-0">
                        <label
                          className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 text-left"
                          htmlFor="clave"
                        >
                          {t("menu_diccionarios.titulos_de_tabla.base", "Base")}
                        </label>
                        <input
                          className={`${this.state.errorClave ||
                            this.state.errorValidateClave
                            ? ""
                            : "mb-3"
                            } appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white`}
                          id="clave"
                          type="text"
                          value={this.state.valueClave}
                          onChange={this.handleChangeClave}
                        />
                        {this.state.errorClave ? (
                          <p className="text-red-500 text-xs italic mb-3">
                            {t(
                              "menu_diccionarios.mensajes.ingrese_una_base",
                              "Ingrese una base."
                            )}
                          </p>
                        ) : null}
                        {this.state.errorValidateClave ? (
                          <p className="text-red-500 text-xs italic mb-3">
                            {t(
                              "menu_diccionarios.mensajes.regex_base",
                              "Solo se aceptan estas letras a, aa, áa, a', a'a, b, ch, ch', e, ee, ée, e', e'e, i, ii, íi, i', i'i, j, k, k', l, m, n, o, oo, óo, o', o'o, p, p', r, s, t, t', ts, ts', u, uu, úu, u', u'u, w, x, y."
                            )}
                          </p>
                        ) : null}
                      </div>
                    </div>

                    <div className="flex flex-wrap -mx-3">
                      <div className="w-full px-3 mb-6 md:mb-0">
                        <label
                          className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 text-left"
                          htmlFor="code"
                        >
                          {t(
                            "menu_diccionarios.titulos_de_tabla.categoria",
                            "Categoria"
                          )}
                        </label>
                        <Multiselect
                          className={`${this.state.errorCode ? "" : "mb-3"
                            } appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-1 px-1 leading-tight focus:outline-none focus:bg-white`}
                          options={this.state.codeOptions}
                          selectedValues={this.state.selectedCodeValue}
                          placeholder=""
                          emptyRecordMsg="No hay más opciones"
                          onSelect={this.onSelect}
                          onRemove={this.onRemove}
                          displayValue="name"
                        />
                        {this.state.errorCode ? (
                          <p className="text-red-500 text-xs italic mb-3">
                            {t(
                              "menu_diccionarios.mensajes.seleccione_una_categoria",
                              "Seleccione una categoria."
                            )}
                          </p>
                        ) : null}
                      </div>
                    </div>

                    <div className="flex flex-wrap -mx-3">
                      <div className="w-full px-3 mb-6 md:mb-0">
                        <label
                          className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 text-left"
                          htmlFor="description"
                        >
                          {t("menu_diccionarios.titulos_de_tabla.glosa", "Glosa")}
                        </label>
                        <input
                          className={`${this.state.errorDescription ||
                            this.state.errorValidateDescription
                            ? ""
                            : "mb-3"
                            } appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white`}
                          id="description"
                          type="text"
                          value={this.state.valueDescription}
                          onChange={this.handleChangeDescription}
                        />
                        {this.state.errorDescription ? (
                          <p className="text-red-500 text-xs italic mb-3">
                            {t(
                              "menu_diccionarios.mensajes.ingrese_una_glosa",
                              "Ingrese una glosa."
                            )}
                          </p>
                        ) : null}
                        {this.state.errorValidateDescription ? (
                          <p className="text-red-500 text-xs italic mb-3">
                            {t(
                              "menu_diccionarios.mensajes.regex_glosa",
                              "Solo se aceptan a-z o A-Z."
                            )}
                          </p>
                        ) : null}
                      </div>
                    </div>

                    <div className="flex flex-wrap -mx-3">
                      <div className="w-full px-3 mb-6 md:mb-0">
                        <label
                          className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 text-left"
                          htmlFor="traduction"
                        >
                          {t(
                            "menu_diccionarios.titulos_de_tabla.traduccion",
                            "Traducción"
                          )}
                        </label>
                        <input
                          className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-2 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                          id="traduction"
                          type="text"
                          value={this.state.valueTraduction}
                          onChange={this.handleChangeTraduction}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                    <button
                      className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="button"
                      onClick={this.saveNewRegister}
                    >
                      {t("menu_diccionarios.acciones.crear", "Crear")}
                    </button>
                    <button
                      className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="button"
                      onClick={this.hideDicRegister}
                    >
                      {t("menu_diccionarios.acciones.cerrar", "cerrar")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
          </>
        ) : null}
      </>
    );
  }

  renderAlertSuccess() {
    const { t } = this.props;
    return (
      <>
        {this.state.showAlertSuccess ? (
          <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
              <div className="relative w-auto my-6 mx-auto max-w-3xl">
                <div className="flex flex-col p-5 rounded-lg shadow bg-white">
                  <div className="flex">
                    <div>
                      <svg
                        className="w-6 h-6 fill-current text-green-500"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                      >
                        <path d="M0 0h24v24H0V0z" fill="none" />
                        <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm4.59-12.42L10 14.17l-2.59-2.58L6 13l4 4 8-8z" />
                      </svg>
                    </div>

                    <div className="ml-3">
                      <h2 className="font-semibold text-gray-800">
                        {t(
                          "menu_diccionarios.mensajes.exitosamente",
                          "Exitosamente"
                        )}
                      </h2>
                    </div>
                  </div>

                  <div className="flex items-center justify-center mt-3">
                    <button
                      onClick={this.hideAlertSuccess()}
                      className="bg-emerald-400 text-white hover:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150"
                    >
                      {t("menu_diccionarios.mensajes.aceptar", "Aceptar")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : null}
      </>
    );
  }

  render() {
    const { t } = this.props;

    return (
      <div className="grid grid-cols-12 w-full pl-6 py-3 h-full overflow-auto">
        {this.state.showLoading ? (
          <LoadingScreen title="Cargando..." message="Espere un momento" />
        ) : null}
        <div className="col-span-10 w-full mx-auto">
          {this.state.glosado.map((glosa, glosaIndex) => {
            return (
              <div
                key={glosa.ANNOTATION_ID}
                className={`bg-white px-4 py-4 mb-2 shadow-lg rounded-md ${glosa.saved ? "border-4 border-cdmx-green" : ""
                  }`}
              >
                <div className="flex justify-between">
                  <span className="font-bold text-xl tracking-wide">
                    <span className="text-white bg-gray-600 px-3 py-1 rounded select-none">{glosaIndex + 1}</span> {glosa.ANNOTATION_VALUE}
                  </span>
                  <button
                    onClick={this.collapseHandler(glosa.ANNOTATION_ID, true)}
                  >
                    {/* <button onClick={this.fetchGlosas()}> */}
                    {glosa.collapsed ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="w-6 h-6 fill-current"
                      >
                        <path d="m12 15.375-6-6 1.4-1.4 4.6 4.6 4.6-4.6 1.4 1.4Z" />
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="w-6 h-6 fill-current"
                      >
                        <path d="m7.4 15.375-1.4-1.4 6-6 6 6-1.4 1.4-4.6-4.6Z" />
                      </svg>
                    )}
                  </button>
                </div>
                <div
                  className={`${glosa.collapsed ? "hidden" : "flex"} flex-col`}
                >
                  <div className="flex flex-wrap w-full">
                    {glosa.glosas.map(step => {
                      return (
                        <div
                          key={step.id}
                          className="flex flex-col w-1/2 md:w-1/4 px-2 py-2"
                        >
                          <div
                            className="flex items-center justify-between mb-2 cursor-context-menu"
                            onContextMenu={this.handleContextMenu({
                              step: step.word,
                              annotation: glosa.ANNOTATION_ID
                            })}
                          >
                            <span className="font-semibold text-lg tracking-wide text-gray-900">
                              {step.word}
                            </span>
                            {/* <button
                              className="bg-red-500 hover:bg-red-600 text-white font-bold p-0.5 rounded shadow"
                              onClick={this.showConfirmModal(
                                step.word,
                                glosa.ANNOTATION_ID
                              )}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="w-6 h-6 fill-current"
                              >
                                <path d="M5 21V4h9l.4 2H20v10h-7l-.4-2H7v7Zm7.5-11Zm2.15 4H18V8h-5.25l-.4-2H7v6h7.25Z" />
                              </svg>
                            </button> */}
                          </div>
                          {
                            step.parsing.length > 0 ? (
                              <select
                                className="px-3 py-1.5 text-base font-normal border border-solid border-gray-300 rounded"
                                value={step.select}
                                onChange={this.updateSelectValue(
                                  glosa.ANNOTATION_ID,
                                  step.id
                                )}
                              >
                                <option disabled value={""}>
                                  Seleccione una opción
                                </option>
                                {step.parsing.map((parse, index) => {
                                  return (
                                    <option key={index} value={parse}>
                                      {parse}
                                    </option>
                                  );
                                })}
                                {/* <option value="otro">Otro</option> */}
                              </select>
                            ) : (
                              <span className="px-3 py-1.5 text-base font-normal border border-solid border-gray-300 bg-neutral-200 rounded select-none">
                                No hay coincidencias.
                              </span>
                            )
                            /* (
                            <input
                              className="px-3 py-1.5 text-base font-normal border border-solid border-gray-300 rounded"
                              type="text"
                              placeholder="Ingrese el término"
                            />
                          ) */
                          }
                          {/* {step.select === "otro" ? (
                            <input
                              className="mt-2 px-3 py-1.5 text-base font-normal border border-solid border-gray-300 rounded"
                              type="text"
                              placeholder="Ingrese el término"
                            />
                          ) : null} */}
                        </div>
                      );
                    })}
                  </div>
                  <div className="flex justify-end space-x-3">
                    <button
                      className="bg-cdmx-blue-light hover:bg-cdmx-blue-light/90 text-white font-bold px-4 py-2 rounded shadow"
                      onClick={this.showEditModal(glosa)}
                    >
                      Editar
                    </button>
                    <button
                      className="bg-cdmx-blue-light hover:bg-cdmx-blue-light/90 text-white font-bold px-4 py-2 rounded shadow"
                      onClick={this.collapseHandler(glosa.ANNOTATION_ID, false)}
                    >
                      Analizar
                    </button>

                    <button
                      onClick={this.saveGlosa(glosa.ANNOTATION_ID)}
                      className="bg-cdmx-green hover:bg-cdmx-green/90 text-white font-bold px-4 py-2 rounded shadow"
                    >
                      Guardar
                    </button>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <div className="col-span-2 grid justify-items-center">
          <div className="fixed flex flex-col">
            {/* <button className="bg-green-500 hover:bg-green-600 text-white font-bold px-4 py-2 mb-2 rounded shadow">
              Guardar
            </button> */}
            <button
              className="bg-cdmx-red hover:bg-cdmx-red/90 text-white font-bold px-4 py-2 mb-2 rounded shadow"
              onClick={this.showModal}
            >
              {t(
                "glosado.acciones.reportar_documentos",
                "Reportar documento"
              )}
            </button>
          </div>
        </div>

        <Modal
          show={this.state.modalShow}
          handleClose={this.hideModal}
          size="w-1/4"
          actions={[this.saveDocumentReport]}
          labels={["Enviar reporte"]}
        >
          <div className="flex flex-col">
            <span className="text-xl font-bold tracking-wider mb-2">
              Reporte de documento
            </span>
            <div className="flex flex-col mb-4">
              <label
                htmlFor="error-msg"
                className="block text-base font-medium text-gray-700"
              >
                Motivo de reporte:
              </label>
              <textarea
                name="error-msg"
                className="mt-2 px-3 py-1.5 text-base font-normal border border-solid border-gray-300 rounded resize-none"
                cols="30"
                rows="6"
                value={this.state.reportDocMsg}
                onChange={this.updateReportDocMsg}
              ></textarea>
            </div>
          </div>
        </Modal>

        <Modal
          show={this.state.confirmModal}
          handleClose={this.hideConfirmModal}
          size="w-1/4"
          actions={[this.saveWordReport]}
          labels={["Enviar reporte"]}
        >
          <div className="flex flex-col">
            <span className="text-xl font-bold tracking-wider mb-2">
              Reporte de palabra
            </span>
            <div className="flex flex-col mb-4">
              <label
                htmlFor="error-wmsg"
                className="block text-base font-medium text-gray-700"
              >
                Comentarios:
              </label>
              <textarea
                name="error-wmsg"
                className="mt-2 px-3 py-1.5 text-base font-normal border border-solid border-gray-300 rounded resize-none"
                cols="30"
                rows="6"
                value={this.state.reportWordMsg}
                onChange={this.updateReportWordMsg}
              ></textarea>
            </div>
          </div>
        </Modal>

        <div
          className={`context-wrapper ${this.props.showContext ? "visible" : "invisible"
            } absolute bg-white rounded-md shadow-md`}
        >
          <div className="content">
            <ul className="menu px-4 py-2">
              {/* <li className="item list-none flex cursor-pointer px-2 items-center rounded mb-1 hover:bg-gray-100">
                <span>Corregir ortografía</span>
              </li> */}
              <li
                className="item list-none flex cursor-pointer px-2 items-center rounded mb-1 hover:bg-gray-100"
                onClick={this.showDicModal(this.state.selectedWord.step)}
              >
                <span>Agregar palabra</span>
              </li>
              <li
                className="item list-none flex cursor-pointer px-2 items-center rounded mb-1 hover:bg-gray-100"
                onClick={this.showConfirmModal(
                  this.state.selectedWord.step,
                  this.state.selectedWord.annotation
                )}
              >
                <span>Reportar palabra</span>
              </li>
            </ul>
          </div>
        </div>

        <Modal
          show={this.state.editModal}
          handleClose={this.hideEditModal}
          size="w-1/4"
          actions={[this.saveEditAnnotation]}
          labels={["Guardar"]}
        >
          <div className="flex flex-col">
            <span className="text-xl font-bold tracking-wider mb-2">
              Editar anotación
            </span>
            <div className="flex flex-col mb-4">
              <label
                htmlFor="error-wmsg"
                className="block text-base font-medium text-gray-700"
              >
                Anotación:
              </label>
              <textarea
                name="error-wmsg"
                className="mt-2 px-3 py-1.5 text-base font-normal border border-solid border-gray-300 rounded resize-none"
                cols="30"
                rows="6"
                value={this.state.annotationEdit.ANNOTATION_VALUE}
                onChange={this.updateAnnotationEdit}
              ></textarea>
            </div>
          </div>
        </Modal>

        {this.renderModal()}
        {this.renderAlertSuccess()}
      </div>
    );
  }
}

export default withTranslation()(GlosaEditor);

import React from "react";

const ImageModal = ({ handleClose, actions, multiple}) => {
  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative my-6 mx-auto  w-96">
          {/*content*/}
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            {/* CloseButton */}
            <button type="button" className="absolute z-10 top-0 right-0 focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium text-md dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900" onClick={handleClose}>
              <svg className="h-3 w-3" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>

            {/* CloseButton */}
            {/* Body */}
            <div className="relative p-6 flex-auto">
              <div className=" flex justify-center items-center w-full shadow-md">
                <label htmlFor="dropzone-file" className="flex flex-col justify-center items-center w-full h-64  rounded-lg border-2 border-gray-300 border-dashed cursor-pointer bg-white">
                  <div className="flex flex-col justify-center items-center pt-5 pb-6">
                    <svg aria-hidden="true" className="mb-3 w-10 h-10 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path></svg>
                    <p className="mb-2 text-sm text-gray-500 dark:text-gray-400"><span className="font-semibold">Click para subir</span></p>
                    <p className="text-xs text-gray-500 dark:text-gray-400">SVG, PNG, JPG, GIF (MAX. 800x400px)</p>
                  </div>
                  <input id="dropzone-file" type="file" name="fotos" className="hidden" multiple={multiple} accept="image/*" onChange={actions} />
                </label>
              </div>

            </div>
            {/* Body */}
          </div>
        </div>
      </div><div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
}

export default ImageModal;


import React from "react";

class LogsView extends React.Component {
  constructor() {
    super()
    this.state = {
      text: '',
      fileName: ''
    };

  }

  componentDidMount() {
    let path = window.location.pathname;
    let fileName = path.split("/")[4];
    this.openFile(fileName);
  }

  openFile(fileName) {
    let body = {
        fileName: fileName
    };
    this.setState({
        fileName: fileName
    })

    fetch("../../../api/logger/file", {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
            "Content-Type": "application/json",
        },
    })
    .then((response) => {
        response.json().then((data) => {
            console.log(data)
            this.setState({
                text: data['text']
            })
        });
    })
    .catch((error) => {
        console.log("error", error);
    });
  }

  
  render() {
    return (
      <div className="w-full px-4 py-3 h-full max-h-screen overflow-auto">
        <h1 className="text-3xl">{this.state.fileName}</h1>
        <code className="text-sm">
          <pre>{this.state.text}</pre>
        </code>";
      </div>
    );
  }
}

export default LogsView;

/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { withTranslation } from "react-i18next";
import PieChartComponent from "./PieChartComponent";
import DataTable from "react-data-table-component";
import Modal from "./Modal";

class Storage extends React.Component {
  constructor() {
    super();
    this.state = {
      tableInfo: [],
      totalCapacity: 0,
      freePartitionSpace: 0,
      usableParitionSpace: 0,
      usedPartitionSpace: 0,
      showMetadataModal: false,
      select_project: "",
      metadata: "",
      recordedHours: 0
    };

    this.showMetadata = this.showMetadata.bind(this);
    this.hideMetadata = this.hideMetadata.bind(this);
  }

  fetchStorage() {
    const formData = new FormData();
    formData.append("uuid", localStorage.getItem("uuid"));

    const requestOptions = {
      method: "POST",
      body: formData
    };
    fetch("../api/storage/getStorage", requestOptions)
      .then(response => {
        console.log(response);
        response.json().then(data => {
          console.log(JSON.parse(data["metadata"][0]["metadata"]));
          this.createRows(data["metadata"]);
          this.setState({
            totalCapacity: data["totalCapacity"],
            freePartitionSpace: data["freePartitionSpace"],
            usableParitionSpace: data["usableParitionSpace"],
            usedPartitionSpace: data["usedPartitionSpace"]
            //tableInfo: data['metadata']
          });
        });
      })
      .catch(error => {
        console.log("error", error);
      });
  }

  calculateTime = secs => {
    let hours = Math.floor(secs / 3600);
    let minutes = Math.floor((secs % 3600) / 60);
    let remainingSeconds = Math.round(secs % 60);

    let formattedHours = hours.toString().padStart(1, '0');
    let formattedMinutes = minutes.toString().padStart(2, '0');
    let formattedSeconds = remainingSeconds.toString().padStart(2, '0');

    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  };

  formatBytes(bytes, decimals = 2) {
    if (!+bytes) return '0 Bytes'

    const k = 1000
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
  }

  formatProjectName = project => {
    return project.slice(0, project.lastIndexOf("_"));
  };

  componentDidMount() {
    this.fetchStorage();
  }

  createRows(data) {
    const data2 = [];
    let recordedHours = 0;
    data.forEach(element => {
      console.log({ element });
      recordedHours = recordedHours + parseFloat(element.duration)
      data2.push({
        id: element.id_project,
        metadata: JSON.stringify(JSON.parse(element.metadata), undefined, 2),
        Proyecto: this.formatProjectName(element.ruta_trabajo.split("/")[4]),
        Multimedia: element.mime_type,
        Canales: element.channels,
        //Tamaño: this.formatBytes(element.tamaño),
        Tamaño: element.tamaño,
        Duración: this.calculateTime(element.duration),
        //Tamaño_proyecto: this.formatBytes(element.folderSize),
        Tamaño_proyecto: element.folderSize,
      });
    });
    this.setState({
      tableInfo: data2,
      recordedHours: this.calculateTime(recordedHours)
    }, () => { console.log(this.state.recordedHours) });
  }

  updateaMetadata(row) {
    let data = {
      project: row.id
    };

    fetch("../api/storage/setStorage", {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json"
      }
    })
      .then(response => {
        response.json().then(data => {
          this.fetchStorage();
        });
      })
      .catch(error => {
        console.log(error);
      });
  }

  showMetadata(data) {
    console.log(data);
    this.setState({
      showMetadataModal: true,
      select_project: data.Proyecto,
      metadata: data.metadata
    });
  }

  hideMetadata() {
    this.setState({
      showMetadataModal: false,
      select_project: "",
      metadata: ""
    });
  }

  render() {
    const { t } = this.props;

    const columns = [
      {
        name: "Proyecto",
        selector: row => row.Proyecto,
        sortable: true,
        width: "16rem"
      },
      {
        name: "Multimedia",
        selector: row => row.Multimedia,
        sortable: true,
        width: "10rem"
      },
      {
        name: "Canales",
        selector: row => row.Canales,
        sortable: true,
        hide: "md",
        width: "8rem"
      },
      {
        name: "Peso del multimedia",
        selector: row => row.Tamaño,
        sortable: true,
        hide: "md",
        cell: row => {
          return (
            this.formatBytes(row.Tamaño)
          );
        }
      },
      {
        name: "Duración",
        selector: row => row.Duración,
        sortable: true,
        hide: "md",
        width: "9rem"
      },
      {
        name: "Peso total del proyecto",
        selector: row => row.Tamaño_proyecto,
        sortable: true,
        cell: row => {
          return (
            this.formatBytes(row.Tamaño_proyecto)
          );
        }
        
      },
      {
        name: "Acciones",
        selector: row => row.Tamaño_proyecto,
        sortable: true,
        width: "170px",
        cell: row => {
          return (
            <div className="flex flex-wrap w-full">
              <button
                onClick={() => this.showMetadata(row)}
                className={`text-white bg-infokab-gray hover:bg-infokab-gray/90 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg ${this.props.isFontBig ? "text-lg" : "text-sm"
                  } px-2 py-2 text-center mr-2 mb-2`}
              >
                Ver
              </button>
              <button
                onClick={() => this.updateaMetadata(row)}
                className={`text-white bg-infokab-gray hover:bg-infokab-gray/90 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg ${this.props.isFontBig ? "text-lg" : "text-sm"
                  } px-2 py-2 text-center mr-2 mb-2`}
              >
                Actualizar
              </button>
            </div>
          );
        }
      }
    ];

    const paginationComponentOptions = {
      rowsPerPageText: "Filas por página",
      rangeSeparatorText: "de",
      selectAllRowsItem: true,
      selectAllRowsItemText: "Todos"
    };

    const tableCustomStyles = {
      headRow: {
        style: {
          color: "#fff",
          backgroundColor: "#0f172a",
          fontSize: this.props.isFontBig ? "1.5rem" : "1.125rem"
        }
      },
      rows: {
        style: {
          color: "black",
          backgroundColor: "#FFF",
          fontSize: this.props.isFontBig ? "1.125rem" : "0.875rem"
        },
        stripedStyle: {
          color: "#red",
          backgroundColor: "#000"
        }
      },
      pagination: {
        style: {
          fontSize: this.props.isFontBig ? "1rem" : "0.75rem"
        }
      }
    };

    return (
      <div className="w-full h-full p-6 overflow-auto">
        <div className="flex flex-wrap bg-white p-6 h-full">
          <div className="flex w-full md:flex-row xs:flex-row sm:flex-row lg:flex-row flex-wrap">
            <div className="flex   w-full md:w-1/4 p-4 text-center items-center justify-center text-gray-700">
              <div className="sm:w-full xs:w-1/2 md:fixed md:w-1/5 lg:w-1/5 lg:fixed top-1/4 mb-10">
                <PieChartComponent
                  class=""
                  libre={this.state.freePartitionSpace}
                  utilizado={this.state.usedPartitionSpace}
                  horas={this.state.recordedHours}
                  totalCapacity={this.state.totalCapacity}
                  isFontBig={this.props.isFontBig}
                />
              </div>

            </div>
            <div className="flex   w-full md:w-3/4 p-4 text-center items-center justify-center text-gray-200">
              <div className="w-full">
                <DataTable
                  columns={columns}
                  data={this.state.tableInfo}
                  defaultSortFieldId="Proyecto"
                  pagination
                  paginationComponentOptions={paginationComponentOptions}
                  customStyles={tableCustomStyles}
                />
              </div>
            </div>
          </div>
        </div>

        <Modal
          show={this.state.showMetadataModal}
          handleClose={this.hideMetadata}
          size="w-1/4"
          isFontBig={this.props.isFontBig}
        >
          <div className="flex flex-col overflow-x-auto overflow-y-auto max-h-96">
            <span
              className={`${this.props.isFontBig ? "text-2xl" : "text-lg"}`}
            >
              {this.state.select_project}
            </span>
            <pre>{this.state.metadata}</pre>
          </div>
        </Modal>
      </div>
    );
  }
}

export default withTranslation()(Storage);

import React from "react";
import { Link } from "react-router-dom";

class Logs extends React.Component {
  constructor() {
    super();

    this.state = {
      fileList: [],
    };
  }

  componentDidMount() {
    this.fileList();
  }

  fileList() {
    let body = {
      page: this.state.currentPageBases,
      record: this.state.maxRecords,
      search: this.state.searchBases,
    };

    fetch("../api/logger/list", {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        response.json().then((data) => {
            console.log(data)
            this.setState({
                fileList: data
            })
        });
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

  
  render() {
    return (
      <div className="w-full px-4 py-3 h-full max-h-screen overflow-auto">
        <ul className="space-y-1 max-w-md list-disc list-inside text-gray-500 dark:text-gray-400">
            {
                this.state.fileList.map((register, index) => {
                    return (
                        <li className="text-3xl">
                            <Link to={`view/${register}`}>
                                {register}
                            </Link>
                        </li>
                    )
                }
            )}
        </ul>
      </div>
    );
  }
}

export default Logs;

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { createRef } from "react";

import { Swiper, SwiperSlide } from "swiper/react";
import { format } from "date-fns";
import * as turf from "@turf/turf";
import { withTranslation } from "react-i18next";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/lazy";

// import required modules
import { Navigation } from "swiper";
import { Autoplay } from "swiper";
import { Lazy } from "swiper";

import {
  useMap,
  MapContainer,
  TileLayer,
  Rectangle,
  useMapEvents,
  Marker
} from "react-leaflet";

import { GeoJSON } from "react-leaflet/GeoJSON";
import "./Search.css";
import "./uploadForm.css";
import "leaflet/dist/leaflet.css";
import L from "leaflet";

import Modal from "./Modal";
import AudioPlayer from "./AudioPlayer";
import { Link } from "react-router-dom";
import LoadingScreen from "./LoadingScreen";
import pdfLegal from "../INDAUTOR-Taantsil.pdf";

class Search extends React.Component {
  constructor(props) {
    super(props);
    this.accordionContent = [];
    const { t, i18n } = props;
    this.myRef = React.createRef();
    this.state = {
      searchValue: "",
      focusValue: "",
      documents: [],
      showPagination: false,
      currentPage: 1,
      maxPage: 1,
      showDownload: false,
      showConfigMenu: false,
      selectedIndex: "maya",
      showLegend: false,
      queryType: false,
      showModalMap: false,
      lat: 0,
      lng: 0,
      showEmailModal: false,
      downloadUserEmail: "",
      showSearchMap: false,
      latSearchMap: 19.841644,
      lngSearchMap: -88.750305,
      bbox: [
        [21.698265, -91.461182],
        [21.728886, -91.417236],
      ],
      mainBbox: [
        [20.3161, -91.05351],
        [18.6293, -88.93245],
      ],
      toggleGallery: true,
      bboxs: [],
      listCVEGEO: [],
      searchMapBbox: [
        [-91.483154, 18.01008],
        [-85.924072, 21.779905],
      ],
      mapRef: createRef(),
      showTime: false,
      IsLiveAudioPlayer: true,
      select_language: i18n.language,
      i18n: i18n,
      showLoading: false,
      showModalAlertSuccess: false,
      message_success: "",
      hasSearched: false,
      /* variables para el modal del audio */
      showCompleteAudio: false,
      start: null,
      end: null,
      originalAudio: "",
      glosado: [],
      maya: true,
      spanish: false,
    };


    this.fetchDocs = this.fetchDocs.bind(this);
    this.fetchNextPage = this.fetchNextPage.bind(this);
    this.fetchPreviousPage = this.fetchPreviousPage.bind(this);
    this.fetchPage = this.fetchPage.bind(this);

    this.toggleConfigMenu = this.toggleConfigMenu.bind(this);
    this.downloadResult = this.downloadResult.bind(this);
    this.showDownloadModal = this.showDownloadModal.bind(this);
    this.hideDownloadModal = this.hideDownloadModal.bind(this);
    this.alternateGallery = this.alternateGallery.bind(this);
    this.showMarkTimes = this.showMarkTimes.bind(this);
    this.changeLanguage = this.changeLanguage.bind(this);
    this.setAnnotationView = this.setAnnotationView.bind(this);
  }

  changeLanguage = lng => {
    this.state.i18n.changeLanguage(lng);
    this.setState({
      select_language: lng
    });
  };

  handleSearchChange = event => {
    this.setState({
      searchValue: event.target.value
    });  
  };

  formatDate = dateProject => {
    const date = new Date(dateProject);
    const dateOffset = new Date(
      date.valueOf() + date.getTimezoneOffset() * 60 * 1000
    );

    return format(dateOffset, "dd/MM/yyyy");
  };

  fetchDocs() {
    if(this.state.searchValue.trim() == ''){
      return 1
    }

    let payload = {
      text: this.state.searchValue.trim(),
      index: this.state.selectedIndex,
      levenshtein: this.state.queryType,
      id_usuario: localStorage.getItem("uuid")
    };

    this.setState(
      {
        documents: [],
        focusValue: this.state.searchValue.trim(),
        currentPage: 1,
        showConfigMenu: false,
        showLoading: true,
        hasSearched: true
      },
      () => {
        if (!this.state.showSearchMap) {
          fetch("api/search/multiple", {
            method: "POST",
            body: JSON.stringify(payload),
            headers: {
              "Content-Type": "application/json"
            }
          })
            /* .then(response => response.json())
          .then(
            data => {
              console.log(data);
              this.setState({
                documents: data.documents
              });
              let totalHits = data.totalHits;
    
              if (totalHits > 10) {
                let maxPage = Math.ceil(totalHits / 10);
                this.setState({
                  showPagination: true,
                  maxPage: maxPage
                });
              } else {
                this.setState({
                  showPagination: false
                });
              }
            },
            error => {
              console.log(error);
            }
          ); */
            .then(response => {
              if (!response.ok) {
                console.log(response);
                response.json().then(res => {
                  console.log(res);
                });
                this.setState({
                  showLoading: false
                });
                throw new Error("Not 2xx response", { cause: response });
              } else {
                response.json().then(data => {
                  data.documents.forEach(element => {
                    element.showVideo = false;
                    element.originalAudio = false;
                    element.showTime = false;
                  });
                  console.log({ data });
                  let noDupes = data.documents.filter(
                    (arr, index, self) =>
                      index ===
                      self.findIndex(
                        t => t.text === arr.text && t.fileName === arr.fileName
                      )
                  );

                  this.setState({
                    documents: noDupes
                  });
                  let totalHits = data.totalHits;

                  if (totalHits > 10) {
                    let maxPage = Math.ceil(totalHits / 10);
                    this.setState({
                      showPagination: true,
                      maxPage: maxPage,
                      showDownload: totalHits > 0 ? true : false,
                      showLegend: totalHits === 0 ? true : false,
                      showLoading: false
                    });
                  } else {
                    this.setState({
                      showPagination: false,
                      showDownload: totalHits > 0 ? true : false,
                      showLegend: totalHits === 0 ? true : false,
                      showLoading: false
                    });
                  }
                });
              }
            })
            .catch(error => {
              this.setState({
                showLoading: false
              });
              console.log(error.cause);
            });
        }

        fetch("api/search/locations", {
          method: "POST",
          body: JSON.stringify(payload),
          headers: {
            "Content-Type": "application/json"
          }
        })
          .then(response => {
            response.json().then(data => {
              console.log(data);
              let boundingBox = turf.bbox(JSON.parse(data.bbox[0]));
              this.setState({
                bboxs: data.projectPostgresLocations,
                mainBbox: [
                  [boundingBox[1], boundingBox[0]],
                  [boundingBox[3], boundingBox[2]]
                ]
              });

              if (this.state.mapRef.current) {
                this.state.mapRef.current.fitBounds([
                  [boundingBox[1], boundingBox[0]],
                  [boundingBox[3], boundingBox[2]]
                ]);
              }
            });
          })
          .catch(error => {
            console.log(error);
          });
      }
    );
  }

  fetchPreviousPage() {
    if (this.state.currentPage > 1) {
      let newPage = this.state.currentPage - 1;
      this.setState(
        {
          currentPage: newPage
        },
        this.fetchPage
      );
    }
  }

  fetchNextPage() {
    if (this.state.currentPage < this.state.maxPage) {
      let newPage = this.state.currentPage + 1;
      this.setState(
        {
          currentPage: newPage
        },
        this.fetchPage
      );
    }
  }

  fetchPage() {
    this.setState(
      {
        documents: [],
        showLoading: true
      },
      () => {
        let payload = {
          text: this.state.searchValue.trim(),
          page: this.state.currentPage,
          index: this.state.selectedIndex,
          levenshtein: this.state.queryType,
          cvegeo: this.state.listCVEGEO,
          map: this.state.showSearchMap
        };

        fetch("api/search/multiple/page", {
          method: "POST",
          body: JSON.stringify(payload),
          headers: {
            "Content-Type": "application/json"
          }
        })
          .then(response => {
            response.json().then(data => {
              this.setState({
                documents: data,
                showLoading: false
              });
            });
          })
          .catch(error => {
            console.log(error);
            this.setState({
              showLoading: false
            });
          });
      }
    );
  }

  getImageFolder = (ruta, imagen) => {
    let src = ruta.replace("multimedia", "Images") + imagen;
    return src;
  };

  getVideoFolder = (ruta, video) => {
    let src = ruta.replace("multimedia", "Video") + video;
    return src;
  };

  getDownloadLink = (ruta, archivo, extension) => {
    let src = ruta + archivo + "." + extension;
    return src;
  };

  getHighlightedText(text, words) {
    const parts2 = text.split(" ");
    const search_parts = this.state.focusValue
      .toLowerCase()
      .replace(/[^a-zA-Z0-9 ]/g, "")
      .split(" ");
    return (
      <span>
        {parts2.map((part, i) => (
          <span
            key={i}
            style={
              words.some((word) => part.includes(word))
                /* search_parts.includes(
                  part.toLowerCase().replace(/[^a-zA-Z0-9 ]/g, "")
                ) */
                ? { fontWeight: "bold", color: "black", fontStyle: "italic" }
                : {}
            }
          >
            {" "}
            {part}{" "}
          </span>
        ))}{" "}
      </span>
    );
  }

  getHighlightedText2(text) {
    const parts2 = text.split(" ");
    const search_parts = this.state.focusValue
      .toLowerCase()
      .replace(/[^a-zA-Z0-9 ]/g, "")
      .split(" ");
    return (
      <span>
        {" "}
        {parts2.map((part, i) => (
          <span
            key={i}
            style={
              search_parts.includes(
                part.toLowerCase().replace(/[^a-zA-Z0-9 ]/g, "")
              )
                ? { fontWeight: "bold", color: "yellow" }
                : { color: "white" }
            }
          >
            {part}{" "}
          </span>
        ))}{" "}
      </span>
    );
  }

  toggleConfigMenu = evt => {
    this.setState({
      showConfigMenu: !this.state.showConfigMenu
    });
  };

  handleCheckIndex = evt => {
    let selectedValue = evt.target.value;

    this.setState({
      selectedIndex: selectedValue,
      showConfigMenu: !this.state.showConfigMenu
    });
  };

  handleCheckQuery = evt => {
    let selectedValue = evt.target.checked;

    this.setState({
      queryType: selectedValue,
      showConfigMenu: !this.state.showConfigMenu
    });
  };

  showMiniMap = (coordinates, bbox) => {
    let r = bbox.replace("BOX(", "").replace(")", "").split(",");
    let southWest = r[1].split(" ");
    let northEast = r[0].split(" ");
    let partes = JSON.parse("[" + coordinates + "]");
    this.setState(
      {
        lat: partes[0],
        lng: partes[1],
        bbox: [
          [southWest[1], southWest[0]],
          [northEast[1], northEast[0]]
        ]
      },
      () => {
        this.setState({
          showModalMap: true
        });
      }
    );
  };

  hideModalMap = () => {
    this.setState({
      showModalMap: false,
      center: null
    });
  };

  fly = () => {
    const map = useMap();
    map.fitBounds(this.state.bbox);
  };

  algo = () => {
    console.log(this.state.bbox);
  };

  downloadResult() {
    this.setState({
      showLoading: true,
      showEmailModal: false
    });

    let payload = {
      text: this.state.searchValue,
      index: this.state.selectedIndex,
      email: this.state.downloadUserEmail,
      cvegeo: this.state.listCVEGEO,
      map: this.state.showSearchMap
    };

    fetch("api/download", {
      method: "POST",
      body: JSON.stringify(payload),
      headers: {
        "Content-Type": "application/json"
      }
    })
      .then(response => {
        if (response.ok) {
          this.setState({
            showLoading: false,
            showModalAlertSuccess: true,
            message_success:
              "Recibirá el correo electrónico con la descarga en breve"
          });

          this.hideDownloadModal();
        }
      })
      .catch(error => {
        console.log(error);
      });
  }

  showDownloadModal() {
    this.setState({
      showEmailModal: true
    });
  }

  hideDownloadModal() {
    this.setState({
      showEmailModal: false
    });
  }

  updateDownloadUserEmail = e => {
    this.setState({
      downloadUserEmail: e.target.value
    });
  };

  showMap = e => {
    this.setState(
      {
        showSearchMap: !this.state.showSearchMap,
        listCVEGEO: [],
        currentPage: 1
      },
      () => {
        this.fetchPage();
      }
    );
  };

  alternateGallery = (status, index) => () => {
    let documents = [...this.state.documents];
    documents[index].showVideo = status;
    this.setState({
      documents: documents
    });
  };

  changeAudio = index => {
    let documents = [...this.state.documents];
    documents[index].originalAudio = !documents[index].originalAudio;
    this.setState(
      {
        documents: documents
      },
      () => {
        console.log(documents);
      }
    );
  };

  renderLocation(box, index) {
    let r = box.bbox.replace("BOX(", "").replace(")", "").split(",");
    let southWest = r[1].split(" ");
    let northEast = r[0].split(" ");

    let bbox = [
      [southWest[1], southWest[0]],
      [northEast[1], northEast[0]]
    ];

    let geom = JSON.parse(box.geometria);

    let centerGeom = turf.centerOfMass(geom).geometry.coordinates;

    return (
      <>
        <Marker
          position={[centerGeom[1], centerGeom[0]]}
          icon={this.generateIcon(box.coincidencias)}
          key={`marker${index}`}
        ></Marker>
        <GeoJSON data={geom} key={`location${index}`} />
      </>
    );
  }

  FilterZoom = () => {
    const map = useMap();
    useMapEvents({
      // click: () => {
      //   this.getListCVEGEO(map);
      // },
      zoomend: () => {
        this.getListCVEGEO(map);
      },
      moveend: () => {
        this.getListCVEGEO(map);
      }
    });
  };

  getListCVEGEO(map) {
    let bbox = map.getBounds();
    var region = turf.bboxPolygon([
      bbox._southWest.lng,
      bbox._southWest.lat,
      bbox._northEast.lng,
      bbox._northEast.lat
    ]);
    let list_cvegeo = [];

    this.state.bboxs.map(function (layer) {
      var polygon = undefined;
      let geom = JSON.parse(layer.geometria);
      if (geom.type == "MultiPolygon") {
        polygon = turf.multiPolygon(geom.coordinates);
      } else {
        polygon = turf.polygon(geom.coordinates);
      }

      var intersection = turf.intersect(region, polygon);
      if (intersection != null) {
        list_cvegeo.push(layer.localidad_cvegeo);
      }
    });

    this.setState(
      {
        listCVEGEO: list_cvegeo
      },
      () => {
        this.fetchPage();
      }
    );
  }

  generateIcon = number => {
    return new L.DivIcon({
      className: "marker-div-icon",
      html: `<span class="text-2xl text-white bg-black/70 p-3 rounded-full">${number}</span>`
    });
  };

  addCvegeos = () => {
    let cvegeos = [];
    this.state.bboxs.forEach(bbox => {
      cvegeos.push(bbox.localidad_cvegeo);
    });

    this.setState({
      listCVEGEO: cvegeos
    });
  };

  calculateTime = secs => {
    let hours = Math.floor(secs / 3600);
    let minutes = Math.floor((secs % 3600) / 60);
    let remainingSeconds = Math.ceil(secs % 60);

    let formattedHours = hours.toString().padStart(1, '0');
    let formattedMinutes = minutes.toString().padStart(2, '0');
    let formattedSeconds = remainingSeconds.toString().padStart(2, '0');

    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  };


  componentWillUnmount() {
    window.onbeforeunload = null;
  }

  hideAlertSuccessModal = () => {
    this.setState({
      showModalAlertSuccess: false,
      downloadUserEmail: ""
    });
  };

  handleKeyDown = event => {
    if (event.key === "Enter") {
      this.fetchDocs();
    }
  };

  handleScroll = (event) => {
    clearInterval(this.interval);
    //console.log(event)
  };


  showMarkTimes = () => (index, id_proyecto, original, start, end) => {
    console.log('hhhhh', index, id_proyecto, original, start, end)
    this.fetchProject(id_proyecto);
    let documents = [...this.state.documents];
    documents[index].showTime = !documents[index].showTime;
    this.setState({
      documents: documents,
      originalAudio: original,
      start: start,
      end: end
    }, () => {
      this.setState({ showCompleteAudio: true });

    });

  };

  handleTimeJump = (timeJump, index) => () => {
    console.log(timeJump)
    this.myRef.current.changeTime(timeJump);
    this.clearBorders();
    this.scrollToSelected(index);
  };

  getCurrentTime = () => () => {
    console.log(this.state.glosado)
    if (this.state.showCompleteAudio) {
      this.interval = setInterval(() => {
        if (this.accordionContent.length > 0 && this.state.showCompleteAudio) {
          let min_index = -1;
          let min_index_exist = false;
          let max_index = -1;
          let currentTime = this.myRef.current.getCurrentTime();

          this.clearBorders()
          for (let index = 0; index < this.state.glosado.length; index++) {
            const time1 = this.state.glosado[index].TIME_VALUE1 / 1000;
            const time2 = this.state.glosado[index].TIME_VALUE2 / 1000;
            if (currentTime >= time1 && currentTime < time2) {
              console.log(index, currentTime, time1, time2)

              if (!min_index_exist) {
                min_index_exist = true;
                min_index = index;
              }

              if (index > max_index) {
                max_index = index;
              }

              this.scrollToSelected(index)
            }
          }

          if (min_index == max_index) {
            this.oneScrollToSelected(min_index);
          } else {
            this.startScrollToSelected(min_index);
            this.endScrollToSelected(max_index);
          }
        }
      }, 100);
    }
  };

  getCurrentTimeInit = (timer) => {

    if (this.accordionContent.length > 0 && this.state.showCompleteAudio) {
      let min_index = -1;
      let min_index_exist = false;
      let max_index = -1;

      console.log(this.state.glosado);
      let currentTime = timer;
      //this.clearBorders();
      for (let index = 0; index < this.state.glosado.length; index++) {
        const time1 = this.state.glosado[index].TIME_VALUE1 / 1000;
        const time2 = this.state.glosado[index].TIME_VALUE2 / 1000;
        if (currentTime >= time1 && currentTime < time2) {
          console.log(index, currentTime, time1, time2, this.state.glosado[19]);
          if (!min_index_exist) {
            min_index_exist = true;
            min_index = index;
          }

          if (index > max_index) {
            max_index = index;
          }

          this.scrollToSelected(index);
        }
      }

      if (min_index == max_index) {
        this.oneScrollToSelected(min_index);
      } else {
        this.startScrollToSelected(min_index);
        this.endScrollToSelected(max_index);
      }
    }
  };

  hideShowOriginalAudio = () => {
    this.setState({
      showCompleteAudio: false
    });
    this.accordionContent = [];
    clearInterval(this.interval);

  };

  pauseCurrentTime = () => () => {
    if (this.state.showCompleteAudio) {
      clearInterval(this.interval);
    }
  }


  fetchProject = projectId => {
    let payload = {
      project: projectId
    };

    fetch("../../../api/project/register", {
      method: "POST",
      body: JSON.stringify(payload),
      headers: {
        "Content-Type": "application/json"
      }
    })
      .then(response => {
        response.json().then(data => {
          console.log(data.ruta_trabajo)
          this.fetchGlosa(data)
        });
      })
      .catch(error => {
        console.log(error);
      });
  };


  fetchGlosa = (project) => {
    let path = project.ruta_trabajo.slice(2);

    path += `${project.nombre_proyecto}.eaf`;

    let payload = {
      project: project.id_proyecto,
      filePath: path
    };

    fetch("../../../api/annotation/multiple_registers", {
      method: "POST",
      body: JSON.stringify(payload),
      headers: {
        "Content-Type": "application/json"
      }
    })
      .then(response => {
        response.json().then(data => {
          console.log("asdad", data)

          let sortedGlosado = data.sort((p1, p2) =>
            parseInt(p1.TIME_VALUE1) > parseInt(p2.TIME_VALUE1) ? 1 : parseInt(p1.TIME_VALUE1) < parseInt(p2.TIME_VALUE1) ? -1 : 0
          );

          this.setState({
            glosado: sortedGlosado,
          });
        });
      })
      .catch(error => {
        console.log(error);
      });
  };

  clearBorders = () => {
    console.log("this.accordionContent", this.accordionContent);
    if (this.accordionContent.length > 0) {
      for (let i = 0; i < this.accordionContent.length; i++) {
        if (this.state.glosado[i].annotator_NAME == this.state.glosado[i].participant_NAME) {
          this.accordionContent[i].className = "w-auto flex self-start bg-white px-4 py-4 mb-2 shadow-lg rounded-md border-4 border-cdmx-gray";
        } else {
          this.accordionContent[i].className = "w-auto flex self-end bg-white px-4 py-4 mb-2 shadow-lg rounded-md border-4 border-cdmx-gray";
        }
      }
    }
  }

  scrollToSelected = (index) => {
    if (this.accordionContent[index] && this.state.showCompleteAudio) {
      //this.accordionContent[index].scrollIntoView({ behavior: "smooth", block: "center", })
      if (this.state.glosado[index].annotator_NAME == this.state.glosado[index].participant_NAME) {
        this.accordionContent[index].className = "w-auto flex self-start bg-white px-4 py-4 mb-2 shadow-lg rounded-md border-4 border-cdmx-blue-light"
      } else {
        this.accordionContent[index].className = "w-auto flex self-end bg-white px-4 py-4 mb-2 shadow-lg rounded-md border-4 border-cdmx-green"
      }

    }
  }

  oneScrollToSelected = (index) => {
    if (this.accordionContent[index] && this.state.showCompleteAudio) {
      this.accordionContent[index].scrollIntoView({ behavior: "smooth", block: "center", })
      if (this.state.glosado[index].annotator_NAME == this.state.glosado[index].participant_NAME) {
        this.accordionContent[index].className = "w-auto flex self-start bg-white px-4 py-4 mb-2 shadow-lg rounded-md border-4 border-cdmx-blue-light"
      } else {
        this.accordionContent[index].className = "w-auto flex self-end bg-white px-4 py-4 mb-2 shadow-lg rounded-md border-4 border-cdmx-green"
      }
    }
  }

  startScrollToSelected = (index) => {
    if (this.accordionContent[index] && this.state.showCompleteAudio) {
      //this.accordionContent[index].scrollIntoView({ behavior: "smooth", block: "center", })
      if (this.state.glosado[index].annotator_NAME == this.state.glosado[index].participant_NAME) {
        this.accordionContent[index].className = "w-auto flex self-start bg-white px-4 py-4 mb-2 shadow-lg rounded-md border-4 border-cdmx-blue-light"
      } else {
        this.accordionContent[index].className = "w-auto flex self-end bg-white px-4 py-4 mb-2 shadow-lg rounded-md border-4 border-cdmx-green"
      }
    }
  }

  endScrollToSelected = (index) => {
    if (this.accordionContent[index] && this.state.showCompleteAudio) {
      this.accordionContent[index].scrollIntoView({ behavior: "smooth", block: "center", })
      if (this.state.glosado[index].annotator_NAME == this.state.glosado[index].participant_NAME) {
        this.accordionContent[index].className = "w-auto flex self-start bg-white px-4 py-4 mb-2 shadow-lg rounded-md border-4 border-cdmx-blue-light"
      } else {
        this.accordionContent[index].className = "w-auto flex self-end bg-white px-4 py-4 mb-2 shadow-lg rounded-md border-4 border-cdmx-green"
      }
    }
  }

  setAnnotationView = () => (type) => {
    if(type === "maya"){
      this.setState({
        maya: !this.state.maya
      })
    }else{
      this.setState({
        spanish: !this.state.spanish
      })
    }

  };

  render() {
    const { t } = this.props;

    return (
      <div
        className={`relative w-full px-4 py-3 h-full bg-cdmx-gray flex flex-col ${this.props.isContrast ? "invert" : "invert-0"
          }`}
      >
        {this.state.showLoading ? (
          <LoadingScreen
            title={this.state.message_loading}
            message="Esto tomará unos segundos, por favor no cierre esta página."
          />
        ) : null}

        {/* Selector de idioma */}
        <div className="absolute top-8 left-5 lg:left-10 z-[100]">
          <div
            className={`flex item-center flex-wrap justify-center ${this.props.isFontBig
              ? "text-base lg:text-lg"
              : "text-sm lg:text-base"
              }`}
          >
            <div>
              <button
                className="px-2 text-black hover:text-gray-800  hover:border-gray-100"
                type="button"
                onClick={() => this.changeLanguage("en")}
              >
                <span
                  className={`${this.state.select_language === "en" ? "underline" : ""
                    }`}
                >
                  en
                </span>
              </button>
            </div>
            <div className="underline">
              <button
                className="px-2 text-black hover:text-gray-800  hover:border-gray-100"
                type="button"
                onClick={() => this.changeLanguage("es")}
              >
                <span
                  className={`${this.state.select_language === "es" ? "underline" : ""
                    }`}
                >
                  es
                </span>
              </button>
            </div>
            <div className="underline">
              <button
                className="px-2 text-black hover:text-gray-800  hover:border-gray-100"
                type="button"
                onClick={() => this.changeLanguage("maya")}
              >
                <span
                  className={`${this.state.select_language === "maya" ? "underline" : ""
                    }`}
                >
                  maya
                </span>
              </button>
            </div>
          </div>
        </div>

        {/* Encabezado */}
        <div className="flex flex-col">
          <div className="flex flex-col items-center justify-center w-full">
            <img
              src="blanco gris BCBEC0.png"
              className={`h-16 self-end pr-4 ${this.state.hasSearched
                ? "lg:h-24 lg:pr-8 "
                : "lg:h-64 lg:self-center"
                }`}
              alt=""
            />
            <div className="w-full flex items-center justify-center py-2">
              <span
                className={`flex capitalize w-20 lg:w-24 items-center rounded-l ${this.props.isFontBig
                  ? "text-sm lg:text-base"
                  : "text-xs lg:text-sm"
                  } text-zinc-500 bg-zinc-300 font-semibold border-cdmx-green px-3 py-2 border-b-2 border-t-2 border-l-2`}
              >
                <span className="text-center w-full">
                  {this.state.selectedIndex == "maya"
                    ? t("buscador.titulos.maya", "Maya")
                    : ""}
                  {this.state.selectedIndex == "español"
                    ? t("buscador.titulos.español", "Español")
                    : ""}
                  {/* {this.state.selectedIndex == "glosado"
                    ? t("buscador.titulos.glosado", "Glosado")
                    : ""} */}
                  {this.state.selectedIndex == "soundex"
                    ? t("buscador.titulos.soundex", "Soundex")
                    : ""}
                  {this.state.selectedIndex == "soundex_boolean"
                    ? t("buscador.titulos.soundex_boolean", "Soundex-B")
                    : ""}
                </span>
              </span>
              <input
                className={`w-1/3 outline-0 bg-white border-cdmx-green border-b-2 border-t-2 border-r-2 px-2 ${this.props.isFontBig ? "py-2 lg:py-2.5" : "py-1.5 lg:py-2"
                  } leading-tight`}
                type="text"
                name="search"
                id="search"
                value={this.state.searchValue}
                onChange={this.handleSearchChange}
                onKeyDown={this.handleKeyDown}
              ></input>
              <button
                className={`px-4 py-2 bg-cdmx-green text-white font-bold ${this.props.isFontBig
                  ? "text-base lg:text-lg"
                  : "text-sm lg:text-base"
                  }`}
                type="button"
                onClick={this.fetchDocs}
              >
                {t("buscador.acciones.buscar", "Buscar")}
              </button>
              <div className="relative">
                <button
                  className={`bg-infokab-gray px-4 ${this.props.isFontBig ? "py-2 lg:py-2.5" : "py-1.5 lg:py-2"
                    } text-white rounded-r`}
                  onClick={this.toggleConfigMenu}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6 fill-current"
                  >
                    <path d="m9.25 22-.4-3.2q-.325-.125-.612-.3-.288-.175-.563-.375L4.7 19.375l-2.75-4.75 2.575-1.95Q4.5 12.5 4.5 12.337v-.675q0-.162.025-.337L1.95 9.375l2.75-4.75 2.975 1.25q.275-.2.575-.375.3-.175.6-.3l.4-3.2h5.5l.4 3.2q.325.125.613.3.287.175.562.375l2.975-1.25 2.75 4.75-2.575 1.95q.025.175.025.337v.675q0 .163-.05.338l2.575 1.95-2.75 4.75-2.95-1.25q-.275.2-.575.375-.3.175-.6.3l-.4 3.2Zm2.8-6.5q1.45 0 2.475-1.025Q15.55 13.45 15.55 12q0-1.45-1.025-2.475Q13.5 8.5 12.05 8.5q-1.475 0-2.488 1.025Q8.55 10.55 8.55 12q0 1.45 1.012 2.475Q10.575 15.5 12.05 15.5Zm0-2q-.625 0-1.062-.438-.438-.437-.438-1.062t.438-1.062q.437-.438 1.062-.438t1.063.438q.437.437.437 1.062t-.437 1.062q-.438.438-1.063.438ZM12 12Zm-1 8h1.975l.35-2.65q.775-.2 1.438-.588.662-.387 1.212-.937l2.475 1.025.975-1.7-2.15-1.625q.125-.35.175-.738.05-.387.05-.787t-.05-.788q-.05-.387-.175-.737l2.15-1.625-.975-1.7-2.475 1.05q-.55-.575-1.212-.963-.663-.387-1.438-.587L13 4h-1.975l-.35 2.65q-.775.2-1.437.587-.663.388-1.213.938L5.55 7.15l-.975 1.7 2.15 1.6q-.125.375-.175.75-.05.375-.05.8 0 .4.05.775t.175.75l-2.15 1.625.975 1.7 2.475-1.05q.55.575 1.213.962.662.388 1.437.588Z" />
                  </svg>
                </button>

                {/* Búsqueda avanzada */}
                <div
                  className={`${this.state.showConfigMenu ? "flex" : "hidden"
                    } flex-col absolute lg:top-0 right-0 lg:left-14 bg-white rounded px-4 py-2 z-50 divide-y divide-gray-100 shadow w-32`}
                >
                  <div className="flex flex-col">
                    <span className="text-base lg:text-lg font-semibold border-b-2 mb-2">
                      {t("buscador.titulos.indices", "indices")}
                    </span>
                    <ul>
                      <li>
                        <div className="flex items-center mb-2">
                          <input
                            id="indexMaya"
                            type="radio"
                            value="maya"
                            name="index"
                            checked={this.state.selectedIndex === "maya"}
                            onChange={this.handleCheckIndex}
                            className="w-3 h-3 lg:w-4 lg:h-4 text-blue-600 bg-gray-100 border-gray-300"
                          />
                          <label
                            htmlFor="indexMaya"
                            className="ml-2 text-xs lg:text-sm font-medium text-gray-900"
                          >
                            {t("buscador.titulos.maya", "Maya")}
                          </label>
                        </div>
                      </li>
                      <li>
                        <div className="flex items-center mb-2">
                          <input
                            id="indexEspañol"
                            type="radio"
                            value="español"
                            name="index"
                            checked={this.state.selectedIndex === "español"}
                            onChange={this.handleCheckIndex}
                            className="w-3 h-3 lg:w-4 lg:h-4 text-blue-600 bg-gray-100 border-gray-300"
                          />
                          <label
                            htmlFor="indexEspañol"
                            className="ml-2 text-xs lg:text-sm font-medium text-gray-900"
                          >
                            {t("buscador.titulos.español", "Español")}
                          </label>
                        </div>
                      </li>
                      {/* <li>
                        <div className="flex items-center mb-2">
                          <input
                            id="indexGlosado"
                            type="radio"
                            value="glosado"
                            name="index"
                            checked={this.state.selectedIndex === "glosado"}
                            onChange={this.handleCheckIndex}
                            className="w-3 h-3 lg:w-4 lg:h-4 text-blue-600 bg-gray-100 border-gray-300"
                          />
                          <label
                            htmlFor="indexGlosado"
                            className="ml-2 text-xs lg:text-sm font-medium text-gray-900"
                          >
                            {t("buscador.titulos.glosado", "Glosado")}
                          </label>
                        </div>
                      </li> */}
                      <li>
                        <div className="flex items-center mb-2">
                          <input
                            id="indexSoundex"
                            type="radio"
                            value="soundex"
                            name="index"
                            checked={this.state.selectedIndex === "soundex"}
                            onChange={this.handleCheckIndex}
                            className="w-3 h-3 lg:w-4 lg:h-4 text-blue-600 bg-gray-100 border-gray-300"
                          />
                          <label
                            htmlFor="indexSoundex"
                            className="ml-2 text-xs lg:text-sm font-medium text-gray-900"
                          >
                            {t("buscador.titulos.soundex", "soundex")}
                          </label>
                        </div>
                      </li>

                      <li>
                        <div className="flex items-center mb-2">
                          <input
                            id="indexSoundexBoolean"
                            type="radio"
                            value="soundex_boolean"
                            name="index"
                            checked={this.state.selectedIndex === "soundex_boolean"}
                            onChange={this.handleCheckIndex}
                            className="w-3 h-3 lg:w-4 lg:h-4 text-blue-600 bg-gray-100 border-gray-300"
                          />
                          <label
                            htmlFor="indexSoundexBoolean"
                            className="ml-2 text-xs lg:text-sm font-medium text-gray-900"
                          >
                            {t("buscador.titulos.soundex_boolean", "soundex-B")}
                          </label>
                        </div>
                      </li>
                    </ul>
                  </div>

                  {/* <div className="flex flex-col">
                    <span className="text-lg font-semibold border-b-2 mb-2">
                      Tipo:
                    </span>
                    <ul>
                      <li>
                        <div className="flex items-center mb-2">
                          <input
                            id="queryType"
                            type="checkbox"
                            value="Levenshtein"
                            name="queryType"
                            checked={this.state.queryType}
                            onChange={this.handleCheckQuery}
                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300"
                          />
                          <label
                            htmlFor="queryType"
                            className="ml-2 text-sm font-medium text-gray-900"
                          >
                            Levenshtein
                          </label>
                        </div>
                      </li>
                    </ul>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col flex-grow overflow-auto">
          <div
            className={`flex w-full items-center justify-center py-3 relative`}
          >
            <div
              className={`${this.state.showDownload
                ? "flex w-full justify-between"
                : "hidden"
                } pb-0`}
            >
              <button
                className={`px-2 lg:px-4 py-1 lg:py-2 font-bold rounded bg-infokab-gray text-white mx-2 ${this.props.isFontBig ? "text-base lg:text-lg" : "text-sm lg:text-base"
                  }`}
                onClick={this.showMap}
              >
                {this.state.showSearchMap
                  ? t("buscador.acciones.ocultar_mapa", "Ocultar Mapa")
                  : t("buscador.acciones.mostrar_mapa", "Mostrar Mapa")}
              </button>
              <button
                className={`px-2 lg:px-4 py-1 lg:py-2 font-bold rounded bg-infokab-gray text-white mx-2 ${this.props.isFontBig ? "text-base lg:text-lg" : "text-sm lg:text-base"
                  }`}
                onClick={this.showDownloadModal}
              >
                {t(
                  "buscador.acciones.descargar_resultados",
                  "Descargar resultados"
                )}
              </button>
            </div>
          </div>

          <div className="flex w-full h-full flex-col lg:flex-row overflow-auto">
            {this.state.showSearchMap && (
              <div className="w-full lg:w-2/5 h-72 lg:h-full mb-2 lg:mb-0 mainMap">
                <MapContainer
                  className="h-72 lg:h-full"
                  center={[this.state.latSearchMap, this.state.lngSearchMap]}
                  bounds={this.state.mainBbox}
                  whenReady={this.addCvegeos}
                  ref={this.state.mapRef}
                //zoom={11}
                >
                  <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  />
                  {this.state.bboxs.map((box, index) => {
                    return this.renderLocation(box, index);
                  })}

                  <this.FilterZoom></this.FilterZoom>
                </MapContainer>
              </div>
            )}
            <div
              className={`${this.state.showSearchMap ? "w-full lg:w-3/5" : "w-full"
                } overflow-auto`}
            >
              <div
                className={`${this.state.showLegend && this.state.hasSearched ? "flex" : "hidden"
                  } w-full items-center justify-center ${this.props.isFontBig ? "text-lg" : "text-base"
                  }`}
              >
                {t(
                  "buscador.mensajes.no_se_encontraron_coincidencias",
                  "No se encontraron coincidencias."
                )}
              </div>

              {this.state.documents.map((document, index) => {
                return (
                  <div
                    className="w-full px-2 mb-4 break-all overflow-hidden"
                    key={index}
                  >
                    <div className="w-full md:flex bg-white  p-2 rounded-lg border-4 hover:border-cdmx-green">
                      {document.originalPath.includes(".wav") ? (
                        <div className="md:w-1/4 xs:w-full bg-black ">
                          <div className="w-full dark:border-gray-700 ">
                            <ul className="w-full flex flex-wrap -mb-px text-xs font-normal text-center text-gray-600">
                              <li className="mr-2">
                                <a
                                  href="#"
                                  onClick={this.alternateGallery(false, index)}
                                  className={`inline-flex p-2 ${document.showVideo
                                    ? ""
                                    : "border-b-2 text-white"
                                    } rounded-t-lg hover:text-white hover:border-gray-300 border-gray-300 dark:hover:text-white group`}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth="1.5"
                                    stroke="currentColor"
                                    className="w-4 h-4 mr-1"
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      d="M2.25 15.75l5.159-5.159a2.25 2.25 0 013.182 0l5.159 5.159m-1.5-1.5l1.409-1.409a2.25 2.25 0 013.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 001.5-1.5V6a1.5 1.5 0 00-1.5-1.5H3.75A1.5 1.5 0 002.25 6v12a1.5 1.5 0 001.5 1.5zm10.5-11.25h.008v.008h-.008V8.25zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z"
                                    />
                                  </svg>
                                  {t("buscador.titulos.imagenes", "Imágenes")}
                                </a>
                              </li>
                              <li className="mr-2">
                                <a
                                  href="#"
                                  onClick={this.alternateGallery(true, index)}
                                  className={`inline-flex p-2 ${document.showVideo
                                    ? "border-b-2 text-white"
                                    : ""
                                    } rounded-t-lg hover:text-white hover:border-gray-300 border-gray-300 dark:hover:text-white group`}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth="1.5"
                                    stroke="currentColor"
                                    className="w-4 h-4 mr-1"
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      d="M3.375 19.5h17.25m-17.25 0a1.125 1.125 0 01-1.125-1.125M3.375 19.5h1.5C5.496 19.5 6 18.996 6 18.375m-3.75 0V5.625m0 12.75v-1.5c0-.621.504-1.125 1.125-1.125m18.375 2.625V5.625m0 12.75c0 .621-.504 1.125-1.125 1.125m1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125m0 3.75h-1.5A1.125 1.125 0 0118 18.375M20.625 4.5H3.375m17.25 0c.621 0 1.125.504 1.125 1.125M20.625 4.5h-1.5C18.504 4.5 18 5.004 18 5.625m3.75 0v1.5c0 .621-.504 1.125-1.125 1.125M3.375 4.5c-.621 0-1.125.504-1.125 1.125M3.375 4.5h1.5C5.496 4.5 6 5.004 6 5.625m-3.75 0v1.5c0 .621.504 1.125 1.125 1.125m0 0h1.5m-1.5 0c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125m1.5-3.75C5.496 8.25 6 7.746 6 7.125v-1.5M4.875 8.25C5.496 8.25 6 8.754 6 9.375v1.5m0-5.25v5.25m0-5.25C6 5.004 6.504 4.5 7.125 4.5h9.75c.621 0 1.125.504 1.125 1.125m1.125 2.625h1.5m-1.5 0A1.125 1.125 0 0118 7.125v-1.5m1.125 2.625c-.621 0-1.125.504-1.125 1.125v1.5m2.625-2.625c.621 0 1.125.504 1.125 1.125v1.5c0 .621-.504 1.125-1.125 1.125M18 5.625v5.25M7.125 12h9.75m-9.75 0A1.125 1.125 0 016 10.875M7.125 12C6.504 12 6 12.504 6 13.125m0-2.25C6 11.496 5.496 12 4.875 12M18 10.875c0 .621-.504 1.125-1.125 1.125M18 10.875c0 .621.504 1.125 1.125 1.125m-2.25 0c.621 0 1.125.504 1.125 1.125m-12 5.25v-5.25m0 5.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125m-12 0v-1.5c0-.621-.504-1.125-1.125-1.125M18 18.375v-5.25m0 5.25v-1.5c0-.621.504-1.125 1.125-1.125M18 13.125v1.5c0 .621.504 1.125 1.125 1.125M18 13.125c0-.621.504-1.125 1.125-1.125M6 13.125v1.5c0 .621-.504 1.125-1.125 1.125M6 13.125C6 12.504 5.496 12 4.875 12m-1.5 0h1.5m-1.5 0c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125M19.125 12h1.5m0 0c.621 0 1.125.504 1.125 1.125v1.5c0 .621-.504 1.125-1.125 1.125m-17.25 0h1.5m14.25 0h1.5"
                                    />
                                  </svg>
                                  {t("buscador.titulos.video", "Video")}
                                </a>
                              </li>
                            </ul>
                          </div>
                          <div
                            className={`md:shrink-0 p-1 ${document.showVideo ? " hidden" : ""
                              }`}
                          >
                            <Swiper
                              slidesPerView={1}
                              modules={[Autoplay, Lazy]}
                              autoplay={{
                                delay: 2500,
                                disableOnInteraction: false
                              }}
                              lazy={{ loadPrevNext: true }}
                              preloadImages={false}
                              className="md:w-full xs:w-full h-36 lg:h-72 bg-black items-center rounded-lg "
                            >
                              {document.imageList != null ? (
                                document.imageList.map((element, index) => (
                                  <SwiperSlide key={index}>
                                    <img
                                      key={index}
                                      alt={"imagen de proyecto"}
                                      className="swiper-lazy h-full w-full object-contain md:h-full md:w-full  bg-black"
                                      src={`../api/multimedia${this.getImageFolder(
                                        document.basePath,
                                        element
                                      )}`}
                                      loading="lazy"
                                    />
                                    <div className="swiper-lazy-preloader"></div>
                                  </SwiperSlide>
                                ))
                              ) : (
                                <SwiperSlide
                                  key={index}
                                  className="flex items-center"
                                >
                                  <span className="font-medium tracking-wide p-2  text-center w-full text-white items-center">
                                    {t(
                                      "buscador.mensajes.sin_imagenes_agregadas",
                                      "SIN IMÁGENES AGREGADAS"
                                    )}
                                  </span>
                                </SwiperSlide>
                              )}
                            </Swiper>
                          </div>

                          <div
                            className={`md:shrink-0 p-1 ${document.showVideo ? "" : " hidden"
                              }`}
                          >
                            <Swiper
                              slidesPerView={1}
                              modules={[Navigation, Lazy]}
                              navigation={true}
                              className="md:w-full xs:w-full h-72  bg-black items-center rounded-lg"
                            >
                              {document.videoList != null ? (
                                document.videoList.map((element, index) => (
                                  <SwiperSlide key={index}>
                                    <video
                                      className=" h-full w-full object-contain md:h-full md:w-full  bg-black"
                                      controls
                                      muted
                                    >
                                      <source
                                        src={`../api/multimedia${this.getVideoFolder(
                                          document.basePath,
                                          element
                                        )}`}
                                        type="video/mp4"
                                      />
                                    </video>
                                  </SwiperSlide>
                                ))
                              ) : (
                                <SwiperSlide
                                  key={index}
                                  className="flex items-center"
                                >
                                  <span className="font-medium tracking-wide p-2  text-center w-full text-white items-center">
                                    {t(
                                      "buscador.mensajes.sin_videos_agregados",
                                      "SIN VIDEOS AGREGADOS"
                                    )}
                                  </span>
                                </SwiperSlide>
                              )}
                            </Swiper>
                          </div>
                        </div>
                      ) : null}

                      {document.originalPath.includes(".mp4") ? (
                        <div className="md:w-1/4 xs:w-full bg-black ">
                          <video
                            className="object-contain h-full w-full  p-1 h-full"
                            controls
                          >
                            <source
                              src={`api/streaming/videoV2${document.basePath}${document.multimediaName}.${document.typePath}`}
                              type="video/mp4"
                            />
                          </video>
                        </div>
                      ) : null}

                      <div className="md:w-3/4 xs:w-full flex flex-col justify-center w-full lg:pl-4 bg-ondas bg-contain bg-center bg-repeat-x">
                        <div
                          className={`flex w-full items-center justify-end ${this.props.isFontBig ? " text-lg" : "text-base"
                            }`}
                        >
                          <span>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth="1.5"
                              stroke="currentColor"
                              className="w-4 lg:w-6 h-4 lg:h-6"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z"
                              />
                            </svg>
                          </span>
                          <span className="ml-2 text-xs lg:text-base">
                            {this.formatDate(document.fecha_archivo)}
                          </span>
                        </div>
                        <div className="flex flex-col flex-grow justify-center">
                          <div className="flex w-full items-center flex-grow">
                            <span
                              className={`font-medium tracking-wide pb-2 ${this.props.isFontBig ? "text-lg lg:text-2xl" : "text-base lg:text-xl"
                                } text-center w-full h-full text-zinc-600 items-center break-normal`}
                            >
                              <sup
                                className={`font-xs tracking-wide pb-2 ${this.props.isFontBig ? "text-sm" : "text-xs"
                                  } text-center w-full h-full text-red-600 items-center break-normal`}
                              >
                                {this.calculateTime(document.startTime)}
                              </sup>
                              {this.getHighlightedText(document.text, document.highlight_words)}
                              <sup
                                className={`font-xs tracking-wide pb-2 ${this.props.isFontBig ? "text-sm" : "text-xs"
                                  } text-center w-full h-full text-red-600 items-center break-normal`}
                              >
                                {this.calculateTime(document.endTime)}
                              </sup>
                            </span>
                          </div>
                          {document.subText !== "" ? (
                            <div className="flex w-full justify-between items-center ">
                              <span className="font-normal tracking-wide p-2 italic text-center w-full bg-cdmx-gray  items-center break-normal">
                                <span
                                  className={`text-white ${this.props.isFontBig
                                    ? "text-base lg:text-lg"
                                    : "text-sm lg:text-base"
                                    }`}
                                >
                                  {this.getHighlightedText2(document.subText)}
                                </span>
                              </span>
                            </div>
                          ) : null}
                          <div className="flex items-start py-4">
                            <div className="flex flex-row h-1/2 w-1/3 justify-center">
                              <div className="justify-center">
                                <span className="">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-6 w-6 mx-auto"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth="1.5"
                                    stroke="currentColor"
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      d="M2.25 18L9 11.25l4.306 4.307a11.95 11.95 0 015.814-5.519l2.74-1.22m0 0l-5.94-2.28m5.94 2.28l-2.28 5.941"
                                    />
                                  </svg>
                                </span>
                                <span
                                  className={`block ${this.props.isFontBig
                                    ? " text-sm"
                                    : "text-xs"
                                    } break-normal`}
                                >
                                  {t("buscador.titulos.puntaje", "Puntaje")}:{" "}
                                  {document.score}
                                </span>
                              </div>
                            </div>
                            <div className="flex flex-col justify-center items-center w-1/3">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-5 lg:h-6 w-5 lg:w-6 mx-auto"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth="1.5"
                                stroke="currentColor"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M18 18.72a9.094 9.094 0 003.741-.479 3 3 0 00-4.682-2.72m.94 3.198l.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0112 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 016 18.719m12 0a5.971 5.971 0 00-.941-3.197m0 0A5.995 5.995 0 0012 12.75a5.995 5.995 0 00-5.058 2.772m0 0a3 3 0 00-4.681 2.72 8.986 8.986 0 003.74.477m.94-3.197a5.971 5.971 0 00-.94 3.197M15 6.75a3 3 0 11-6 0 3 3 0 016 0zm6 3a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0zm-13.5 0a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0z"
                                />
                              </svg>
                              <span
                                className={`block ${this.props.isFontBig
                                  ? "text-sm"
                                  : "text-xs"
                                  } break-normal`}
                              >
                                {JSON.parse(document.nhablantes).length}{" "}
                                {t("buscador.titulos.hablantes", "Hablantes")}
                              </span>
                            </div>
                            <div className="flex items-center justify-center w-1/3">
                              <button
                                onClick={() =>
                                  this.showMiniMap(
                                    document.coordinates,
                                    document.bbox
                                  )
                                }
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="h-5 lg:h-6 w-5 lg:w-6 mx-auto"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  strokeWidth="1.5"
                                  stroke="currentColor"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z"
                                  />
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z"
                                  />
                                </svg>
                                <span
                                  className={`block text-center items-center ${this.props.isFontBig
                                    ? "text-sm"
                                    : "text-xs"
                                    } break-normal`}
                                >
                                  {document.localidad}, {document.municipio}
                                  <br />
                                  {document.entidad}
                                </span>
                              </button>
                            </div>
                            <div className="flex flex-col justify-center items-center w-1/3">
                              <a
                                href={`../api/multimedia${this.getDownloadLink(
                                  document.basePath,
                                  document.multimediaName,
                                  document.typePath
                                )}`}
                                download
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="h-5 lg:h-6 w-5 lg:w-6 mx-auto"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  strokeWidth="1.5"
                                  stroke="currentColor"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3"
                                  />
                                </svg>
                                <span
                                  className={`block ${this.props.isFontBig
                                    ? "text-sm"
                                    : "text-xs"
                                    } break-normal`}
                                >
                                  {t(
                                    "buscador.acciones.descargar",
                                    "Descargar"
                                  )}
                                </span>
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="flex w-full items-center">
                          {document.originalPath.includes(".wav") ? (
                            <AudioPlayer
                              key={index}
                              id_proyecto={document.project_id}
                              className="w-full flex"
                              original={document.originalPath}
                              short={`api/streaming/audioV2${document.basePath}${document.multimediaName}`}
                              start={document.startTime}
                              end={document.endTime}
                              index={index}
                              action={this.showMarkTimes()}
                              initTime={this.getCurrentTime()}
                              pauseTime={this.pauseCurrentTime()}
                              isFontBig={this.props.isFontBig}

                            />
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>

          <div
            className={`flex w-full items-center justify-center py-3 relative select-none`}
          >
            <div className={`${this.state.showPagination ? "flex" : "hidden"}`}>
              <button
                className={`px-2 lg:px-4 py-1 lg:py-2 font-bold rounded bg-infokab-gray hover:bg-infokab-gray/90 text-white mx-2 ${this.props.isFontBig ? "text-base lg:text-lg" : "text-sm lg:text-base"
                  }`}
                onClick={this.fetchPreviousPage}
              >
                {t("buscador.acciones.anterior", "Anterior")}
              </button>
              <div
                className={`px-2 lg:px-4 py-1 lg:py-2 font-bold rounded bg-infokab-gray hover:bg-infokab-gray/90 text-white ${this.props.isFontBig ? "text-base lg:text-lg" : "text-sm lg:text-base"
                  }`}
              >
                {this.state.currentPage}
              </div>
              <button
                className={`px-2 lg:px-4 py-1 lg:py-2 font-bold rounded bg-infokab-gray hover:bg-infokab-gray/90 text-white mx-2 ${this.props.isFontBig ? "text-base lg:text-lg" : "text-sm lg:text-base"
                  }`}
                onClick={this.fetchNextPage}
              >
                {t("buscador.acciones.siguiente", "Siguiente")}
              </button>
            </div>
          </div>
        </div>

        {/* Footer */}
        <div className="flex justify-between flex-col lg:flex-row">
          <div
            className={`text-gray-200 space-x-2 ${this.props.isFontBig ? " text-lg" : "text-base"
              }`}
          >
            {/* <Link to="">
                {t("buscador.acciones.información_legal", "Información legal")}
              </Link>
              <span>|</span> */}
            <Link to="/info" className={`${this.props.isFontBig ? "text-base lg:text-lg" : "text-xs lg:text-base"}`}>
              {t("buscador.acciones.sobre_el_proyecto", "Sobre el proyecto")}
            </Link>
            <span>|</span>
              <Link to="/apps">apps</Link>
            {/*  <span>|</span>
              <Link to="/about">
                {t(
                  "buscador.acciones.patrocinadores_y_colaboradores",
                  "patrocinadores y colaboradores" 
                )}
              </Link>
              {/*<Navigate to="/about" replace={true}>Patrocinadores y Colaboradores</Navigate>*/}
          </div>

          <div
            className={`text-gray-200 ${this.props.isFontBig ? "text-base lg:text-lg" : "text-xs lg:text-base"
              }`}
          >
            <span>
              <a href={pdfLegal} target="_blank">
                © T'aantsil 2023.{" "}
                {t(
                  "buscador.acciones.todos_los_derechos_reservados",
                  "All rights reserved"
                )}
                . Centrogeo
              </a>
            </span>
          </div>
        </div>

        {/*<MapModal
          show={this.state.showModalMap}
          handleClose={this.hideModalMap}
          lat={this.state.lat}
          lng={this.state.lng}
        >
        </MapModal>*/}

        {this.state.showModalMap ? (
          <>
            <div
              className={`fixed top-0 left-0 w-full h-full z-50 bg-gray-800/60 `}
            >
              <section
                className={`fixed bg-white w-11/12 lg:w-1/4 h-96 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 p-4 flex flex-col rounded-md`}
              >
                <div className=" map ">
                  <MapContainer
                    center={[this.state.lat, this.state.lng]}
                    zoom={13}
                    bounds={this.state.bbox}
                    onClick={this.algo}
                  >
                    <TileLayer
                      attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    <Rectangle bounds={this.state.bbox} />
                    <this.fly></this.fly>
                  </MapContainer>
                </div>
                <div className="self-end space-x-2 pt-2">
                  <button
                    type="button"
                    className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none ease-linear transition-all duration-150"
                    onClick={this.hideModalMap}
                  >
                    {t("buscador.acciones.cerrar", "Cerrar")}
                  </button>
                </div>
              </section>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
          </>
        ) : null}

        <Modal
          show={this.state.showEmailModal}
          handleClose={this.hideDownloadModal}
          size="w-11/12 lg:w-1/4"
          actions={[this.downloadResult]}
          labels={[t("buscador.acciones.enviar", "Enviar")]}
        >
          <div className="flex flex-col">
            <span className="text-xl font-bold tracking-wider mb-2">
              {t(
                "buscador.mensajes.descarga_de_resultados_de_búsqueda",
                "Descarga de resultados de búsqueda"
              )}
            </span>
            <div className="flex flex-col mb-4">
              <label
                htmlFor="download-email"
                className="block text-base font-medium text-gray-700"
              >
                {t(
                  "buscador.mensajes.ingresa_tu_correo_electrónico",
                  "Ingresa tu correo electrónico"
                )}
                :
              </label>
              <input
                type={"text"}
                name="download-email"
                className="mt-2 px-3 py-1.5 text-base font-normal border border-solid border-gray-300 rounded resize-none"
                value={this.state.downloadUserEmail}
                onChange={this.updateDownloadUserEmail}
              />
            </div>
          </div>
        </Modal>

        <Modal
          show={this.state.showModalAlertSuccess}
          handleClose={this.hideAlertSuccessModal}
          size="w-11/12 lg:w-1/5"
        >
          <h2 className="font-semibold text-gray-800">
            {this.state.message_success}
          </h2>
        </Modal>

        {/* Modal del audio completo */}


        {this.state.showCompleteAudio ? (
          <>
            <Modal
              show={this.state.showCompleteAudio}
              handleClose={this.hideShowOriginalAudio}
              size="w-11/12 lg:w-4/5 !h-[85%]"
            >
              { this.state.maya || this.state.spanish ? (
                <div className="flex flex-col w-full mt-5 pl-6 py-3 h-full overflow-auto bg-ondas" /*onWheel={this.handleScroll} onScroll={this.handleScroll} */  >
                  <div className="flex flex-col w-full mx-auto" >
                    {this.state.glosado.map((glosa, glosaIndex) => {
                      return (
                        <div
                          key={glosa.ANNOTATION_ID}
                          className={`${glosa.annotator_NAME == glosa.participant_NAME ? "w-auto flex self-start bg-white px-4 py-4 mb-2 shadow-lg rounded-md border-2 border-cdmx-gray" : "w-auto flex self-end bg-white px-4 py-4 mb-2 shadow-lg rounded-md border-2 border-cdmx-gray"}`}
                          ref={
                            this.state.start == glosa.TIME_VALUE1 / 1000
                              ? (accordionContent) => {
                                this.accordionContent[glosaIndex] =
                                  accordionContent;
                                this.getCurrentTimeInit(this.state.start);
                              }
                              : (accordionContent) =>
                              (this.accordionContent[glosaIndex] =
                                accordionContent)
                          }
                          onClick={this.handleTimeJump(
                            glosa.TIME_VALUE1 / 1000,
                            glosaIndex
                          )}
                        >
                            <div className={` flex justify-between`}>
                            <span
                              /*className="font-bold text-xl tracking-wide "*/ className={`font-bold tracking-wide pb-2 ${this.props.isFontBig
                                ? "text-lg lg:text-2xl"
                                : "text-base lg:text-xl"
                                } w-full h-full text-zinc-600 break-normal`}
                            >
                              { this.state.maya ? (
                                <>
                                  <sup
                                    className={`font-xs tracking-wide pb-2 ${this.props.isFontBig ? "text-sm" : "text-xs"
                                      } text-center w-full h-full text-red-600 items-center break-normal`}
                                  >
                                    {" "}
                                    {this.calculateTime(glosa.TIME_VALUE1 / 1000)}
                                  </sup>{" "}
                                  { this.state.maya ? glosa.ANNOTATION_VALUE_TRANSCRIPCION_LITERAL : ''}{" "}
                                  <sup
                                    className={`font-xs tracking-wide pb-2 ${this.props.isFontBig ? "text-sm" : "text-xs"
                                      } text-center w-full h-full text-red-600 items-center break-normal`}
                                  >
                                    {" "}
                                    {this.calculateTime(glosa.TIME_VALUE2 / 1000)}
                                  </sup>
                                </>
                              ) : null }

                              { this.state.spanish ? (
                                <>
                                  { this.state.maya ? (<br></br>) : null}
                                  <sup
                                    className={`font-xs tracking-wide pb-2 ${this.props.isFontBig ? "text-sm" : "text-xs"
                                      } text-center w-full h-full text-red-600 items-center break-normal`}
                                  >
                                    {" "}
                                    {this.calculateTime(glosa.TIME_VALUE1 / 1000)}
                                  </sup>{" "}
                                  { this.state.spanish ? glosa.ANNOTATION_VALUE_TRADUCCION_LIBRE : ''}{" "}
                                  <sup
                                    className={`font-xs tracking-wide pb-2 ${this.props.isFontBig ? "text-sm" : "text-xs"
                                      } text-center w-full h-full text-red-600 items-center break-normal`}
                                  >
                                    {" "}
                                    {this.calculateTime(glosa.TIME_VALUE2 / 1000)}
                                  </sup>
                                </>
                              ) : null }
                            </span>
                          </div>
                        </div>
                      );
                    },
                    )}
                  </div>
                </div>
              ):<div className="flex flex-col w-full mt-5 pl-6 py-3 h-full overflow-auto bg-ondas" /*onWheel={this.handleScroll} onScroll={this.handleScroll} */  ></div>}

              <AudioPlayer
                short={`api/streaming/audioV3${this.state.originalAudio}`}
                ref={this.myRef}
                showButton={false}
                initTime={this.getCurrentTime()}
                pauseTime={this.pauseCurrentTime()}
                className="w-full flex"
                start={this.state.start}
                end={this.state.end}
                subtitle={this.setAnnotationView()}
              />
            </Modal>
          </>
        ) : null}

      </div>
    );
  }
}

export default withTranslation()(Search);

/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Routes, Route, NavLink, Link } from "react-router-dom";
import GuardedRoute from "./GuardedRoute";
import UploadForm from "./UploadForm";
import Projects from "./Projects";
import GlosaEditor from "./GlosaEditor";
import GlosaDictionary from "./GlosaDictionary";
import UsersList from "./UsersList";
import Storage from "./Storage";
import Soundex from "./Soundex";
import Logs from "./Logs";
import LogsView from "./LogView";
import { withTranslation } from "react-i18next";
import IndexList from "./IndexList";
import DownloadList from "./DownloadList";
import Header from "./Header";
import pdfLegal from "../INDAUTOR-Taantsil.pdf";
import UserSearch from "./UserSearch";

class AdminPanel extends React.Component {
  constructor() {
    super();
    this.state = {
      showLoading: false,
      showSubir: false,
      showProyectos: false,
      showDiccionarios: false,
      showUserPermissions: false,
      isAuthenticated: true,
      permissions: {
        buscador: true,
        subir: true,
        editor_glosas: true,
        dev: true
      },
      showContext: false
    };
  }

  handleSidebar = module => {
    this.setState(
      {
        showLoading: false,
        showSubir: false,
        showProyectos: false,
        showDiccionarios: false,
        showUserPermissions: false
      },
      () => {
        switch (module) {
          case "Subir":
            this.setState({ showSubir: true });
            break;
          case "Proyectos":
            this.setState({ showProyectos: true });
            break;
          case "Diccionarios":
            this.setState({ showDiccionarios: true });
            break;
          case "UserPermissions":
            this.setState({ showUserPermissions: true });
            break;
          default:
            break;
        }
      }
    );
  };

  componentDidMount() {
    if (localStorage.getItem("permisos") !== "null") {
      this.setState({
        permissions: JSON.parse(localStorage.getItem("permisos"))
      });
    }
  }

  handleClick = e => {
    if (this.state.showContext) {
      this.setState({
        showContext: false
      });
    }
  };

  handleScroll = evt => {
    if (this.state.showContext) {
      this.setState({
        showContext: false
      });
    }
  };

  handleContext = () => {
    this.setState({
      showContext: true
    });
  };

  render() {
    const { t } = this.props;

    return (
      <div
        className={`bg-white ${this.props.isContrast ? "invert" : "invert-0"}`}
        onClick={this.handleClick}
      >
        <Header
          isFontBig={this.props.isFontBig}
          onAuthChange={this.props.onAuthChange}
        />
        <div className="flex flex-row min-h-screen bg-white text-black">
          <aside className="pt-20 sidebar w-64 md:shadow transform -translate-x-full md:translate-x-0 transition-transform duration-150 ease-in bg-cdmx-green">
            <div className="sidebar-content px-4 py-6">
              <ul className="flex flex-col w-full">
                <li className="my-px">
                  <span
                    className={`flex font-medium ${this.props.isFontBig ? "text-xl" : "text-md"
                      } text-white px-4 my-4 uppercase`}
                  >
                    {t("menu_de_izquierda.titulo_de_modulos", "Modulos")}
                  </span>
                </li>

                <li className="my-px">
                  <NavLink
                    className={({ isActive }) =>
                      "flex flex-row items-center h-10 px-3 rounded-lg hover:bg-gray-300 hover:text-gray-700" +
                      (!isActive ? " text-white" : " bg-gray-300 text-black")
                    }
                    to="projects"
                  >
                    <span className="flex items-center justify-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        className={`${this.props.isFontBig ? "w-8 h-8" : "w-6 h-6"
                          }`}
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 002.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 00-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25zM6.75 12h.008v.008H6.75V12zm0 3h.008v.008H6.75V15zm0 3h.008v.008H6.75V18z"
                        />
                      </svg>
                    </span>
                    <span
                      className={`ml-3 ${this.props.isFontBig ? "text-xl" : "text-base"
                        }`}
                    >
                      {t("menu_de_izquierda.modulos.proyectos", "Proyectos")}
                    </span>
                  </NavLink>
                </li>
                {this.state.permissions.subir ? (
                  <li className="my-px">
                    <NavLink
                      className={({ isActive }) =>
                        "flex flex-row items-center h-10 px-3 rounded-lg hover:bg-gray-300 hover:text-gray-700" +
                        (!isActive ? " text-white" : " bg-gray-300 text-black")
                      }
                      to="uploadForm"
                    >
                      <span className="flex items-center justify-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          className={`${this.props.isFontBig ? "w-8 h-8" : "w-6 h-6"
                            }`}
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M12 16.5V9.75m0 0l3 3m-3-3l-3 3M6.75 19.5a4.5 4.5 0 01-1.41-8.775 5.25 5.25 0 0110.233-2.33 3 3 0 013.758 3.848A3.752 3.752 0 0118 19.5H6.75z"
                          />
                        </svg>
                      </span>
                      <span
                        className={`ml-3 ${this.props.isFontBig ? "text-xl" : "text-base"
                          }`}
                      >
                        {t("menu_de_izquierda.modulos.subir_archivos", "Subir")}
                      </span>
                    </NavLink>
                  </li>
                ) : null}
                {this.state.permissions.editor_glosas ? (
                  <li className="my-px">
                    <NavLink
                      className={({ isActive }) =>
                        "flex flex-row items-center h-10 px-3 rounded-lg hover:bg-gray-300 hover:text-gray-700" +
                        (!isActive ? " text-white" : " bg-gray-300 text-black")
                      }
                      to="dictionary"
                    >
                      <span className="flex items-center justify-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          className={`${this.props.isFontBig ? "w-8 h-8" : "w-6 h-6"
                            }`}
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M12 6.042A8.967 8.967 0 006 3.75c-1.052 0-2.062.18-3 .512v14.25A8.987 8.987 0 016 18c2.305 0 4.408.867 6 2.292m0-14.25a8.966 8.966 0 016-2.292c1.052 0 2.062.18 3 .512v14.25A8.987 8.987 0 0018 18a8.967 8.967 0 00-6 2.292m0-14.25v14.25"
                          />
                        </svg>
                      </span>
                      <span
                        className={`ml-3 ${this.props.isFontBig ? "text-xl" : "text-base"
                          }`}
                      >
                        {t(
                          "menu_de_izquierda.modulos.diccionarios",
                          "Diccionarios"
                        )}
                      </span>
                    </NavLink>
                  </li>
                ) : null}
              </ul>
              {this.state.permissions.dev ? (
                <>
                  <hr />
                  <ul className="flex flex-col w-full py-10">
                    <li className="my-px">
                      <span
                        className={`flex font-medium ${this.props.isFontBig ? "text-xl" : "text-md"
                          } text-white px-4 my-4 uppercase`}
                      >
                        {t(
                          "menu_de_izquierda.titulo_de_configuracion",
                          "Configuraciones"
                        )}
                      </span>
                    </li>
                    <li className="my-px">
                      <NavLink
                        className={({ isActive }) =>
                          "flex flex-row items-center h-10 px-3 rounded-lg hover:bg-gray-300 hover:text-gray-800 " +
                          (!isActive
                            ? " text-white"
                            : " bg-gray-300 text-black")
                        }
                        to="userPermissions"
                      >
                        <span className="flex items-center justify-center">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="currentColor"
                            className={`${this.props.isFontBig ? "w-8 h-8" : "w-6 h-6"
                              }`}
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M18 18.72a9.094 9.094 0 003.741-.479 3 3 0 00-4.682-2.72m.94 3.198l.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0112 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 016 18.719m12 0a5.971 5.971 0 00-.941-3.197m0 0A5.995 5.995 0 0012 12.75a5.995 5.995 0 00-5.058 2.772m0 0a3 3 0 00-4.681 2.72 8.986 8.986 0 003.74.477m.94-3.197a5.971 5.971 0 00-.94 3.197M15 6.75a3 3 0 11-6 0 3 3 0 016 0zm6 3a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0zm-13.5 0a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0z"
                            />
                          </svg>
                        </span>
                        <span
                          className={`ml-3 ${this.props.isFontBig ? "text-xl" : "text-base"
                            }`}
                        >
                          {t(
                            "menu_de_izquierda.configuracion.usuarios",
                            "Usuarios"
                          )}
                        </span>
                      </NavLink>
                    </li>
                    <li className="my-px">
                      <NavLink
                        className={({ isActive }) =>
                          "flex flex-row items-center h-10 px-3 rounded-lg hover:bg-gray-300 hover:text-gray-800 " +
                          (!isActive
                            ? " text-white"
                            : " bg-gray-300 text-black")
                        }
                        to="indices"
                      >
                        <span className="flex items-center justify-center">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 -960 960 960"
                            className={`fill-current ${this.props.isFontBig ? "w-8 h-8" : "w-6 h-6"
                              }`}
                          >
                            <path d="M480-120q-151 0-255.5-46.5T120-280v-400q0-66 105.5-113T480-840q149 0 254.5 47T840-680v400q0 67-104.5 113.5T480-120Zm0-479q89 0 179-25.5T760-679q-11-29-100.5-55T480-760q-91 0-178.5 25.5T200-679q14 30 101.5 55T480-599Zm0 199q42 0 81-4t74.5-11.5q35.5-7.5 67-18.5t57.5-25v-120q-26 14-57.5 25t-67 18.5Q600-528 561-524t-81 4q-42 0-82-4t-75.5-11.5Q287-543 256-554t-56-25v120q25 14 56 25t66.5 18.5Q358-408 398-404t82 4Zm0 200q46 0 93.5-7t87.5-18.5q40-11.5 67-26t32-29.5v-98q-26 14-57.5 25t-67 18.5Q600-328 561-324t-81 4q-42 0-82-4t-75.5-11.5Q287-343 256-354t-56-25v99q5 15 31.5 29t66.5 25.5q40 11.5 88 18.5t94 7Z" />
                          </svg>
                        </span>
                        <span
                          className={`ml-3 ${this.props.isFontBig ? "text-xl" : "text-base"
                            }`}
                        >
                          {t(
                            "menu_de_izquierda.configuracion.indices",
                            "Índices"
                          )}
                        </span>
                      </NavLink>
                    </li>
                    <li className="my-px">
                      <NavLink
                        className={({ isActive }) =>
                          "flex flex-row items-center h-10 px-3 rounded-lg hover:bg-gray-300 hover:text-gray-800 " +
                          (!isActive
                            ? " text-white"
                            : " bg-gray-300 text-black")
                        }
                        to="storage"
                      >
                        <span className="flex items-center justify-center text-lgtext-white">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="currentColor"
                            className="w-6 h-6"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M7.875 14.25l1.214 1.942a2.25 2.25 0 001.908 1.058h2.006c.776 0 1.497-.4 1.908-1.058l1.214-1.942M2.41 9h4.636a2.25 2.25 0 011.872 1.002l.164.246a2.25 2.25 0 001.872 1.002h2.092a2.25 2.25 0 001.872-1.002l.164-.246A2.25 2.25 0 0116.954 9h4.636M2.41 9a2.25 2.25 0 00-.16.832V12a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 12V9.832c0-.287-.055-.57-.16-.832M2.41 9a2.25 2.25 0 01.382-.632l3.285-3.832a2.25 2.25 0 011.708-.786h8.43c.657 0 1.281.287 1.709.786l3.284 3.832c.163.19.291.404.382.632M4.5 20.25h15A2.25 2.25 0 0021.75 18v-2.625c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125V18a2.25 2.25 0 002.25 2.25z"
                            />
                          </svg>
                        </span>
                        <span className="ml-3">
                          {t(
                            "menu_de_izquierda.configuracion.almacenamiento",
                            "Almacenamiento"
                          )}
                        </span>
                      </NavLink>
                    </li>
                  </ul>
                </>
              ) : null}
            </div>
            <div className="absolute bottom-0 left-0 sidebar-content px-4 py-6">
              <img src="/../logo3.png" className="object-center" alt="" />
            </div>
          </aside>
          <main
            className="pt-20 main w-full flex bg-cdmx-gray flex-col flex-grow -ml-64 md:ml-0 transition-all duration-150 ease-in overflow-auto h-screen"
            onScroll={this.handleScroll}
          >
            <div className="main-content flex flex-col flex-grow">
              <div className="flex flex-col flex-grow  rounded mt-4">
                <Routes>
                  <Route
                    exact
                    path="/uploadForm"
                    element={
                      <GuardedRoute
                        auth={this.state.isAuthenticated}
                        haspermissions={this.state.permissions.subir}
                      >
                        <UploadForm isFontBig={this.props.isFontBig} />
                      </GuardedRoute>
                    }
                  ></Route>
                  <Route
                    path="/projects"
                    element={
                      <GuardedRoute
                        auth={this.state.isAuthenticated}
                        haspermissions={true}
                      >
                        <Projects isFontBig={this.props.isFontBig} />
                      </GuardedRoute>
                    }
                  />
                  <Route
                    path="/projects/editor/:id"
                    element={
                      <GuardedRoute
                        auth={this.state.isAuthenticated}
                        haspermissions={this.state.permissions.editor_glosas}
                      >
                        <GlosaEditor
                          showContext={this.state.showContext}
                          handleContext={this.handleContext}
                        />
                      </GuardedRoute>
                    }
                  />
                  <Route
                    exact
                    path="/dictionary"
                    element={
                      <GuardedRoute
                        auth={this.state.isAuthenticated}
                        haspermissions={this.state.permissions.editor_glosas}
                      >
                        <GlosaDictionary isFontBig={this.props.isFontBig} />
                      </GuardedRoute>
                    }
                  />
                  <Route
                    path="/userPermissions"
                    element={
                      <GuardedRoute
                        auth={this.state.isAuthenticated}
                        haspermissions={this.state.permissions.dev}
                      >
                        <UsersList isFontBig={this.props.isFontBig} />
                      </GuardedRoute>
                    }
                  />
                  <Route
                    path="/logs"
                    element={
                      <GuardedRoute auth={this.state.isAuthenticated}>
                        <Logs />
                      </GuardedRoute>
                    }
                  />
                  <Route
                    path="/logs/view/:id"
                    element={
                      <GuardedRoute auth={this.state.isAuthenticated}>
                        <LogsView />
                      </GuardedRoute>
                    }
                  />
                  <Route
                    path="/indices"
                    element={
                      <GuardedRoute
                        auth={this.state.isAuthenticated}
                        haspermissions={this.state.permissions.dev}
                      >
                        <IndexList isFontBig={this.props.isFontBig} />
                      </GuardedRoute>
                    }
                  />
                  <Route
                    path="/downloads"
                    element={
                      <GuardedRoute auth={this.state.isAuthenticated}>
                        <DownloadList />
                      </GuardedRoute>
                    }
                  />
                  <Route
                    path="/storage"
                    element={
                      <GuardedRoute
                        auth={this.state.isAuthenticated}
                        haspermissions={this.state.permissions.dev}
                      >
                        <Storage isFontBig={this.props.isFontBig} />
                      </GuardedRoute>
                    }
                  />

                  {/* <Route
                    path="/soundex"
                    element={
                      <GuardedRoute
                        auth={this.state.isAuthenticated}
                        haspermissions={this.state.permissions.dev}
                      >
                        <Soundex />
                      </GuardedRoute>
                    }
                  /> */}

                  <Route
                    path="/busquedas"
                    element={
                      <GuardedRoute
                        auth={this.state.isAuthenticated}
                        haspermissions={this.state.permissions.dev}
                      >
                        <UserSearch />
                      </GuardedRoute>
                    }
                  />
                </Routes>
              </div>
            </div>
            <footer className="footer px-4 py-6">
              <div className="footer-content">
                <p
                  className={`${this.props.isFontBig ? "text-lg" : "text-sm"
                    } text-gray-600 text-center`}
                >
                  © T'aantsil 2023.{" "}
                  {t(
                    "buscador.acciones.todos_los_derechos_reservados",
                    "All rights reserved"
                  )}{" "}
                  .{" "}
                  <a href={pdfLegal} target="_blank">
                    Centrogeo
                  </a>
                </p>
              </div>
            </footer>
          </main>
        </div>
      </div>
    );
  }
}

export default withTranslation()(AdminPanel);

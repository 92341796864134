import React from "react";
import ReactMarkdown from "react-markdown";

class UserManual extends React.Component {
  constructor() {
    super();

    this.state = {
      markdown: null
    };
  }

  componentDidMount() {
    this.loadFile("search.md");
  }

  loadFile = fileName => {
    const testPath = require(`../documentation/${fileName}`);

    fetch(testPath)
      .then(response => {
        return response.text();
      })
      .then(text => {
        this.setState({
          markdown: text
        });
      });
  };

  render() {
    return (
      <div className="flex w-full py-3 h-full">
        <div className="w-2/12 h-auto mx-2 px-4 py-3 border-2 rounded-md">
          <ul className="text-lg font-semibold cursor-pointer text-blue-500">
            <li onClick={() => this.loadFile("login.md")}>Iniciar sesión</li>
            <li onClick={() => this.loadFile("search.md")}>Buscador</li>
            {/*<li onClick={() => this.loadFile("test1.md")}>Prueba 1</li>
            <li onClick={() => this.loadFile("test2.md")}>Prueba 2</li>*/}
            <li onClick={() => this.loadFile("uploadFiles.md")}>Subir Proyecto</li>
            <li onClick={() => this.loadFile("projects.md")}>Proyecto</li>
          </ul>
        </div>
        <div className="prose w-10/12 max-w-none h-full px-4 overflow-auto prose-a:text-blue-500 prose-a:no-underline prose-code:before:content-none prose-code:after:content-none prose-img:mx-auto">
          <ReactMarkdown children={this.state.markdown} />
        </div>
      </div>
    );
  }
}

export default UserManual;

import React from "react";
import LoadingScreen from "./LoadingScreen";
import { withTranslation } from "react-i18next";
import { format } from "date-fns";

class UserSearch extends React.Component {
    constructor() {
        super();

        this.state = {
            searchs: [],
            showLoading: false
        };

        this.fetchSearchs = this.fetchSearchs.bind(this);
    }

    componentDidMount() {
        this.fetchSearchs();
    }

    fetchSearchs = () => {
        fetch("../api/search/userSearch", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then(response => {
                response.json().then(data => {
                    this.setState({
                        searchs: data
                    });
                });
            })
            .catch(error => {
                console.log(error);
            });
    };

    formatCDate = dateProject => {
        const date = new Date(dateProject);
        const dateOffset = new Date(
            date.valueOf() - date.getTimezoneOffset() * 60 * 1000
        );

        return format(dateOffset, "dd/MM/yyyy hh:mm:ss a");
    };

    render() {
        return (
            <div className="w-full p-6  overflow-auto">
                {this.state.showLoading ? (
                    <LoadingScreen title="Guardando..." message="Espere un momento" />
                ) : null}

                <div className="flex flex-wrap bg-white">
                    <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                        <div className="overflow-hidden">
                            <table className="w-full">
                                <thead className="bg-slate-900 border-b">
                                    <tr>
                                        <th
                                            scope="col"
                                            className={`${this.props.isFontBig ? "text-xl" : "text-base"
                                                } font-extrabold text-white px-6 py-4 text-center`}
                                        >
                                            Búsqueda
                                        </th>
                                        <th
                                            scope="col"
                                            className={`${this.props.isFontBig ? "text-xl" : "text-base"
                                                } font-extrabold text-white px-6 py-4 text-center`}
                                        >
                                            Fecha
                                        </th>
                                        <th
                                            scope="col"
                                            className={`${this.props.isFontBig ? "text-xl" : "text-base"
                                                } font-extrabold text-white px-6 py-4 text-center`}
                                        >
                                            Indice
                                        </th>
                                        <th
                                            scope="col"
                                            className={`${this.props.isFontBig ? "text-xl" : "text-base"
                                                } font-extrabold text-white px-6 py-4 text-center`}
                                        >
                                            Soundex
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.searchs.map((search, index) => (
                                        <tr
                                            key={index}
                                            className={` ${index % 2 === 0
                                                ? "bg-white border-b"
                                                : "bg-gray-100 border-b"
                                                } `}
                                        >
                                            <td
                                                className={`${this.props.isFontBig ? "text-lg" : "text-sm"
                                                    } text-gray-900 font-light px-6 py-4 whitespace-normal text-center`}
                                            >
                                                {search.consulta}
                                            </td>

                                            <td
                                                className={`${this.props.isFontBig ? "text-lg" : "text-sm"
                                                    } text-gray-900 font-light px-6 py-4 whitespace-normal text-center`}
                                            >
                                                {this.formatCDate(search.fecha_creacion)}
                                            </td>
                                            <td
                                                className={`${this.props.isFontBig ? "text-lg" : "text-sm"
                                                    } text-gray-900 font-light px-6 py-4 whitespace-normal text-center`}
                                            >
                                                {search.indice}
                                            </td>
                                            <td
                                                className={`${this.props.isFontBig ? "text-lg" : "text-sm"
                                                    } text-gray-900 font-light px-6 py-4 whitespace-normal text-center`}
                                            >
                                                {search.soundex}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(UserSearch);

import React from "react";
import Multiselect from "multiselect-react-dropdown";
import "./GlosaDicionary.css";
import { withTranslation } from "react-i18next";

class GlosaDictionary extends React.Component {
  constructor() {
    super();

    this.state = {
      codeOptions: [
        {
          name: "INTERJ"
        },
        {
          name: "ADJ"
        },
        {
          name: "CLF"
        },
        {
          name: "PREP"
        },
        {
          name: "PRON"
        },
        {
          name: "PART"
        },
        {
          name: "NOUN"
        },
        {
          name: "VT"
        },
        {
          name: "CONJ"
        },
        {
          name: "ADV"
        },
        {
          name: "VI"
        }
      ],
      selectedCodeValue: [],

      extraOptions: [
        {
          name: "ninguno"
        },
        {
          name: "Lehmann"
        },
        {
          name: "texts"
        },
        {
          name: "+"
        }
      ],
      selectedExtraValue: [],

      selectedValueCode: "",

      maxRecords: 10,
      selectDictionary: "bases",

      bases: [],
      showPaginationBases: false,
      currentPageBases: 1,
      maxPageBases: 1,
      searchBases: "",

      prefixes: [],
      showPaginationPrefixes: false,
      currentPagePrefixes: 1,
      maxPagePrefixes: 1,
      searchPrefixes: "",

      suffixes: [],
      showPaginationSuffixes: false,
      currentPageSuffixes: 1,
      maxPageSuffixes: 1,
      searchSuffixes: "",

      showModal: false,
      showAlertDelete: false,
      showAlertSuccess: false,

      statusModal: "",
      dictionaryType: "",
      valueClave: "",
      valueCode: "",
      valueDescription: "",
      valueTraduction: "",
      valueExtra: "",
      valueID: "",

      errorClave: false,
      errorValidateClave: false,
      errorCode: false,
      errorDescription: false,
      errorValidateDescription: false
    };
    //Search Word
    this.searchDictionaryBases = this.searchDictionaryBases.bind(this);
    this.searchDictionaryPrefixes = this.searchDictionaryPrefixes.bind(this);
    this.searchDictionarySuffixes = this.searchDictionarySuffixes.bind(this);

    //Pagination bases
    this.fetchGlosaBases = this.fetchGlosaBases.bind(this);
    this.fetchNextPageBases = this.fetchNextPageBases.bind(this);
    this.fetchPreviousPageBases = this.fetchPreviousPageBases.bind(this);

    //Pagination prefixes
    this.fetchGlosaPrefixes = this.fetchGlosaPrefixes.bind(this);
    this.fetchNextPagePrefixes = this.fetchNextPagePrefixes.bind(this);
    this.fetchPreviousPagePrefixes = this.fetchPreviousPagePrefixes.bind(this);

    //Pagination suffixes
    this.fetchGlosaSuffixes = this.fetchGlosaSuffixes.bind(this);
    this.fetchPreviousPageSuffixes = this.fetchPreviousPageSuffixes.bind(this);
    this.fetchNextPageSuffixes = this.fetchNextPageSuffixes.bind(this);

    //Actions new register
    this.newRegister = this.newRegister.bind(this);
    this.saveNewRegister = this.saveNewRegister.bind(this);

    //Actions update register
    this.updateRegister = this.updateRegister.bind(this);
    this.saveUpdateRegister = this.saveUpdateRegister.bind(this);

    //Actions delete register
    this.deleteRegister = this.deleteRegister.bind(this);

    //Action close modal
    this.hideRegister = this.hideRegister.bind(this);

    //Action alerte message
    this.showAlertDeleteRegister = this.showAlertDeleteRegister.bind(this);
    this.hideAlertDeleteRegister = this.hideAlertDeleteRegister.bind(this);
    this.showAlertSuccess = this.showAlertSuccess.bind(this);
    this.hideAlertSuccess = this.hideAlertSuccess.bind(this);

    //Update Form
    this.handleChangeClave = this.handleChangeClave.bind(this);
    this.handleChangeCoode = this.handleChangeCoode.bind(this);
    this.handleChangeDescription = this.handleChangeDescription.bind(this);
    this.handleChangeTraduction = this.handleChangeTraduction.bind(this);
    this.handleChangeExtra = this.handleChangeExtra.bind(this);

    //Render htmls
    this.renderTable = this.renderTable.bind(this);
    this.renderModal = this.renderModal.bind(this);
    this.renderAlertDelete = this.renderAlertDelete.bind(this);
    this.renderAlertSuccess = this.renderAlertSuccess.bind(this);
    this.renderInteractiveTabs = this.renderInteractiveTabs.bind(this);
    this.renderSectionBases = this.renderSectionBases.bind(this);
    this.renderSectionPrefixes = this.renderSectionPrefixes.bind(this);
    this.renderSectionSuffixes = this.renderSectionSuffixes.bind(this);
    this.selectDictionary = this.selectDictionary.bind(this);
  }

  componentDidMount() {
    this.fetchGlosaBases();
    this.fetchGlosaPrefixes();
    this.fetchGlosaSuffixes();
  }

  fetchGlosaBases() {
    let body = {
      page: this.state.currentPageBases,
      record: this.state.maxRecords,
      search: this.state.searchBases
    };

    fetch("../api/dictionary/bases/list", {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json"
      }
    })
      .then(response => {
        response.json().then(data => {
          let maxRecords = this.state.maxRecords;
          let totalHits = data.totalHits;

          if (totalHits > maxRecords) {
            let maxPageBases = Math.ceil(totalHits / maxRecords);
            this.setState({
              showPaginationBases: true,
              bases: data.registers,
              maxPageBases: maxPageBases
            });
          } else {
            this.setState({
              showPaginationBases: false,
              bases: data.registers,
              maxPageBases: 1
            });
          }
        });
      })
      .catch(error => {
        console.log("error", error);
      });
  }

  fetchPreviousPageBases() {
    if (this.state.currentPageBases > 1) {
      let newPage = this.state.currentPageBases - 1;
      this.setState(
        {
          currentPageBases: newPage
        },
        () => {
          this.fetchGlosaBases();
        }
      );
    }
  }

  fetchNextPageBases() {
    if (this.state.currentPageBases < this.state.maxPageBases) {
      let newPage = this.state.currentPageBases + 1;
      this.setState(
        {
          currentPageBases: newPage
        },
        () => {
          this.fetchGlosaBases();
        }
      );
    }
  }

  fetchGlosaPrefixes() {
    let body = {
      page: this.state.currentPagePrefixes,
      record: this.state.maxRecords,
      search: this.state.searchPrefixes
    };

    fetch("../api/dictionary/prefixes/list", {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json"
      }
    })
      .then(response => {
        response.json().then(data => {
          let maxRecords = this.state.maxRecords;
          let totalHits = data.totalHits;

          if (totalHits > maxRecords) {
            let maxPageBases = Math.ceil(totalHits / maxRecords);
            this.setState({
              showPaginationPrefixes: true,
              prefixes: data.registers,
              maxPagePrefixes: maxPageBases
            });
          } else {
            this.setState({
              showPaginationPrefixes: false,
              prefixes: data.registers,
              maxPageBases: 1
            });
          }
        });
      })
      .catch(error => {
        console.log("error", error);
      });
  }

  fetchPreviousPagePrefixes() {
    if (this.state.currentPagePrefixes > 1) {
      let newPage = this.state.currentPagePrefixes - 1;
      this.setState(
        {
          currentPagePrefixes: newPage
        },
        () => {
          this.fetchGlosaPrefixes();
        }
      );
    }
  }

  fetchNextPagePrefixes() {
    if (this.state.currentPagePrefixes < this.state.maxPagePrefixes) {
      let newPage = this.state.currentPagePrefixes + 1;
      this.setState(
        {
          currentPagePrefixes: newPage
        },
        () => {
          this.fetchGlosaPrefixes();
        }
      );
    }
  }

  fetchGlosaSuffixes() {
    let body = {
      page: this.state.currentPageSuffixes,
      record: this.state.maxRecords,
      search: this.state.searchSuffixes
    };

    fetch("../api/dictionary/suffixes/list", {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json"
      }
    })
      .then(response => {
        response.json().then(data => {
          let maxRecords = this.state.maxRecords;
          let totalHits = data.totalHits;

          if (totalHits > maxRecords) {
            let maxPageBases = Math.ceil(totalHits / maxRecords);
            this.setState({
              showPaginationSuffixes: true,
              suffixes: data.registers,
              maxPageSuffixes: maxPageBases
            });
          } else {
            this.setState({
              showPaginationSuffixes: false,
              suffixes: data.registers,
              maxPageSuffixes: 1
            });
          }
        });
      })
      .catch(error => {
        console.log("error", error);
      });
  }

  fetchPreviousPageSuffixes() {
    if (this.state.currentPageSuffixes > 1) {
      let newPage = this.state.currentPageSuffixes - 1;
      this.setState(
        {
          currentPageSuffixes: newPage
        },
        () => {
          this.fetchGlosaSuffixes();
        }
      );
    }
  }

  fetchNextPageSuffixes() {
    if (this.state.currentPageSuffixes < this.state.maxPageSuffixes) {
      let newPage = this.state.currentPageSuffixes + 1;
      this.setState(
        {
          currentPageSuffixes: newPage
        },
        () => {
          this.fetchGlosaSuffixes();
        }
      );
    }
  }

  updateDictionary(dictionary) {
    switch (dictionary) {
      case "bases":
        this.fetchGlosaBases();
        break;
      case "prefixes":
        this.fetchGlosaPrefixes();
        break;
      case "suffixes":
        this.fetchGlosaSuffixes();
        break;
      default:
        break;
    }
  }

  updateRegister = (register, dictionary) => evt => {
    let list_code = register.codigo.split("/");
    let select_code = [];
    list_code.forEach(elem => {
      select_code.push({
        name: elem
      });
    });

    let select_extra = [];

    if (register.extra === "") {
      select_extra = [
        {
          name: "ninguno"
        }
      ];
    } else {
      select_extra = [
        {
          name: register.extra
        }
      ];
    }

    this.setState(
      {
        statusModal: "update",
        dictionaryType: dictionary,
        valueID: register.id,
        valueClave: register.clave,
        valueCode: register.codigo,
        selectedCodeValue: select_code,
        selectedExtraValue: select_extra,
        valueDescription: register.descripcion,
        valueTraduction: register.traduccion,
        valueExtra: register.extra,
        showAlertDelete: false,
        errorClave: false,
        errorValidateClave: false,
        errorCode: false,
        errorDescription: false,
        errorValidateDescription: false
      },
      () => {
        this.setState({ showModal: true });
      }
    );
  };

  newRegister = dictionary => evt => {
    this.setState(
      {
        statusModal: "new",
        dictionaryType: dictionary,
        valueClave: "",
        valueCode: "",
        valueDescription: "",
        valueTraduction: "",
        valueExtra: "",
        selectedCodeValue: [],
        selectedExtraValue: [],
        showAlertDelete: false,
        errorClave: false,
        errorValidateClave: false,
        errorCode: false,
        errorDescription: false,
        errorValidateDescription: false
      },
      () => {
        this.setState({ showModal: true });
      }
    );
  };

  hideRegister = () => {
    this.setState({
      showModal: false
    });
  };

  Register = () => {
    this.setState({
      showModal: false
    });
  };

  saveNewRegister = () => {
    let dictionaryType = this.state.dictionaryType;

    let register = {
      clave: this.state.valueClave,
      code: this.state.valueCode,
      description: this.state.valueDescription,
      traduction: this.state.valueTraduction,
      extra: this.state.valueExtra
    };

    if (this.validateForm()) {
      fetch(`../api/dictionary/${dictionaryType}/create`, {
        method: "POST",
        body: JSON.stringify(register),
        headers: {
          "Content-Type": "application/json"
        }
      })
        .then(response => {
          response.json().then(() => {
            this.showAlertSuccess();
            this.updateDictionary(dictionaryType);
          });
        })
        .catch(error => {
          console.log(error);
        });
    }
  };

  saveUpdateRegister = () => {
    let dictionaryType = this.state.dictionaryType;
    let register = {
      id: this.state.valueID,
      clave: this.state.valueClave,
      code: this.state.valueCode,
      description: this.state.valueDescription,
      traduction: this.state.valueTraduction,
      extra: this.state.valueExtra
    };

    if (this.validateForm()) {
      fetch(`../api/dictionary/${dictionaryType}/update`, {
        method: "POST",
        body: JSON.stringify(register),
        headers: {
          "Content-Type": "application/json"
        }
      })
        .then(response => {
          response.json().then(() => {
            this.showAlertSuccess();
            this.updateDictionary(dictionaryType);
          });
        })
        .catch(error => {
          console.log(error);
        });
    }
  };

  validateForm() {
    const reg_spanish = new RegExp("^[a-zA-ZñÑÁÉÍÓÚáéíóúÜü0-9-.,/ ]+$");
    const reg_maya = new RegExp(
      "^(a|aa|áa|a'|a'a|b|ch|ch'|e|ee|ée|e'|e'e|i|ii|íi|i'|i'i|j|k|k'|l|m|n|o|oo|óo|o'|o'o|p|p'|r|s|t|t'|ts|ts'|u|uu|úu|u'|u'u|w|x|y)+$"
    );

    let clave = this.state.valueClave;
    let code = this.state.valueCode;
    let description = this.state.valueDescription;
    let errorClave = clave === "" ? true : false;
    let errorValidateClave =
      !errorClave && !reg_maya.test(clave) ? true : false;
    let errorCode = code === "" ? true : false;
    let errorDescription = description === "" ? true : false;
    let errorValidateDescription =
      !errorDescription && !reg_spanish.test(description) ? true : false;

    this.setState({
      errorClave: errorClave,
      errorCode: errorCode,
      errorDescription: errorDescription,
      errorValidateDescription: errorValidateDescription,
      errorValidateClave: errorValidateClave
    });

    if (
      errorClave ||
      errorValidateClave ||
      errorCode ||
      errorDescription ||
      errorValidateDescription
    ) {
      return false;
    }

    return true;
  }

  showAlertSuccess = () => {
    this.setState({
      showModal: false,
      showAlertDelete: false,
      showAlertSuccess: true
    });
  };

  hideAlertSuccess = () => evt => {
    this.setState({
      showAlertSuccess: false
    });
  };

  showAlertDeleteRegister = (register, dictionary) => evt => {
    this.setState(
      {
        statusModal: "delete",
        dictionaryType: dictionary,
        valueID: register.id,
        showModal: false
      },
      () => {
        this.setState({
          showAlertDelete: true
        });
      }
    );
  };

  hideAlertDeleteRegister = () => evt => {
    this.setState({
      showAlertDelete: false
    });
  };

  deleteRegister = id => evt => {
    let dictionaryType = this.state.dictionaryType;
    fetch(`../api/dictionary/${dictionaryType}/delete/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json"
      }
    })
      .then(response => {
        response.json().then(data => {
          this.showAlertSuccess();
          this.updateDictionary(dictionaryType);
        });
      })
      .catch(error => {
        console.log(error);
      });
  };

  handleChangeClave(event) {
    this.setState({ valueClave: event.target.value });
  }
  handleChangeCoode(event) {
    this.setState({ valueCode: event.target.value });
  }
  handleChangeDescription(event) {
    this.setState({ valueDescription: event.target.value });
  }
  handleChangeTraduction(event) {
    this.setState({ valueTraduction: event.target.value });
  }
  handleChangeExtra(event) {
    this.setState({ valueExtra: event.target.value });
  }

  searchDictionaryBases(event) {
    this.setState(
      {
        currentPageBases: 1,
        searchBases: event.target.value
      },
      () => {
        this.fetchGlosaBases();
      }
    );
  }

  searchDictionaryPrefixes(event) {
    this.setState(
      {
        currentPagePrefixes: 1,
        searchPrefixes: event.target.value
      },
      () => {
        this.fetchGlosaPrefixes();
      }
    );
  }

  searchDictionarySuffixes(event) {
    this.setState(
      {
        currentPageSuffixes: 1,
        searchSuffixes: event.target.value
      },
      () => {
        this.fetchGlosaSuffixes();
      }
    );
  }

  selectDictionary = selectDictionary => evt => {
    this.setState({
      selectDictionary: selectDictionary
    });
  };

  onSelect = evt => {
    let selectValuecode = [];
    evt.forEach(data => {
      selectValuecode.push(data.name);
    });

    let valueCode = selectValuecode.join("/");
    this.setState({
      valueCode: valueCode
    });
  };

  onRemove = evt => {
    let selectValuecode = [];
    evt.forEach(data => {
      selectValuecode.push(data.name);
    });

    let valueCode = selectValuecode.join("/");
    this.setState({
      valueCode: valueCode
    });
  };

  onSelectExtra = evt => {
    let selectExtra = "";
    evt.forEach(data => {
      selectExtra = data.name === "ninguno" ? "" : data.name;
    });

    this.setState({
      valueExtra: selectExtra
    });
  };

  render() {
    const { t } = this.props;
    return (
      <div className="w-full px-4 py-3 h-full overflow-auto">
        {this.renderInteractiveTabs()}
        {this.renderModal()}
        {this.renderAlertDelete()}
        {this.renderAlertSuccess()}
      </div>
    );
  }

  renderInteractiveTabs() {
    const { t } = this.props;
    return (
      <>
        <div className="mb-4 border-b border-gray-200 ">
          <ul
            className="flex flex-wrap -mb-px text-sm font-medium text-center"
            id="dictionaries"
            data-tabs-toggle="#dictionaries"
            role="tablist"
          >
            <li className="mr-2" role="presentation">
              <button
                onClick={this.selectDictionary("bases")}
                className={` ${
                  this.state.selectDictionary === "bases"
                    ? "border-blue-600"
                    : "border-gray-600"
                } bg-white inline-block p-4 rounded-t-lg border-b-2 border-transparent hover:text-gray-600 hover:border-blue-300`}
                id="bases-tab"
                data-tabs-target="#bases"
                type="button"
                role="tab"
                aria-controls="bases"
                aria-selected="false"
              >
                <h3
                  className={`${
                    this.state.selectDictionary === "bases"
                      ? "text-blue-600"
                      : "text-stone-600"
                  } font-medium leadi ng-tight text-2xl mt-0 mb-2`}
                >
                  {t("menu_diccionarios.tabs.bases", "Bases")}
                </h3>
              </button>
            </li>
            <li className="mr-2" role="presentation">
              <button
                onClick={this.selectDictionary("prefixes")}
                className={` ${
                  this.state.selectDictionary === "prefixes"
                    ? "border-blue-600"
                    : "border-gray-600"
                } bg-white inline-block p-4 rounded-t-lg border-b-2 border-transparent hover:text-gray-600 hover:border-blue-300`}
                id="prefixes-tab"
                data-tabs-target="#prefixes"
                type="button"
                role="tab"
                aria-controls="prefixes"
                aria-selected="false"
              >
                <h3
                  className={`${
                    this.state.selectDictionary === "prefixes"
                      ? "text-blue-600"
                      : "text-stone-600"
                  } font-medium leadi ng-tight text-2xl mt-0 mb-2`}
                >
                  {t("menu_diccionarios.tabs.prefjos", "Prefijos")}
                </h3>
              </button>
            </li>
            <li role="presentation">
              <button
                onClick={this.selectDictionary("suffixes")}
                className={` ${
                  this.state.selectDictionary === "suffixes"
                    ? "border-blue-600"
                    : "border-gray-600"
                } bg-white inline-block p-4 rounded-t-lg border-b-2 border-transparent hover:text-gray-600 hover:border-blue-300`}
                id="suffixes-tab"
                data-tabs-target="#suffixes"
                type="button"
                role="tab"
                aria-controls="suffixes"
                aria-selected="false"
              >
                <h3
                  className={`${
                    this.state.selectDictionary === "suffixes"
                      ? "text-blue-600"
                      : "text-stone-600"
                  } font-medium leadi ng-tight text-2xl mt-0 mb-2`}
                >
                  {t("menu_diccionarios.tabs.sufijos", "Sufijos")}
                </h3>
              </button>
            </li>
          </ul>
        </div>
        <div id="dictionaries">
          <div
            className={`${
              this.state.selectDictionary === "bases" ? "" : "hidden"
            } p-4 bg-gray-50 rounded-lg`}
            id="bases"
            role="tabpanel"
            aria-labelledby="bases-tab"
          >
            {this.renderSectionBases()}
          </div>
          <div
            className={`${
              this.state.selectDictionary === "prefixes" ? "" : "hidden"
            } p-4 bg-gray-50 rounded-lg`}
            id="prefixes"
            role="tabpanel"
            aria-labelledby="prefixes-tab"
          >
            {this.renderSectionPrefixes()}
          </div>
          <div
            className={`${
              this.state.selectDictionary === "suffixes" ? "" : "hidden"
            } p-4 bg-gray-50 rounded-lg`}
            id="suffixes"
            role="tabpanel"
            aria-labelledby="suffixes-tab"
          >
            {this.renderSectionSuffixes()}
          </div>
        </div>
      </>
    );
  }

  renderSectionBases() {
    const { t } = this.props;
    return (
      <>
        <div className="flex w-full items-center justify-end py-3 flex">
          {/* <div className="flex justify-start w-1/2">
          <h3 className="font-medium leadi ng-tight text-2xl mt-0 mb-2 text-blue-600">Bases</h3>
        </div> */}
          <div className="flex justify-end w-1/2">
            <div className="max-w-md">
              <div className="relative flex items-center w-full h-12 rounded-lg focus-within:shadow-lg bg-white overflow-hidden">
                <div className="grid place-items-center h-full w-12 text-gray-300">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                    />
                  </svg>
                </div>

                <input
                  className={`peer h-full w-full outline-none ${
                    this.props.isFontBig ? "text-lg" : "text-sm"
                  } text-gray-700 pr-2`}
                  type="text"
                  id="search"
                  onChange={this.searchDictionaryBases}
                  placeholder={t(
                    "menu_diccionarios.acciones.buscar",
                    "Buscar.."
                  )}
                />
              </div>
            </div>
            <button
              className={`font-bold rounded bg-infokab-gray hover:bg-infokab-gray/90 text-white mx-2 ${
                this.props.isFontBig
                  ? "text-xl px-5 py-3"
                  : "text-base px-4 py-2"
              }`}
              onClick={this.newRegister("bases")}
            >
              {t("menu_diccionarios.acciones.nuevo_registro", "Nuevo Registro")}
            </button>
          </div>
        </div>

        {this.renderTable("bases")}

        <div
          className={`flex w-full items-center justify-center py-3 select-none ${
            this.state.showPaginationBases ? "flex" : "hidden"
          }`}
        >
          <button
            className={`font-bold rounded bg-infokab-gray hover:bg-infokab-gray/90 text-white mx-2 ${
              this.props.isFontBig ? "text-xl px-5 py-3" : "text-base px-4 py-2"
            }`}
            onClick={this.fetchPreviousPageBases}
          >
            {t("menu_diccionarios.acciones.anterior", "Anterior")}
          </button>
          <div
            className={`font-bold rounded bg-infokab-gray hover:bg-infokab-gray/90 text-white ${
              this.props.isFontBig ? "text-xl px-5 py-3" : "text-base px-4 py-2"
            }`}
          >
            {this.state.currentPageBases}
          </div>
          <button
            className={`font-bold rounded bg-infokab-gray hover:bg-infokab-gray/90 text-white mx-2 ${
              this.props.isFontBig ? "text-xl px-5 py-3" : "text-base px-4 py-2"
            }`}
            onClick={this.fetchNextPageBases}
          >
            {t("menu_diccionarios.acciones.siguiente", "Siguiente")}
          </button>
        </div>
      </>
    );
  }

  renderSectionPrefixes() {
    const { t } = this.props;
    return (
      <>
        <div className="flex w-full items-center justify-end py-3 flex">
          {/* <div className="flex justify-start w-1/2">
          <h3 className="font-medium leadi ng-tight text-2xl mt-0 mb-2 text-blue-600">Prefijos</h3>
        </div> */}
          <div className="flex justify-end w-1/2">
            <div className="max-w-md">
              <div className="relative flex items-center w-full h-12 rounded-lg focus-within:shadow-lg bg-white overflow-hidden">
                <div className="grid place-items-center h-full w-12 text-gray-300">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                    />
                  </svg>
                </div>

                <input
                  className="peer h-full w-full outline-none text-sm text-gray-700 pr-2"
                  type="text"
                  id="search"
                  onChange={this.searchDictionaryPrefixes}
                  placeholder={t(
                    "menu_diccionarios.acciones.buscar",
                    "Buscar.."
                  )}
                />
              </div>
            </div>
            <button
              className={`font-bold rounded bg-infokab-gray hover:bg-infokab-gray/90 text-white mx-2 ${
                this.props.isFontBig
                  ? "text-xl px-5 py-3"
                  : "text-base px-4 py-2"
              }`}
              onClick={this.newRegister("prefixes")}
            >
              {t("menu_diccionarios.acciones.nuevo_registro", "Nuevo Registro")}
            </button>
          </div>
        </div>

        {this.renderTable("prefixes")}

        <div
          className={`flex w-full items-center justify-center py-3 select-none ${
            this.state.showPaginationPrefixes ? "flex" : "hidden"
          }`}
        >
          <button
            className={`font-bold rounded bg-infokab-gray hover:bg-infokab-gray/90 text-white mx-2 ${
              this.props.isFontBig ? "text-xl px-5 py-3" : "text-base px-4 py-2"
            }`}
            onClick={this.fetchPreviousPagePrefixes}
          >
            {t("menu_diccionarios.acciones.anterior", "Anterior")}
          </button>
          <div
            className={`font-bold rounded bg-infokab-gray hover:bg-infokab-gray/90 text-white ${
              this.props.isFontBig ? "text-xl px-5 py-3" : "text-base px-4 py-2"
            }`}
          >
            {this.state.currentPagePrefixes}
          </div>
          <button
            className={`font-bold rounded bg-infokab-gray hover:bg-infokab-gray/90 text-white mx-2 ${
              this.props.isFontBig ? "text-xl px-5 py-3" : "text-base px-4 py-2"
            }`}
            onClick={this.fetchNextPagePrefixes}
          >
            {t("menu_diccionarios.acciones.siguiente", "Siguiente")}
          </button>
        </div>
      </>
    );
  }

  renderSectionSuffixes() {
    const { t } = this.props;
    return (
      <>
        <div className="flex w-full items-center justify-end py-3 flex">
          {/* <div className="flex justify-start w-1/2">
          <h3 className="font-medium leadi ng-tight text-2xl mt-0 mb-2 text-blue-600">Sufijos</h3>
        </div> */}
          <div className="flex justify-end w-1/2">
            <div className="max-w-md">
              <div className="relative flex items-center w-full h-12 rounded-lg focus-within:shadow-lg bg-white overflow-hidden">
                <div className="grid place-items-center h-full w-12 text-gray-300">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                    />
                  </svg>
                </div>

                <input
                  className="peer h-full w-full outline-none text-sm text-gray-700 pr-2"
                  type="text"
                  id="search"
                  onChange={this.searchDictionarySuffixes}
                  placeholder={t(
                    "menu_diccionarios.acciones.buscar",
                    "Buscar.."
                  )}
                />
              </div>
            </div>
            <button
              className={`font-bold rounded bg-infokab-gray hover:bg-infokab-gray/90 text-white mx-2 ${
                this.props.isFontBig
                  ? "text-xl px-5 py-3"
                  : "text-base px-4 py-2"
              }`}
              onClick={this.newRegister("suffixes")}
            >
              {t("menu_diccionarios.acciones.nuevo_registro", "Nuevo Registro")}
            </button>
          </div>
        </div>

        {this.renderTable("suffixes")}

        <div
          className={`flex w-full items-center justify-center py-3 ${
            this.state.showPaginationSuffixes ? "flex" : "hidden"
          }`}
        >
          <button
            className={`font-bold rounded bg-infokab-gray hover:bg-infokab-gray/90 text-white mx-2 ${
              this.props.isFontBig ? "text-xl px-5 py-3" : "text-base px-4 py-2"
            }`}
            onClick={this.fetchPreviousPageSuffixes}
          >
            {t("menu_diccionarios.acciones.anterior", "Anterior")}
          </button>
          <div
            className={`font-bold rounded bg-infokab-gray hover:bg-infokab-gray/90 text-white ${
              this.props.isFontBig ? "text-xl px-5 py-3" : "text-base px-4 py-2"
            }`}
          >
            {this.state.currentPageSuffixes}
          </div>
          <button
            className={`font-bold rounded bg-infokab-gray hover:bg-infokab-gray/90 text-white mx-2 ${
              this.props.isFontBig ? "text-xl px-5 py-3" : "text-base px-4 py-2"
            }`}
            onClick={this.fetchNextPageSuffixes}
          >
            {t("menu_diccionarios.acciones.siguiente", "Siguiente")}
          </button>
        </div>
      </>
    );
  }

  renderTable(dictionary) {
    const { t } = this.props;
    return (
      <div className="flex flex-col">
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
            <div className="overflow-hidden">
              <table className="w-full">
                <thead className="bg-slate-900 border-b">
                  <tr>
                    <th
                      scope="col"
                      className={`${
                        this.props.isFontBig ? "text-xl" : "text-base"
                      } font-extrabold text-white px-6 py-4 text-left`}
                    >
                      {t("menu_diccionarios.titulos_de_tabla.bases", "Bases")}
                    </th>
                    <th
                      scope="col"
                      className={`${
                        this.props.isFontBig ? "text-xl" : "text-base"
                      } font-extrabold text-white px-6 py-4 text-left`}
                    >
                      {t(
                        "menu_diccionarios.titulos_de_tabla.categoria",
                        "Categorias"
                      )}
                    </th>
                    <th
                      scope="col"
                      className={`${
                        this.props.isFontBig ? "text-xl" : "text-base"
                      } font-extrabold text-white px-6 py-4 text-left`}
                    >
                      {t("menu_diccionarios.titulos_de_tabla.glosa", "Glosa")}
                    </th>
                    <th
                      scope="col"
                      className={`${
                        this.props.isFontBig ? "text-xl" : "text-base"
                      } font-extrabold text-white px-6 py-4 text-left`}
                    >
                      {t(
                        "menu_diccionarios.titulos_de_tabla.traduccion",
                        "Traducción"
                      )}
                    </th>

                    {dictionary != "bases" ? (
                      <th
                        scope="col"
                        className={`${
                          this.props.isFontBig ? "text-xl" : "text-base"
                        } font-extrabold text-white px-6 py-4 text-left`}
                      >
                        {t("menu_diccionarios.titulos_de_tabla.extra", "Extra")}
                      </th>
                    ) : null}
                    <th
                      scope="col"
                      className={`${
                        this.props.isFontBig ? "text-xl" : "text-base"
                      } font-extrabold text-white px-6 py-4 text-left`}
                    >
                      {t(
                        "menu_diccionarios.titulos_de_tabla.acciones",
                        "Acciones"
                      )}
                    </th>
                  </tr>
                </thead>
                {((registers, dictionary) => {
                  return (
                    <tbody>
                      {registers.map((register, index) => {
                        return (
                          <tr
                            key={index}
                            className={` ${
                              index % 2 === 0
                                ? "bg-white border-b"
                                : "bg-gray-100 border-b"
                            } `}
                          >
                            <td
                              className={`${
                                this.props.isFontBig ? "text-lg" : "text-sm"
                              } text-gray-900 font-light px-6 py-4 whitespace-normal`}
                            >
                              {register.clave}
                            </td>
                            <td
                              className={`${
                                this.props.isFontBig ? "text-lg" : "text-sm"
                              } text-gray-900 font-light px-6 py-4 whitespace-normal`}
                            >
                              {register.codigo}
                            </td>
                            <td
                              className={`${
                                this.props.isFontBig ? "text-lg" : "text-sm"
                              } text-gray-900 font-light px-6 py-4 whitespace-normal`}
                            >
                              {register.descripcion}
                            </td>
                            <td
                              className={`${
                                this.props.isFontBig ? "text-lg" : "text-sm"
                              } text-gray-900 font-light px-6 py-4 whitespace-normal`}
                            >
                              {register.traduccion}
                            </td>
                            {dictionary != "bases" ? (
                              <td
                                className={`${
                                  this.props.isFontBig ? "text-lg" : "text-sm"
                                } text-gray-900 font-light px-6 py-4 whitespace-normal`}
                              >
                                {register.extra}
                              </td>
                            ) : null}
                            <td
                              className={`${
                                this.props.isFontBig ? "text-lg" : "text-sm"
                              } text-gray-900 font-light px-6 py-4 whitespace-normal`}
                            >
                              <button
                                onClick={this.updateRegister(
                                  register,
                                  dictionary
                                )}
                                type="button"
                                className={`text-white bg-infokab-gray hover:bg-infokab-gray/90 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg ${
                                  this.props.isFontBig ? "text-lg" : "text-sm"
                                } px-5 py-2.5 text-center mr-2 mb-2`}
                              >
                                {t(
                                  "menu_diccionarios.acciones.editar",
                                  "Editar"
                                )}
                              </button>
                              <button
                                onClick={this.showAlertDeleteRegister(
                                  register,
                                  dictionary
                                )}
                                type="button"
                                className="text-red-500 background-transparent font-bold"
                              >
                                {t(
                                  "menu_diccionarios.acciones.eliminar",
                                  "Eliminar"
                                )}
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  );
                })(this.state[dictionary], dictionary)}
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderModal() {
    const { t } = this.props;
    return (
      <>
        {this.state.showModal ? (
          <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
              <div className="relative w-auto my-6 mx-auto max-w-2xl w-1/4">
                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                  <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                    <h3
                      className={`${
                        this.props.isFontBig ? "text-5xl" : "text-3xl"
                      } font-bold`}
                    >
                      {(() => {
                        switch (this.state.selectDictionary) {
                          case "bases":
                            return this.state.statusModal === "new" ? (
                              <>
                                {t(
                                  "menu_diccionarios.mensajes.nuevo_registro",
                                  "Nuevo Registro"
                                )}{" "}
                              </>
                            ) : (
                              <>
                                {t(
                                  "menu_diccionarios.mensajes.actualizacion_de_registro",
                                  "Actualización de Registro"
                                )}
                              </>
                            );
                          case "prefixes":
                            return this.state.statusModal === "new" ? (
                              <>
                                {t(
                                  "menu_diccionarios.mensajes.nuevo_registro",
                                  "Nuevo Registro"
                                )}{" "}
                              </>
                            ) : (
                              <>
                                {t(
                                  "menu_diccionarios.mensajes.actualizacion_de_registro",
                                  "Actualización de Registro"
                                )}
                              </>
                            );
                          case "suffixes":
                            return this.state.statusModal === "new" ? (
                              <>
                                {t(
                                  "menu_diccionarios.mensajes.nuevo_registro",
                                  "Nuevo Registro"
                                )}{" "}
                              </>
                            ) : (
                              <>
                                {t(
                                  "menu_diccionarios.mensajes.actualizacion_de_registro",
                                  "Actualización de Registro"
                                )}
                              </>
                            );
                          default:
                            return "";
                        }
                      })()}
                    </h3>
                    <button
                      className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                      type="button"
                      onClick={this.hideRegister}
                    >
                      <svg
                        className="h-6 w-6"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </button>
                  </div>

                  <div className="relative p-6 flex-auto">
                    <div className="flex flex-wrap -mx-3">
                      <div className="w-full px-3 mb-6 md:mb-0">
                        <label
                          className={`block uppercase tracking-wide text-gray-700 ${
                            this.props.isFontBig ? "text-base" : "text-xs"
                          } font-bold mb-2 text-left`}
                          htmlFor="clave"
                        >
                          {t("menu_diccionarios.titulos_de_tabla.base", "Base")}
                        </label>
                        <input
                          className={`${
                            this.state.errorClave ||
                            this.state.errorValidateClave
                              ? ""
                              : "mb-3"
                          } appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white`}
                          id="clave"
                          type="text"
                          value={this.state.valueClave}
                          onChange={this.handleChangeClave}
                        />
                        {this.state.errorClave ? (
                          <p
                            className={`text-red-500 ${
                              this.props.isFontBig ? "text-base" : "text-xs"
                            } italic mb-3`}
                          >
                            {t(
                              "menu_diccionarios.mensajes.ingrese_una_base",
                              "Ingrese una base."
                            )}
                          </p>
                        ) : null}
                        {this.state.errorValidateClave ? (
                          <p
                            className={`text-red-500 ${
                              this.props.isFontBig ? "text-base" : "text-xs"
                            } italic mb-3`}
                          >
                            {t(
                              "menu_diccionarios.mensajes.regex_base",
                              "Solo se aceptan estas letras a, aa, áa, a', a'a, b, ch, ch', e, ee, ée, e', e'e, i, ii, íi, i', i'i, j, k, k', l, m, n, o, oo, óo, o', o'o, p, p', r, s, t, t', ts, ts', u, uu, úu, u', u'u, w, x, y."
                            )}
                          </p>
                        ) : null}
                      </div>
                    </div>

                    <div className="flex flex-wrap -mx-3">
                      <div className="w-full px-3 mb-6 md:mb-0">
                        <label
                          className={`block uppercase tracking-wide text-gray-700 ${
                            this.props.isFontBig ? "text-base" : "text-xs"
                          } font-bold mb-2 text-left`}
                          htmlFor="code"
                        >
                          {t(
                            "menu_diccionarios.titulos_de_tabla.categoria",
                            "Categoria"
                          )}
                        </label>
                        <Multiselect
                          className={`${
                            this.state.errorCode ? "" : "mb-3"
                          } appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-1 px-1 leading-tight focus:outline-none focus:bg-white`}
                          options={this.state.codeOptions}
                          selectedValues={this.state.selectedCodeValue}
                          placeholder=""
                          emptyRecordMsg="No hay más opciones"
                          onSelect={this.onSelect}
                          onRemove={this.onRemove}
                          displayValue="name"
                        />
                        {this.state.errorCode ? (
                          <p
                            className={`text-red-500 ${
                              this.props.isFontBig ? "text-base" : "text-xs"
                            } italic mb-3`}
                          >
                            {t(
                              "menu_diccionarios.mensajes.seleccione_una_categoria",
                              "Seleccione una categoria."
                            )}
                          </p>
                        ) : null}
                      </div>
                    </div>

                    <div className="flex flex-wrap -mx-3">
                      <div className="w-full px-3 mb-6 md:mb-0">
                        <label
                          className={`block uppercase tracking-wide text-gray-700 ${
                            this.props.isFontBig ? "text-base" : "text-xs"
                          } font-bold mb-2 text-left`}
                          htmlFor="description"
                        >
                          {t(
                            "menu_diccionarios.titulos_de_tabla.glosa",
                            "Glosa"
                          )}
                        </label>
                        <input
                          className={`${
                            this.state.errorDescription ||
                            this.state.errorValidateDescription
                              ? ""
                              : "mb-3"
                          } appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white`}
                          id="description"
                          type="text"
                          value={this.state.valueDescription}
                          onChange={this.handleChangeDescription}
                        />
                        {this.state.errorDescription ? (
                          <p
                            className={`text-red-500 ${
                              this.props.isFontBig ? "text-base" : "text-xs"
                            } italic mb-3`}
                          >
                            {t(
                              "menu_diccionarios.mensajes.ingrese_una_glosa",
                              "Ingrese una glosa."
                            )}
                          </p>
                        ) : null}
                        {this.state.errorValidateDescription ? (
                          <p
                            className={`text-red-500 ${
                              this.props.isFontBig ? "text-base" : "text-xs"
                            } italic mb-3`}
                          >
                            {t(
                              "menu_diccionarios.mensajes.regex_glosa",
                              "Solo se aceptan a-z o A-Z."
                            )}
                          </p>
                        ) : null}
                      </div>
                    </div>

                    <div className="flex flex-wrap -mx-3">
                      <div className="w-full px-3 mb-6 md:mb-0">
                        <label
                          className={`block uppercase tracking-wide text-gray-700 ${
                            this.props.isFontBig ? "text-base" : "text-xs"
                          } font-bold mb-2 text-left`}
                          htmlFor="traduction"
                        >
                          {t(
                            "menu_diccionarios.titulos_de_tabla.traduccion",
                            "Traducción"
                          )}
                        </label>
                        <input
                          className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-2 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                          id="traduction"
                          type="text"
                          value={this.state.valueTraduction}
                          onChange={this.handleChangeTraduction}
                        />
                      </div>
                    </div>

                    {this.state.selectDictionary != "bases" ? (
                      <div className="flex flex-wrap -mx-3">
                        <div className="w-full px-3 mb-6 md:mb-0">
                          <label
                            className={`block uppercase tracking-wide text-gray-700 ${
                              this.props.isFontBig ? "text-base" : "text-xs"
                            } font-bold mb-2 text-left`}
                            htmlFor="extre"
                          >
                            {t(
                              "menu_diccionarios.titulos_de_tabla.extra",
                              "Extra"
                            )}
                          </label>

                          <Multiselect
                            className={`${
                              this.state.errorCode ? "" : "mb-3"
                            } appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-1 px-1 leading-tight focus:outline-none focus:bg-white h-12`}
                            options={this.state.extraOptions}
                            selectedValues={this.state.selectedExtraValue}
                            onSelect={this.onSelectExtra}
                            placeholder=""
                            singleSelect
                            displayValue="name"
                            customCloseIcon={<></>}
                          />
                        </div>
                      </div>
                    ) : null}
                  </div>

                  <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                    {(() => {
                      switch (this.state.statusModal) {
                        case "new":
                          return (
                            <button
                              className={`bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase ${
                                this.props.isFontBig ? "text-lg" : "text-sm"
                              } px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150`}
                              type="button"
                              onClick={this.saveNewRegister}
                            >
                              {t("menu_diccionarios.acciones.crear", "Crear")}
                            </button>
                          );
                        case "update":
                          return (
                            <button
                              className={`bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase ${
                                this.props.isFontBig ? "text-lg" : "text-sm"
                              } px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150`}
                              type="button"
                              onClick={this.saveUpdateRegister}
                            >
                              {t(
                                "menu_diccionarios.acciones.actualizar",
                                "Actualizar"
                              )}
                            </button>
                          );
                        default:
                          return <> </>;
                      }
                    })()}
                    <button
                      className={`text-red-500 background-transparent font-bold uppercase px-6 py-2 ${
                        this.props.isFontBig ? "text-lg" : "text-sm"
                      } outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150`}
                      type="button"
                      onClick={this.hideRegister}
                    >
                      {t("menu_diccionarios.acciones.cerrar", "cerrar")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
          </>
        ) : null}
      </>
    );
  }

  renderAlertDelete() {
    const { t } = this.props;
    return (
      <>
        {this.state.showAlertDelete ? (
          <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
              <div className="relative w-auto my-6 mx-auto max-w-3xl">
                <div className="flex flex-col p-5 rounded-lg shadow bg-white">
                  <div className="flex">
                    <div>
                      <svg
                        className="w-6 h-6 fill-current text-red-500"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                      >
                        <path d="M0 0h24v24H0V0z" fill="none" />
                        <path d="M12 5.99L19.53 19H4.47L12 5.99M12 2L1 21h22L12 2zm1 14h-2v2h2v-2zm0-6h-2v4h2v-4z" />
                      </svg>
                    </div>

                    <div className="ml-3">
                      <h2 className="font-semibold text-gray-800">
                        {t(
                          "menu_diccionarios.mensajes.eliminar_registro",
                          "¿Seguro que desea eliminar este registro?"
                        )}
                      </h2>
                    </div>
                  </div>

                  <div className="flex items-center mt-3">
                    <button
                      onClick={this.hideAlertDeleteRegister()}
                      className="flex-1 px-4 py-2 bg-infokab-gray hover:bg-infokab-gray/90 text-white text-sm font-medium rounded-md"
                    >
                      {t("menu_diccionarios.acciones.cerrar", "Cancel")}
                    </button>

                    <button
                      onClick={this.deleteRegister(this.state.valueID)}
                      className="flex-1 text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    >
                      {t("menu_diccionarios.acciones.eliminar", "Eliminar")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : null}
      </>
    );
  }

  renderAlertSuccess() {
    const { t } = this.props;
    return (
      <>
        {this.state.showAlertSuccess ? (
          <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
              <div className="relative w-auto my-6 mx-auto max-w-3xl">
                <div className="flex flex-col p-5 rounded-lg shadow bg-white">
                  <div className="flex">
                    <div>
                      <svg
                        className="w-6 h-6 fill-current text-green-500"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                      >
                        <path d="M0 0h24v24H0V0z" fill="none" />
                        <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm4.59-12.42L10 14.17l-2.59-2.58L6 13l4 4 8-8z" />
                      </svg>
                    </div>

                    <div className="ml-3">
                      <h2 className="font-semibold text-gray-800">
                        {t(
                          "menu_diccionarios.mensajes.exitosamente",
                          "Exitosamente"
                        )}
                      </h2>
                    </div>
                  </div>

                  <div className="flex items-center justify-center mt-3">
                    <button
                      onClick={this.hideAlertSuccess()}
                      className="bg-emerald-400 text-white hover:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150"
                    >
                      {t("menu_diccionarios.mensajes.aceptar", "Aceptar")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : null}
      </>
    );
  }
}

export default withTranslation()(GlosaDictionary);

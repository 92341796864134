import React from "react";
import { Navigate } from "react-router-dom";

const GuardedRoute = ({ auth, haspermissions, children }) => {

  console.log()

  if (localStorage.getItem("rol") === "1") {

  } else {
    if (!auth || !haspermissions) {
      return <Navigate to="/info" replace />;
    }
  }



  return children;
};

export default GuardedRoute;

import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Routes, Route, NavLink, Link } from "react-router-dom";
import { format } from "date-fns";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import { withTranslation } from "react-i18next";

//import "./SwiperStyles.css";

// import required modules
import { Autoplay } from "swiper";

import MarkerClusterGroup from "react-leaflet-cluster";
import {
  useMap,
  MapContainer,
  TileLayer,
  Rectangle,
  Marker,
} from "react-leaflet";
import L from "leaflet";
import iconMarker from "leaflet/dist/images/marker-icon.png";
import iconRetina from "leaflet/dist/images/marker-icon-2x.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";
import pdfLegal from "../INDAUTOR-Taantsil.pdf";
import Soundex from "./Soundex";
import "./uploadForm.css";
import "leaflet/dist/leaflet.css";
import Modal from "./Modal";

class CardsApps extends React.Component {
  constructor(props) {
    super();
    const { t, i18n } = props;
    this.state = {
      searchValue: "",
      focusValue: "",
      documents: [],
      diff_location: [],
      diff_location_points: [],
      showPagination: false,
      currentPage: 1,
      maxPage: 1,
      showDownload: false,
      showConfigMenu: false,
      selectedIndex: "maya",
      showLegend: true,
      queryType: false,
      showModalMap: false,
      lat: 20.97537,
      lng: -89.61696,
      showEmailModal: false,
      downloadUserEmail: "",
      position: [51.505, -0.09],
      bbox: [
        [-91.461182, 21.698265],
        [-91.417236, 21.728886],
      ],
    };

    this.fetchDocs = this.fetchDocs.bind(this);
  }

  componentDidMount() {
    console.log(this.state);
    this.fetchDocs();
  }

  fetchDocs() {
    let payload = {
      text: this.state.searchValue,
      index: this.state.selectedIndex,
      levenshtein: this.state.queryType,
    };

    this.setState(
      {
        documents: [],
        focusValue: this.state.searchValue,
        currentPage: 1,
        showConfigMenu: false,
      },
      () => {
        fetch("api/project/locations", {
          method: "POST",
          body: JSON.stringify(payload),
          headers: {
            "Content-Type": "application/json",
          },
        })
          .then((response) => {
            response.json().then((data) => {
              let diff_location_points = [];
              data.forEach((register) => {
                let info = {
                  position: JSON.parse("[" + register.ubicacion + "]"),
                  properties: register,
                };
                diff_location_points.push(info);
              });

              this.setState({
                diff_location_points: diff_location_points,
              });
            });
          })
          .catch((error) => {
            console.log(error.cause);
          });
      }
    );
  }

  render() {
    const { t } = this.props;
    const icon = L.icon({
      iconRetinaUrl: iconRetina,
      iconUrl: iconMarker,
      shadowUrl: iconShadow,
    });

    return (
      <div
        className={`relative w-full px-4 py-3 h-full bg-cdmx-gray flex flex-col ${
          this.props.isContrast ? "invert" : "invert-0"
        }`}
      >
        <div className="flex flex-col flex-grow overflow-auto">
          <div className="flex flex-col items-center justify-center pt-8 mb-8 w-full">
            <h1 className="text-5xl font-bold tracking-wider pb-1 text-white">
              Apps Lab
            </h1>
          </div>

          <div className="flex w-full h-full overflow-auto">
            <div className="flex w-1/2 mx-auto justify-center my-auto">
                <NavLink
                  className="flex-1 w-full rounded-lg hover:bg-gray-300 hover:text-gray-700 hover:bg-gray-300 hover:text-white p-2 "
                  to="/soundex"
                >
                  <div class="h-full w-full bg-gray-300 rounded overflow-hidden shadow-lg">
                    <img class="w-full h-56" src="cards/taantsil.png" alt="soundex"></img>
                    <div class="px-6 py-4">
                      <div class="font-bold text-xl mb-2">Maya Soundex Algorithm</div>
                      <p class="text-gray-700 text-base text-justify">
                        The Soundex algorithm is a widely used phonetic encoding technique designed to represent textual data in a manner that reflects their pronunciation.  The Soundex algorithm operates by converting input strings, into a compact alphanumeric code of fixed length. Its primary goal is to group similar-sounding words together, even when they have different spellings. While Soundex can work for multiple languages, it's important to note that it may not work equally well for all languages due to pronunciation variations. This is the fisrts adaptation of Soundex for the Mayan language.
                      </p>
                    </div>
                  </div>
                </NavLink>  
              

                <NavLink
                  className="flex-1 w-full rounded-lg hover:bg-gray-300 hover:text-gray-700 hover:bg-gray-300 hover:text-white p-2"
                  to="/proyectos"
                >
                  <div class="h-full w-full bg-gray-300 rounded overflow-hidden shadow-lg">
                    <img class="w-full h-56" src="cards/mapa.png" alt="mapa"></img>
                    <div class="px-6 py-4">
                      <div class="font-bold text-xl mb-2">Documentation Places</div>
                      <p class="text-gray-700 text-base text-justify">
                        This map present the communities visited during documentation. Part of our team has to go to distant places, with difficult access. Besides, interviewers have to be sensitive in order to earn the trust of people and get their permission.
                      </p>
                    </div>
                  </div>
                </NavLink>  
            </div>
          </div>

          {/* Footer */}
          <div className="flex justify-between flex-col lg:flex-row">
            <div
              className={`text-gray-200 space-x-2 ${this.props.isFontBig ? " text-lg" : "text-base"
                }`}
            >
              <Link to="/search">Search</Link>
            </div>
            <div
              className={`text-gray-200 space-x-2 ${
                this.props.isFontBig ? " text-lg" : "text-base"
              }`}
            ></div>
            <div
              className={`text-gray-200 ${
                this.props.isFontBig
                  ? "text-base lg:text-lg"
                  : "text-xs lg:text-base"
              }`}
            >
              <span>
                <a href={pdfLegal} target="_blank">
                  © T'aantsil 2023.{" "}
                  {t(
                    "buscador.acciones.todos_los_derechos_reservados",
                    "All rights reserved"
                  )}
                  . Centrogeo
                </a>
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(CardsApps);

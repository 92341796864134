import * as React from "react";
import { useState } from "react";

function AccesibilityMenu(props) {
  const [showMenu, setShowMenu] = useState(false);

  function changeFontSize() {
    props.changeFontSize();
  }

  function changeContrast() {
    props.changeContrast();
  }

  return (
    <div className="absolute bottom-8 right-2 flex flex-col-reverse">
      <div
        className="h-12 w-12 text-white border-2 rounded-full bg-blue-400 flex items-center justify-center cursor-pointer self-end"
        onClick={() => setShowMenu(!showMenu)}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 -960 960 960"
          className="w-12 h-12 fill-current"
        >
          <path d="M480-720q-33 0-56.5-23.5T400-800q0-33 23.5-56.5T480-880q33 0 56.5 23.5T560-800q0 33-23.5 56.5T480-720ZM360-80v-520q-60-5-122-15t-118-25l20-80q78 21 166 30.5t174 9.5q86 0 174-9.5T820-720l20 80q-56 15-118 25t-122 15v520h-80v-240h-80v240h-80Z" />
        </svg>
      </div>
      <div
        className={`${
          showMenu ? "flex" : "hidden"
        } bg-blue-400 px-2 py-4 rounded mb-2`}
      >
        <div className="flex">
          <div
            className={`${
              props.isContrast ? "bg-gray-400" : "bg-white"
            } px-4 py-2 flex flex-col items-center justify-center rounded-md mx-1 cursor-pointer select-none`}
            onClick={changeContrast}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 -960 960 960"
              className="w-8 h-8"
            >
              <path d="M480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm40-83q119-15 199.5-104.5T800-480q0-123-80.5-212.5T520-797v634Z" />
            </svg>
            <span>Contraste</span>
          </div>
          <div
            className={`${
              props.isFontBig ? "bg-gray-400" : "bg-white"
            } px-4 py-2 flex flex-col items-center justify-center rounded-md mx-1 cursor-pointer select-none`}
            onClick={changeFontSize}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-8 h-8"
              viewBox="0 -960 960 960"
            >
              <path d="M560-160v-520H360v-120h520v120H680v520H560Zm-360 0v-320H80v-120h360v120H320v320H200Z" />
            </svg>
            <span>Texto grande</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AccesibilityMenu;

import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { format } from "date-fns";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";

//import "./SwiperStyles.css";

// import required modules
import { Autoplay } from "swiper";

import MarkerClusterGroup from "react-leaflet-cluster";
import {
  useMap,
  MapContainer,
  TileLayer,
  Rectangle,
  Marker,
} from "react-leaflet";
import L from "leaflet";
import iconMarker from "leaflet/dist/images/marker-icon.png";
import iconRetina from "leaflet/dist/images/marker-icon-2x.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";
import pdfLegal from "../INDAUTOR-Taantsil.pdf";

import "./uploadForm.css";
import "leaflet/dist/leaflet.css";
import Modal from "./Modal";

class MapProjects extends React.Component {
  constructor(props) {
    super();
    const { t, i18n } = props;
    this.state = {
      searchValue: "",
      focusValue: "",
      documents: [],
      diff_location: [],
      diff_location_points: [],
      showPagination: false,
      currentPage: 1,
      maxPage: 1,
      showDownload: false,
      showConfigMenu: false,
      selectedIndex: "maya",
      showLegend: true,
      queryType: false,
      showModalMap: false,
      lat: 20.97537,
      lng: -89.61696,
      showEmailModal: false,
      downloadUserEmail: "",
      position: [51.505, -0.09],
      bbox: [
        [-91.461182, 21.698265],
        [-91.417236, 21.728886],
      ],
    };

    this.fetchDocs = this.fetchDocs.bind(this);
  }

  componentDidMount() {
    console.log(this.state);
    this.fetchDocs();
  }

  fetchDocs() {
    let payload = {
      text: this.state.searchValue,
      index: this.state.selectedIndex,
      levenshtein: this.state.queryType,
    };

    this.setState(
      {
        documents: [],
        focusValue: this.state.searchValue,
        currentPage: 1,
        showConfigMenu: false,
      },
      () => {
        fetch("api/project/locations", {
          method: "POST",
          body: JSON.stringify(payload),
          headers: {
            "Content-Type": "application/json",
          },
        })
          .then((response) => {
            response.json().then((data) => {
              let diff_location_points = [];
              data.forEach((register) => {
                let info = {
                  position: JSON.parse("[" + register.ubicacion + "]"),
                  properties: register,
                };
                diff_location_points.push(info);
              });

              this.setState({
                diff_location_points: diff_location_points,
              });
            });
          })
          .catch((error) => {
            console.log(error.cause);
          });
      }
    );
  }

  render() {
    const { t } = this.props;
    const icon = L.icon({
      iconRetinaUrl: iconRetina,
      iconUrl: iconMarker,
      shadowUrl: iconShadow,
    });

    return (
      <div
        className={`relative w-full px-4 py-3 h-full bg-cdmx-gray flex flex-col ${
          this.props.isContrast ? "invert" : "invert-0"
        }`}
      >
        <div className="flex flex-col flex-grow overflow-auto">
          <div className="flex flex-col items-center justify-center pt-8 mb-8 w-full">
            <h1 className="text-5xl font-bold tracking-wider pb-1 text-white">
              Documentation Places
            </h1>
          </div>

          <div className="flex w-full h-full flex-col lg:flex-row overflow-auto">
            <div className="w-full lg:w-full h-72 lg:h-full mb-2 lg:mb-0 mainMap">
              <MapContainer
                className="h-72 lg:h-full"
                center={[this.state.lat, this.state.lng]}
                bounds={this.state.bbox}
                zoom={11}
                //zoom={11}
              >
                <TileLayer
                  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />

                  <MarkerClusterGroup chunkedLoading>
                    {Object.keys(this.state.diff_location_points).map(
                      (location, index) => {
                        return (
                          <>
                            <Marker
                              key={index}
                              data={
                                this.state.diff_location_points[location]
                                  .properties
                              }
                              position={
                                this.state.diff_location_points[location]
                                  .position
                              }
                              icon={icon}
                            ></Marker>
                          </>
                        );
                      }
                    )}
                  </MarkerClusterGroup>
              </MapContainer>

              
            </div>
          </div>

           <div className="flex justify-between flex-col lg:flex-row">
          <div
            className={`text-gray-200 space-x-2 ${this.props.isFontBig ? " text-lg" : "text-base"
              }`}
          >
              <Link to="/search">Search</Link>
              <span>|</span>
              <Link to="/apps">apps</Link>
            </div>

            <div
              className={`text-gray-200 ${this.props.isFontBig ? "text-base lg:text-lg" : "text-xs lg:text-base"
                }`}
            >
              <span>
                <a href={pdfLegal} target="_blank">
                  © T'aantsil 2023.{" "}
                  {t(
                    "buscador.acciones.todos_los_derechos_reservados",
                    "All rights reserved"
                  )}
                  . Centrogeo
                </a>
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(MapProjects);

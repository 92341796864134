/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import Modal from "./Modal";
import LoadingScreen from "./LoadingScreen";
import { withTranslation } from "react-i18next";

class UsersList extends React.Component {
  constructor() {
    super();
    this.state = {
      registros: [],
      modalShow: false,
      modalAlertShow: false,
      textModalAlert: "",
      isSuccessModalAlert: true,
      errorName: false,
      errorLastName: false,
      errorEmail: false,
      errorValidateEmail: false,
      errorPassword: false,
      showLoading: false
    };
    this.showModal = this.showModal.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.showAlertSuccess = this.showAlertSuccess.bind(this);
    this.hideAlertSuccess = this.hideAlertSuccess.bind(this);
    this.register = this.register.bind(this);
  }

  fetchUsers() {
    const formData = new FormData();
    formData.append("uuid", localStorage.getItem("uuid"));

    const requestOptions = {
      method: "POST",
      body: formData
    };
    fetch("../api/user/list", requestOptions)
      .then(response => {
        response.json().then(data => {
          this.setState({ registros: data.registers });
        });
      })
      .catch(error => {
        console.log("error", error);
      });
  }

  componentDidMount() {
    const formData = new FormData();
    formData.append("uuid", localStorage.getItem("uuid"));
    this.fetchUsers();
  }

  /* Sergio */
  showModal = () => {
    this.setState({ modalShow: true });
  };

  hideModal = () => {
    this.setState({ modalShow: false });
  };

  register() {
    let name = document.getElementById("name");
    let lastName = document.getElementById("lastName");
    let email = document.getElementById("email");
    let password = document.getElementById("password");

    let body = {
      nombre: name.value,
      apellido: lastName.value,
      correo: email.value,
      password: password.value
    };

    let validate = this.validateForm(body);
    if (validate) {
      this.setState({
        showLoading: true
      });

      fetch("../api/user/create", {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
          "Content-Type": "application/json"
        }
      })
        .then(response => {
          response.json().then(data => {
            console.log(data);
            name.value = "";
            lastName.value = "";
            email.value = "";
            password.value = "";

            this.showAlertSuccess();
          });
        })
        .catch(error => {
          this.setState({
            showLoading: false,
            modalAlertShow: true,
            textModalAlert: "Hubó un problema con el registro",
            isSuccessModalAlert: false
          });
          console.log("error", error);
        });
    }
  }

  validateForm(body) {
    console.log(body);
    const reg_email = new RegExp("[a-z0-9]+@[a-z]+.[a-z]{2,3}");

    let errorName = body.nombre === "" ? true : false;
    let errorLastName = body.apellido === "" ? true : false;
    let errorEmail = body.correo === "" ? true : false;
    let errorValidateEmail =
      !errorEmail && !reg_email.test(body.correo) ? true : false;
    let errorPassword = body.password === "" ? true : false;

    this.setState({
      errorName: errorName,
      errorLastName: errorLastName,
      errorEmail: errorEmail,
      errorValidateEmail: errorValidateEmail,
      errorPassword: errorPassword
    });

    if (
      errorName ||
      errorLastName ||
      errorEmail ||
      errorValidateEmail ||
      errorPassword
    ) {
      return false;
    }

    return true;
  }

  showAlertSuccess = () => {
    this.setState({
      modalShow: false,
      modalAlertShow: true,
      textModalAlert: "Registrado exitosamente",
      showLoading: false,
      isSuccessModalAlert: true
    });
  };

  hideAlertSuccess = () => {
    this.fetchUsers();
    this.setState({
      modalAlertShow: false
    });
  };
  /* Sergio */
  updatePermissions(uuid, permissions) {
    const formData = new FormData();
    formData.append("uuid", uuid);
    formData.append("permissions", JSON.stringify(permissions));
    console.log(JSON.stringify(permissions));
    fetch("../api/user/updatePermissions", {
      method: "POST",
      body: formData
    })
      .then(response => {
        response.json().then(data => {
          console.log({ data });
        });
        this.fetchUsers();
        setTimeout(
          function () {
            //Start the timer
            this.setState({
              showLoading: false
            }); //After 1 second, set render to true
          }.bind(this),
          500
        );
      })
      .catch(error => {
        console.log("error", error);
        setTimeout(
          function () {
            //Start the timer
            this.setState({
              showLoading: false
            }); //After 1 second, set render to true
          }.bind(this),
          500
        );
      });
  }

  handleCheck = (event, uuid, permisos = null) => {
    this.setState({
      showLoading: true
    });
    let permissions = {
      subir: false,
      editor_glosas: false,
      cargar: false,
      descargar: false,
      dev: false
    };
    if (permisos) {
      Object.entries(permisos).forEach(entry => {
        const [key1, value1] = entry;
        Object.entries(permissions).forEach(entry => {
          const [key2] = entry;
          if (key1 === key2) {
            permissions[key2] = value1;
          }
        });
      });
    }
    permissions[event.target.name] = event.target.checked;
    this.updatePermissions(uuid, permissions);
  };

  render_permission(permisos, uuid, index) {
    if (permisos) {
      permisos = JSON.parse(permisos);
      return (
        <>
          {/*
                    <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-normal text-center">
                        <input type="checkbox" name="buscador" onChange={(e) => { this.handleCheck(e, uuid, permisos) }} defaultChecked={permisos.buscador} />
                    </td>
                    */}
          <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-normal text-center">
            <input
              type="checkbox"
              name="subir"
              onChange={e => {
                this.handleCheck(e, uuid, permisos);
              }}
              defaultChecked={permisos.subir}
            />
          </td>
          <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap text-center">
            <input
              type="checkbox"
              name="editor_glosas"
              onChange={e => {
                this.handleCheck(e, uuid, permisos);
              }}
              defaultChecked={permisos.editor_glosas}
            />
          </td>

          <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap text-center">
            <input
              type="checkbox"
              name="cargar"
              onChange={e => {
                this.handleCheck(e, uuid, permisos);
              }}
              defaultChecked={permisos.cargar}
            />
          </td>

          <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap text-center">
            <input
              type="checkbox"
              name="descargar"
              onChange={e => {
                this.handleCheck(e, uuid, permisos);
              }}
              defaultChecked={permisos.descargar}
            />
          </td>

          <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap text-center">
            <input
              type="checkbox"
              name="dev"
              onChange={e => {
                this.handleCheck(e, uuid, permisos);
              }}
              defaultChecked={permisos.dev}
            />
          </td>
        </>
      );
    } else {
      return (
        <>
          {/*
                    <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-normal text-center">
                        <input type="checkbox" name="buscador" onChange={(e) => { this.handleCheck(e, uuid) }} defaultChecked={false} />
                    </td>
                    */}
          <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-normal text-center">
            <input
              type="checkbox"
              name="subir"
              onChange={e => {
                this.handleCheck(e, uuid);
              }}
              defaultChecked={false}
            />
          </td>
          <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap text-center">
            <input
              type="checkbox"
              name="editor_glosas"
              onChange={e => {
                this.handleCheck(e, uuid);
              }}
              defaultChecked={false}
            />
          </td>

          <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap text-center">
            <input
              type="checkbox"
              name="cargar"
              onChange={e => {
                this.handleCheck(e, uuid);
              }}
              defaultChecked={false}
            />
          </td>

          <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap text-center">
            <input
              type="checkbox"
              name="descargar"
              onChange={e => {
                this.handleCheck(e, uuid);
              }}
              defaultChecked={false}
            />
          </td>

          <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap text-center">
            <input
              type="checkbox"
              name="dev"
              onChange={e => {
                this.handleCheck(e, uuid);
              }}
              defaultChecked={false}
            />
          </td>
        </>
      );
    }
  }

  render() {
    const { t } = this.props;
    return (
      <div className="w-full p-6  overflow-auto">
        {this.state.showLoading ? (
          <LoadingScreen title="Guardando..." message="Espere un momento" />
        ) : null}

        <div className="flex flex-wrap bg-white">
          <div className="flex items-center justify-end w-full sm:px-6 lg:px-8 py-2">
            <button
              className={`font-bold rounded bg-infokab-gray hover:bg-infokab-gray/90 text-white ${
                this.props.isFontBig
                  ? "text-xl px-5 py-3"
                  : "text-base px-4 py-2"
              }`}
              onClick={this.showModal}
            >
              {t("usuarios.acciones.nuevo_usuarios", "Nuevo Usuario")}
            </button>
          </div>
          <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
            <div className="overflow-hidden">
              <table className="w-full">
                <thead className="bg-slate-900 border-b">
                  <tr>
                    <th
                      scope="col"
                      className={`${
                        this.props.isFontBig ? "text-xl" : "text-base"
                      } font-extrabold text-white px-6 py-4 text-center`}
                    >
                      {t("usuarios.titulos_de_tabla.nombres", "Nombre(s)")}
                    </th>
                    <th
                      scope="col"
                      className={`${
                        this.props.isFontBig ? "text-xl" : "text-base"
                      } font-extrabold text-white px-6 py-4 text-center`}
                    >
                      {t("usuarios.titulos_de_tabla.apellidos", "Apellidos")}
                    </th>
                    <th
                      scope="col"
                      className={`${
                        this.props.isFontBig ? "text-xl" : "text-base"
                      } font-extrabold text-white px-6 py-4 text-center`}
                    >
                      {t(
                        "usuarios.titulos_de_tabla.email",
                        "Correo electrónico"
                      )}
                    </th>
                    {/*}
                                            <th scope="col" className="text-base font-extrabold text-white px-6 py-4 text-center">
                                                Buscador
                                            </th>
                                            */}
                    <th
                      scope="col"
                      className={`${
                        this.props.isFontBig ? "text-xl" : "text-base"
                      } font-extrabold text-white px-6 py-4 text-center`}
                    >
                      {t(
                        "usuarios.titulos_de_tabla.subir_archivos",
                        "Subir archivos"
                      )}
                    </th>
                    <th
                      scope="col"
                      className={`${
                        this.props.isFontBig ? "text-xl" : "text-base"
                      } font-extrabold text-white px-6 py-4 text-center`}
                    >
                      {t(
                        "usuarios.titulos_de_tabla.editar_glosas",
                        "Editar glosas"
                      )}
                    </th>
                    <th
                      scope="col"
                      className={`${
                        this.props.isFontBig ? "text-xl" : "text-base"
                      } font-extrabold text-white px-6 py-4 text-center`}
                    >
                      {t("usuarios.titulos_de_tabla.cargar", "Cargar")}
                    </th>

                    <th
                      scope="col"
                      className={`${
                        this.props.isFontBig ? "text-xl" : "text-base"
                      } font-extrabold text-white px-6 py-4 text-center`}
                    >
                      {t("usuarios.titulos_de_tabla.descargar", "Descargar")}
                    </th>

                    <th
                      scope="col"
                      className={`${
                        this.props.isFontBig ? "text-xl" : "text-base"
                      } font-extrabold text-white px-6 py-4 text-center`}
                    >
                      {t("usuarios.titulos_de_tabla.dev", "Dev")}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.registros.map((registros, index) => (
                    <tr
                      key={index}
                      className={` ${
                        index % 2 === 0
                          ? "bg-white border-b"
                          : "bg-gray-100 border-b"
                      } `}
                    >
                      <td
                        className={`${
                          this.props.isFontBig ? "text-lg" : "text-sm"
                        } text-gray-900 font-light px-6 py-4 whitespace-normal text-center`}
                      >
                        {registros.nombres}
                      </td>
                      <td
                        className={`${
                          this.props.isFontBig ? "text-lg" : "text-sm"
                        } text-gray-900 font-light px-6 py-4 whitespace-normal text-center`}
                      >
                        {registros.apellidos}
                      </td>
                      <td
                        className={`${
                          this.props.isFontBig ? "text-lg" : "text-sm"
                        } text-gray-900 font-light px-6 py-4 whitespace-normal text-center`}
                      >
                        {registros.correo}
                      </td>
                      {this.render_permission(
                        registros.permisos,
                        registros.uuid,
                        index
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {this.renderRegisterUser()}
        {this.renderAlertSuccess()}
      </div>
    );
  }

  renderRegisterUser() {
    const { t } = this.props;
    return (
      <Modal
        show={this.state.modalShow}
        handleClose={this.hideModal}
        //size="w-1/4"
        actions={[this.register]}
        labels={[t("usuarios.mensajes.registrar", "Registrar")]}
        isFontBig={this.props.isFontBig}
      >
        <div className="flex flex-col items-center justify-center mx-auto py-2 h-full">
          <div className="w-full px-4 py-3 h-full max-h-screen overflow-auto">
            <div className="flex flex-col mx-auto h-full">
              <h1
                className={`${
                  this.props.isFontBig ? "text-5xl" : "text-3xl"
                } font-bold pb-3`}
              >
                {" "}
                {t(
                  "usuarios.titulos_de_modal.crear_usuario",
                  "Crear Usuario"
                )}{" "}
              </h1>
              <div className="pb-2 flex flex-col">
                <div className="flex flex-col pb-4 w-full">
                  <label
                    className={`font-medium ${
                      this.props.isFontBig ? "text-2xl" : "text-lg"
                    } tracking-wide pb-2`}
                    htmlFor="name"
                  >
                    {t("usuarios.titulos_de_modal.nombre", "Nombre")}*
                  </label>
                  <input
                    className={`${
                      this.state.errorName ? "" : "mb-3"
                    } appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white`}
                    type="text"
                    name="name"
                    id="name"
                    required
                  />
                  {this.state.errorName ? (
                    <p
                      className={`text-red-500 ${
                        this.props.isFontBig ? "text-base" : "text-xs"
                      } italic mb-2`}
                    >
                      {t(
                        "usuarios.mensajes.ingrese_un_nombre",
                        "Escriba un nombre."
                      )}
                    </p>
                  ) : null}
                </div>
                <div className="flex flex-col pb-4 w-full">
                  <label
                    className={`font-medium ${
                      this.props.isFontBig ? "text-2xl" : "text-lg"
                    } tracking-wide pb-2`}
                    htmlFor="lastName"
                  >
                    {t("usuarios.titulos_de_modal.apellido", "Apellido")}*
                  </label>
                  <input
                    className={`${
                      this.state.errorLastName ? "" : "mb-3"
                    } appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white`}
                    type="text"
                    name="lastName"
                    id="lastName"
                  />
                  {this.state.errorLastName ? (
                    <p
                      className={`text-red-500 ${
                        this.props.isFontBig ? "text-base" : "text-xs"
                      } italic mb-2`}
                    >
                      {t(
                        "usuarios.mensajes.ingrese_un_apellido",
                        "Escriba un apellido."
                      )}
                    </p>
                  ) : null}
                </div>
                <div className="flex flex-col pb-4 w-full">
                  <label
                    className={`font-medium ${
                      this.props.isFontBig ? "text-2xl" : "text-lg"
                    } tracking-wide pb-2`}
                    htmlFor="email"
                  >
                    {t("usuarios.titulos_de_modal.email", "Correo electrónico")}
                    *
                  </label>
                  <input
                    className={`${
                      this.state.errorEmail || this.state.errorValidateEmail
                        ? ""
                        : "mb-3"
                    } appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white`}
                    type={"email"}
                    name="email"
                    id="email"
                  />
                  {this.state.errorEmail ? (
                    <p
                      className={`text-red-500 ${
                        this.props.isFontBig ? "text-base" : "text-xs"
                      } italic mb-2`}
                    >
                      {t(
                        "usuarios.mensajes.ingrese_un_correo",
                        "Ingrese un correo."
                      )}
                    </p>
                  ) : null}
                  {this.state.errorValidateEmail ? (
                    <p
                      className={`text-red-500 ${
                        this.props.isFontBig ? "text-base" : "text-xs"
                      } italic mb-2`}
                    >
                      {t(
                        "usuarios.mensajes.ingrese_un_correo_valido",
                        "Ingrese un correo valido."
                      )}
                    </p>
                  ) : null}
                </div>
                <div className="flex flex-col pb-4 w-full">
                  <label
                    className={`font-medium ${
                      this.props.isFontBig ? "text-2xl" : "text-lg"
                    } tracking-wide pb-2`}
                    htmlFor="password"
                  >
                    {t("usuarios.titulos_de_modal.contrasena", "Contraseña")}*
                  </label>
                  <input
                    className={`${
                      this.state.errorPassword ? "" : "mb-3"
                    } appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white`}
                    type={"password"}
                    name="password"
                    id="password"
                  />
                  {this.state.errorPassword ? (
                    <p
                      className={`text-red-500 ${
                        this.props.isFontBig ? "text-base" : "text-xs"
                      } italic mb-2`}
                    >
                      {t(
                        "usuarios.mensajes.ingrese_una_contrasena",
                        "Escriba una contraseña."
                      )}
                    </p>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }

  renderAlertSuccess() {
    const { t } = this.props;
    return (
      <>
        <Modal
          show={this.state.modalAlertShow}
          handleClose={this.hideAlertSuccess}
          actions={[]}
          labels={[]}
          labelClose={t("usuarios.mensajes.aceptar", "Aceptar")}
          isSuccess={this.state.isSuccessModalAlert}
        >
          <div className="flex">
            <div>
              {this.state.isSuccessModalAlert ? (
                <svg
                  className="w-6 h-6 fill-current text-green-500"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                >
                  <path d="M0 0h24v24H0V0z" fill="none" />
                  <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm4.59-12.42L10 14.17l-2.59-2.58L6 13l4 4 8-8z" />
                </svg>
              ) : (
                <svg
                  className="w-6 h-6 fill-current text-red-500"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                >
                  <path d="M0 0h24v24H0V0z" fill="none" />
                  <path d="M12 5.99L19.53 19H4.47L12 5.99M12 2L1 21h22L12 2zm1 14h-2v2h2v-2zm0-6h-2v4h2v-4z" />
                </svg>
              )}
            </div>

            <div className="ml-3">
              <h2 className="font-semibold text-gray-800">
                {this.state.textModalAlert}
              </h2>
            </div>
          </div>
        </Modal>
      </>
    );
  }
}

export default withTranslation()(UsersList);

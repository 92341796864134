import React from "react";
import { PieChart, Pie, Legend, Cell, ResponsiveContainer } from "recharts";

const PieChartComponent = EntryData => {
  console.log(EntryData.libre);
  const totalCapacity = EntryData.totalCapacity;
  const recordedHours = EntryData.horas;
  const data = [
    {
      name: "Espacio Libre: " + EntryData.libre + " GB",
      value: EntryData.libre
    },
    {
      name: "Espacio Utilizado: " + EntryData.utilizado + " GB",
      value: EntryData.utilizado
    }
  ];

  const COLORS = ["#6b7280", "#266A36"];

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  return (
    <div className="h-96" style={{width:"100%"}} >
      <ResponsiveContainer >
        <PieChart width={400} height={400}>
          <Legend
            layout="horizontal"
            verticalAlign="top"
            align="center"
            wrapperStyle={{
              fontSize: EntryData.isFontBig ? "1.25rem" : "1rem"
            }}
          />
          <Pie data={data}
            cx="50%"
            cy="50%"
            labelLine={false}
            label={renderCustomizedLabel}
            outerRadius="100%"
            fill="#8884d8"
            dataKey="value">
            {data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>

      <h1
        className={`flex item-center justify-center font-medium ${EntryData.isFontBig ? "text-xl" : "text-base"
          }`}
      >
        Capacidad total: {totalCapacity} GB
      </h1>

      <h1
        className={`flex item-center justify-center font-medium ${EntryData.isFontBig ? "text-xl" : "text-base"
          }`}
      >
        Horas grabadas: {recordedHours}
      </h1>
    </div>
  );
};
export default PieChartComponent;

import React from "react";
import { useTranslation } from "react-i18next";

const ModalMultimedia = ({
  handleClose,
  show,
  size,
  actions,
  labels,
  children,
  labelClose,
  isSuccess
}) => {
  const { t } = useTranslation();
  return (
    <div
      className={`fixed top-0 left-0 w-full h-full z-50 bg-gray-800/90 ${
        show ? "flex" : "hidden"
      }`}
    >
      <section
        className={`fixed ${size} h-full top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 p-4 flex flex-col rounded-md bg-transparent`}
      >
        {children}
        <div className="flex flex-wrap justify-center content-center h-1/4">
          <button
            type="button"
            className={`text-white-500 background-transparent bg-infokab-gray hover:bg-infokab-gray/90 text-white font-bold px-4 py-2 rounded shadow mb-2 ml-2`}
            onClick={handleClose}
          >
            {labelClose ? (
              <>{labelClose}</>
            ) : (
              t("proyectos.acciones.cerrar", "Cerrar")
            )}
          </button>
        </div>
      </section>
    </div>
  );
};

export default ModalMultimedia;

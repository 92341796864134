/* eslint-disable no-undef */
import React, { useState, useRef, useEffect } from 'react'
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import "./AudioPlayer.css"
import { useTranslation } from "react-i18next";

//const AudioPlayer = ({ short, original = null, start = null, end = null, action}) => {
const AudioPlayer = React.forwardRef(({
  short,
  original = null,
  start = null,
  end = null,
  index,
  id_proyecto,
  action,
  initTime,
  pauseTime,
  isFontBig,
  showButton = true,
  subtitle,
}, ref) => {
  const { t } = useTranslation();
  // state
  const [isPlaying, setIsPlaying] = useState(false);
  const [originalAudio, setOriginalAudio] = useState(showButton);
  const [subtitleMaya, setSubtitleMaya] = useState(true);
  const [subtitleSpanish, setSubtitleSpanish] = useState(false);
  const [shortStart, setShortStart] = useState(start);
  const [shortEnd, setShortEnd] = useState(end);
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [message, setMessage] = useState("Cambiar al audio completo")
  const [audio_path, setAudioPath] = useState(short);

  // referencias
  //let audioPlayer = useRef(new Audio(url));
  let audioPlayer = useRef();
  let progressBar = useRef();
  let animationRef = useRef();

  React.useImperativeHandle(ref, () => ({
    changeTime: (timeJump) => {
      timeTravel(timeJump);
    },

    getCurrentTime: () => {
      let x = progressBar.current.value;
      return x;
    }

  }));

  useEffect(() => {
    audioPlayer.current.addEventListener('loadedmetadata', (e) => {
      const seconds = e.target.duration;
      setDuration(seconds);
      progressBar.current.max = seconds;
      if (!showButton) {
        timeTravel(shortStart)

      }

    });

  }, [audioPlayer?.current?.loadedmetadata, audioPlayer?.current?.readyState]);

  useEffect(() => {
    audioPlayer.current.addEventListener('ended', () => {
      setIsPlaying(false);
      //togglePlayPause();
    });

  }, []);


  const calculateTime = (secs) => {
    /*let totalSeconds = secs;
    totalSeconds %= 3600;
    let minutes = Math.floor(totalSeconds / 60);
    let seconds = Math.round(totalSeconds % 60);

    minutes = String(minutes).padStart(1, "0");
    seconds = String(seconds).padStart(2, "0");

    return `${minutes}:${seconds}`;*/

    let hours = Math.floor(secs / 3600);
    let minutes = Math.floor((secs % 3600) / 60);
    let remainingSeconds = Math.ceil(secs % 60);

    let formattedHours = hours.toString().padStart(1, '0');
    let formattedMinutes = minutes.toString().padStart(2, '0');
    let formattedSeconds = remainingSeconds.toString().padStart(2, '0');

    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  }



  const togglePlayPause = () => {
    const prevValue = isPlaying;
    setIsPlaying(!prevValue);
    if (!prevValue) {
      audioPlayer.current.play();
      initTime();
      animationRef.current = requestAnimationFrame(whilePlaying)
    } else {
      pauseTime();
      audioPlayer.current.pause();
      cancelAnimationFrame(animationRef.current);
    }
  }

  const whilePlaying = () => {
    try {
      progressBar.current.value = audioPlayer.current.currentTime;
      changePlayerCurrentTime();
      animationRef.current = requestAnimationFrame(whilePlaying);
    } catch (e) {
      setIsPlaying(false)
      //audioPlayer.current.pause();
    }

  }

  const changeRange = () => {
    audioPlayer.current.currentTime = progressBar.current.value;
    changePlayerCurrentTime();
  }

  const changePlayerCurrentTime = () => {
    progressBar.current.style.setProperty('--seek-before-width', `${progressBar.current.value / duration * 100}%`)
    setCurrentTime(progressBar.current.value);
    if (progressBar.current.value === duration.toFixed(3)) {
      setIsPlaying(false)
    }

  }

  const changeAudio = () => {
    setIsPlaying(false)
    audioPlayer.current.pause();
    cancelAnimationFrame(animationRef.current);
    timeTravel(0)
    setOriginalAudio(true);
    /*if (!originalAudio) {
      setOriginalAudio(true);
      setAudioPath(original);
      audioPlayer.current.load();
      setShortStart(start);
      setShortEnd(end);
      timeTravel(0)
      audioPlayer.current.addEventListener('loadedmetadata', (e) => {
        setDuration(e.target.duration);
      });
      setIsPlaying(false)
      setMessage("Cambiar al audio corto")
      // eslint-disable-next-line no-unused-expressions
    }
    else {
      setOriginalAudio(false);
      /*setAudioPath(short)
      audioPlayer.current.load();
      //setShortStart(null);
      //setShortEnd(null);
      timeTravel(0)
      audioPlayer.current.addEventListener('loadedmetadata', (e) => {
        setDuration(e.target.duration);
      });
      setIsPlaying(false)
      setMessage("Cambiar al audio completo")
    }*/
  };

  const timeTravel = (timeJump) => {
    progressBar.current.value = timeJump;
    changeRange();
  }


  return (
    <div className="flex flex-col bg-gray-200 pl-2 pr-2 w-full">
      <div className="flex w-full items-center pt-2">
        <div className="flex items-center justify-center rounded-full">
          {/*<button onClick={backThirty}>- 30</button>

            <button onClick={forwardThirty}> + 30</button>*/}
        </div>

        <div className="flex relative flex-grow items-center justify-center mr-4 pt-2">
          <audio ref={audioPlayer} src={audio_path} preload="metadata" />
          <input
            type="range"
            min={0}
            step={0.001}
            defaultValue="0"
            ref={progressBar}
            onChange={changeRange}
            className="w-full h-2 absolute accent-black rounded-lg cursor-pointer dark:bg-gray-700"
            //className="progressBar"
          />

          {!showButton ? (
            <>
              <div
                className="chapter w-full h-1 absolute"
                style={{
                  "--left": `${(shortStart / duration) * 100}%`,
                }}
              ></div>
              <div
                className="chapter w-full h-1 absolute"
                style={{
                  "--left": `${(shortEnd / duration) * 100}%`,
                }}
              ></div>
            </>
          ) : null}

          {!showButton ? (
            <>
              <div
                className="chapter w-full h-1 absolute"
                style={{
                  "--left": `${(shortStart / duration) * 100}%`,
                  "--width": `${((shortEnd - shortStart) / duration) * 100}%`,
                }}
              ></div>
            </>
          ) : null}
        </div>
      </div>

      <div class="flex w-full px-4 py-2">
        <div class="flex flex-wrap w-1/3 justify-start">
          <div className="flex items-center justify-center px-1">
            <div className="flex items-center justify-center px-1">
              <div
                className={`flex-none ${
                  isFontBig
                    ? "text-sm xs:text-sm sm:text-base md:text-lg"
                    : "text-xs xs:text-xs sm:text-sm md:text-md"
                }`}
              >
                {calculateTime(currentTime)} /{" "}
                {duration && !isNaN(duration) && calculateTime(duration)}
              </div>
            </div>
          </div>
        </div>
        <div class="flex flex-wrap w-1/3 justify-center">
          <button className="text-black font-bold" onClick={togglePlayPause}>
            {isPlaying ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="#000"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-4 lg:w-5 h-4 lg:h-5"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M15.75 5.25v13.5m-7.5-13.5v13.5"
                />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="#000"
                viewBox="0 0 24 24"
                strokeWidth="3"
                stroke="currentColor"
                className="w-4 lg:w-5 h-4 lg:h-5"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.348a1.125 1.125 0 010 1.971l-11.54 6.347a1.125 1.125 0 01-1.667-.985V5.653z"
                />
              </svg>
            )}
          </button>
        </div>
        <div class="flex flex-wrap w-1/3 justify-end">
          {originalAudio ? (
          <>
            <div className="flex items-center justify-center">
              <button
              title={ t(
                    "buscador.acciones.ver_audio_completo",
                    "Ver audio completo"
                  )}
                className="justify-center"
                onClick={() => {
                  changeAudio();
                  // eslint-disable-next-line no-unused-expressions
                  action(index, id_proyecto, original, start, end);
                }}
              >
                <span className="">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 lg:h-5 w-4 lg:w-5 mx-auto"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
                    />
                  </svg>
                </span>
              </button>
            </div>
          </>
        ) : null}

        {!originalAudio ? (
          <>
            <div className="flex items-center justify-center">
              <button
                className={`mr-2 flex-1 px-4 py-2 text-white text-sm font-medium rounded-md ${subtitleMaya ? 'bg-cdmx-green hover:bg-cdmx-green/90': 'bg-infokab-gray hover:bg-infokab-gray/90'}`}
                title={ t(
                    "buscador.acciones.ver_audio_completo",
                    "Ver audio completo"
                  )}
                onClick={() => {
                  subtitle('maya');
                  setSubtitleMaya(!subtitleMaya);
                }}
              >
                <span className="">
                  Maya
                </span>
              </button>
            </div>

            <div className="flex items-center justify-center">
              <button
                className={`flex-1 px-4 py-2 text-white text-sm font-medium rounded-md ${subtitleSpanish ? 'bg-cdmx-green hover:bg-cdmx-green/90': 'bg-infokab-gray hover:bg-infokab-gray/90'}`}
                title={ t(
                    "buscador.acciones.ver_audio_completo",
                    "Ver audio completo"
                  )}
                onClick={() => {
                  subtitle('spanish');
                  setSubtitleSpanish(!subtitleSpanish);
                }}
              >
                <span className="">
                  Español
                </span>
              </button>
            </div>
          </>
        ) : null}

        </div>
      </div>
    </div>
  );
});

export default AudioPlayer;

import React from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import { useState } from "react";
import Login from "./Login";
import Search from "./Search";
import SearchMap from "./SearchMap";
import GuardedRoute from "./GuardedRoute";
import Home from "./Home";
import AdminPanel from "./AdminPanel";
import { useTranslation } from "react-i18next";
import Download from "./Download";
import I18nextBrowserLanguageDetector from "i18next-browser-languagedetector";

function Header(props) {
  const { t, i18n } = useTranslation("translations");
  let select_language = i18n.language;
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    select_language = lng;
  };

  const [navbar, setNavbar] = useState(false);

  const verifyAuthenticated = () => {
    let uuid = localStorage.getItem("uuid");

    if (uuid != null) {
      return true;
    } else {
      return false;
    }
  };

  function onAuthChange() {
    setIsAuthenticated(verifyAuthenticated());
    setHasPermissions(Permited());
  }

  const Permited = () => {
    let permissions = JSON.parse(localStorage.getItem("permisos"));

    if (permissions === null) {
      return false;
    } else {
      const onePermission = Object.values(permissions).some((el) => el >= true);
      return onePermission;
    }
  };

  function clearStorage() {
    console.log("clear");
    let i18nextLng = localStorage.getItem("i18nextLng");
    localStorage.clear();
    setIsAuthenticated(false);
    localStorage.setItem("i18nextLng", i18nextLng);
    this.props.onAuthChange();
  }

  /*useEffect(() => {
    setPermissions(JSON.parse(localStorage.getItem("permisos")));
  }, []);*/

  //console.log({ permissions })

  const [isAuthenticated, setIsAuthenticated] = useState(verifyAuthenticated());
  const [hasPermissions, setHasPermissions] = useState(Permited());

  /* const contextMenu = document.querySelector(".context-wrapper");
  document.addEventListener("click", () => {
    if (contextMenu != null) {
      contextMenu.style.visibility = "hidden";
    }
  }); */

  return (
    <div className="bg-white ">
      <div className="absolute top-8 left-10 z-[100]">
        <div className={`flex item-center flex-wrap justify-center ${props.isFontBig ? "text-lg" : "text-base"}`}>
          <div>
            <button
              className="px-2 text-white hover:text-gray-800  hover:border-gray-100"
              type="button"
              onClick={() => changeLanguage("en")}
            >
              <span
                className={`${select_language === "en" ? "underline" : ""}`}
              >
                en
              </span>
            </button>
          </div>
          <div className="underline">
            <button
              className="px-2 text-white hover:text-gray-800  hover:border-gray-100"
              type="button"
              onClick={() => changeLanguage("es")}
            >
              <span
                className={`${select_language === "es" ? "underline" : ""}`}
              >
                es
              </span>
            </button>
          </div>
          <div className="underline">
            <button
              className="px-2 text-white hover:text-gray-800  hover:border-gray-100"
              type="button"
              onClick={() => changeLanguage("maya")}
            >
              <span
                className={`${select_language === "maya" ? "underline" : ""}`}
              >
                maya
              </span>
            </button>
          </div>
        </div>
      </div>

      <nav className="fixed flex items-center w-full justify-between bg-cdmx-green h-20 z-50">
        <div className="text-white text-4xl font-bold tracking-wide pl-3 py-4 select-none">
          {/* <Link to="/">Infokaab</Link>
        <div className="text-xs flex item-center flex-wrap justify-center">
            <div>
            <button
                className="px-2 text-white hover:bg-cdmx-green hover:text-gray-800  hover:border-gray-100"
                type="button"
                onClick={() => changeLanguage("en")}
            >
                <span
                className={`${select_language === "en" ? "underline" : ""}`}
                >
                en
                </span>
            </button>
            </div>
            <div className="underline">
            <button
                className="px-2 text-white hover:bg-cdmx-green hover:text-gray-800  hover:border-gray-100"
                type="button"
                onClick={() => changeLanguage("es")}
            >
                <span
                className={`${select_language === "es" ? "underline" : ""}`}
                >
                es
                </span>
            </button>
            </div>
            <div className="underline">
            <button
                className="px-2 text-white hover:bg-cdmx-green hover:text-gray-800  hover:border-gray-100"
                type="button"
                onClick={() => changeLanguage("maya")}
            >
                <span
                className={`${
                    select_language === "maya" ? "underline" : ""
                }`}
                >
                maya
                </span>
            </button>
            </div>
        </div> */}
        </div>
        <div className="self-stretch">
          <div className="md:hidden flex items-center h-full">
            <button
              className="p-2 text-white rounded-md outline-none"
              onClick={() => setNavbar(!navbar)}
            >
              {navbar ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-6 h-6 fill-current"
                >
                  <path d="M6.4 19 5 17.6l5.6-5.6L5 6.4 6.4 5l5.6 5.6L17.6 5 19 6.4 13.4 12l5.6 5.6-1.4 1.4-5.6-5.6Z" />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-6 h-6 fill-current"
                >
                  <path d="M3 18v-2h18v2Zm0-5v-2h18v2Zm0-5V6h18v2Z" />
                </svg>
              )}
            </button>
          </div>
          <ul className={`hidden md:flex font-medium h-full select-none ${props.isFontBig ? "text-2xl" : "text-lg"}`}>
            {isAuthenticated ? (
              <li
                className={
                  "flex items-center  cursor-pointer text-white hover:bg-cdmx-green hover:text-gray-800 "
                }
              >
                <Link
                  className="flex items-center justify-center h-full px-4"
                  onClick={clearStorage}
                  to="/"
                >
                  {t("menu_de_arriba.logout", "Cerrar sesión")}
                </Link>
              </li>
            ) : (
              <li
                className={
                  "flex items-center cursor-pointer text-white hover:bg-cdmx-green hover:text-gray-800"
                }
              >
                <Link
                  className="flex items-center justify-center h-full px-4"
                  to="/login"
                >
                  {t("menu_de_arriba.login", "Iniciar sesión")}
                </Link>
              </li>
            )}

            <li className="flex items-center cursor-pointer text-white hover:bg-cdmx-green hover:text-gray-800 ">
              <Link
                className="flex items-center justify-center h-full px-4"
                to="/"
              >
                {t("menu_de_arriba.buscador", "Buscador")}
              </Link>
            </li>
          </ul>
        </div>
      </nav>

      <ul
        className={`md:hidden absolute bg-white z-50 right-0 top-20 flex-col rounded shadow-md font-medium text-lg tracking-wide ${navbar ? "flex" : "hidden"
          }`}
      >
        <li className={"hidden"}>
          <Link className="px-4 py-2 grow" to="/login">
            Iniciar sesión
          </Link>
        </li>
        <li className="flex">
          <Link className="px-4 py-2 grow" to="/">
            Buscador
          </Link>
        </li>
        <li className="flex">
          <Link className="px-4 py-2 grow" to="/projects">
            Proyectos
          </Link>
        </li>
        <li className="flex">
          <Link className="px-4 py-2 grow" to="/uploadForm">
            Subir
          </Link>
        </li>
        <li className="flex">
          <Link className="px-4 py-2 grow" to="/dictionary">
            Diccionarios
          </Link>
        </li>
        <hr></hr>
        <li className="flex">
          <Link className="px-4 py-2 grow" to="/userPermissions">
            Usuarios
          </Link>
        </li>

        <li className="flex">
          <Link className="px-4 py-2 grow" to="/indices">
            Índices
          </Link>
        </li>

        <li className="flex">
          <Link className="px-4 py-2 grow" to="/storage">
            Almacenamiento
          </Link>
        </li>
      </ul>
    </div>
  );
}

export default Header;

import React from "react";
import { useTranslation } from "react-i18next";

const Modal = ({
  handleClose,
  show,
  size,
  actions,
  labels,
  children,
  labelClose,
  isSuccess,
  isFontBig
}) => {
  const { t } = useTranslation();
  return (
    <div
      className={`fixed top-0 left-0 w-full h-full z-50 bg-gray-800/60 ${
        show ? "flex" : "hidden"
      }`}
    >
      <section
        className={`fixed bg-white ${size} h-auto top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 p-4 flex flex-col rounded-md`}
      >
        {children}
        <div className="self-end space-x-2">
          {actions
            ? actions.map((action, index) => {
                return (
                  <button
                    key={index}
                    type="button"
                    className={`${
                      isFontBig ? "text-xl" : "text-base"
                    } bg-infokab-gray hover:bg-infokab-gray/90 text-white font-bold px-4 py-2 rounded shadow`}
                    onClick={action}
                  >
                    {labels[index]}
                  </button>
                );
              })
            : null}

          <button
            type="button"
            className={`${
              isSuccess
                ? "bg-green-500 hover:bg-green-600"
                : "text-red-500 background-transparent"
            } font-bold uppercase px-6 py-2 ${
              isFontBig ? "text-lg" : "text-sm"
            } outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150`}
            onClick={handleClose}
          >
            {labelClose ? (
              <>{labelClose}</>
            ) : (
              t("proyectos.acciones.cerrar", "Cerrar")
            )}
          </button>
        </div>
      </section>
    </div>
  );
};

export default Modal;

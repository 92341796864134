import * as React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";

function DownloadList() {

    const [files, setFiles] = useState([]);

    const fetchDownloadList = () => {
        let payload = {};
        fetch("../api/download/list", {
          method: "POST",
          body: JSON.stringify(payload),
          headers: {
            "Content-Type": "application/json"
          }
        })
        .then(response => {
            response.json().then(data => {
                setFiles(data);
            });
        })
        .catch(error => {
        console.log(error);
        });
    }

    fetchDownloadList();

    return (
        <div className="relative flex w-full px-4 py-3 h-full bg-cdmx-gray items-center justify-center">
            <div className="bg-white w-3/6 py-8 rounded shadow-lg flex flex-col items-center justify-center">
                <ul>
                {
                        files.map((file, index) => {
                            let fileName = file.split(".")[0];
                            return (
                                <li key={index} className="text-cdmx-green">
                                    <Link to={`../../download/${fileName}`}>
                                        {file}
                                    </Link>
                                </li>
                            )
                        }
                )}
                </ul>
            </div>
        </div>
    );
}

export default DownloadList;
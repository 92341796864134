/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import {
  useMap,
  MapContainer,
  TileLayer,
  Circle,
  useMapEvents,
  Marker
} from "react-leaflet";
import "./uploadForm.css";
import "leaflet/dist/leaflet.css";
import * as turf from "@turf/turf";
import LoadingScreen from "./LoadingScreen";
import { withTranslation } from "react-i18next";
import ImageModal from "./ImageModal";
import DragNDrop from "./DragNDrop";
import L from "leaflet";
import iconMarker from "leaflet/dist/images/marker-icon.png";
import iconRetina from "leaflet/dist/images/marker-icon-2x.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";

class UploadForm extends React.Component {
  constructor(props) {
    super(props);

    const { t } = props;
    this.state = {
      showModal: false,
      formFields: [{ nombre: "", edad: "", sexo: "" }],
      message: "Se han enviado los datos",
      multimedia: "",
      eaf: "",
      autorizacion: "",
      center: {
        lat: 19.552025697517507,
        lng: -89.29412841796875
      },
      position: [51.505, -0.09],
      mapPoints: [],
      lineEnd: [],
      currentPos: null,
      blackOptions: {
        fillOpacity: 0,
        weight: 8,
        color: "grey",
        dashArray: "80",
        className: "borderCircleRegion",
        title: "secundaryCircle"
      },
      radius: 0,
      extendRadius: 0,
      fillBlueOptions: {
        fillColor: "grey",
        weight: 6,
        color: "grey",
        dashArray: "80",
        className: "circleRegion",
        title: "mainCircle"
      },
      timeOver: false,
      renderCircle: false,
      renderLine: false,
      circleBounds: null,
      uuid: localStorage.getItem("uuid"),
      projectName: "",
      multimediaName: "",
      eafName: "",
      showLoading: false,
      images: [],
      EnableMapEvents: true,
      //linkMessage: "Pegue aquí el enlace",
      linkMessage: t(
        "subir_archivos.descripciones.ubicacion",
        "Pegue aquí el enlace"
      ),
      colorlinkMessage: "black",
      showModalImage: false,
      centerMap: true,
      mimeType: null,
      accept: null,
      localidad_cvegeo: null,
      localidad_nombre: null,
      errors: []
    };
  }

  checkForm() {
    const { t } = this.props;
    let errores = [];
    let partDate = this.state.date.split("-");
    let newdate = partDate[2] + partDate[1] + partDate[0];

    let multimediaName = this.state.multimediaName.replace(/\.[^/.]+$/, "");
    let eafName = this.state.eafName.replace(/\.[^/.]+$/, "");
    if (multimediaName !== "" && eafName !== "") {
      if (multimediaName === eafName) {
        let partes = multimediaName.split("_");

        if (partes.length === 8) {
          if (partes[2] === newdate) {
            errores = [];
          } else {
            errores.push(
              t(
                "subir_archivos.mensajes.fecha_no_coincide",
                "No coincide la fecha"
              ) + "\n"
            );
          }
          if (
            parseInt(partes[6]) === this.state.formFields.length &&
            partes[6] >= 1 &&
            partes[6] <= 20
          ) {
            errores = [];
            for (let i = 0; i < this.state.formFields.length; i++) {
              if (
                this.state.formFields[i].nombre === "" ||
                this.state.formFields[i].edad === "" ||
                this.state.formFields[i].sexo === ""
              ) {
                errores.push(
                  t(
                    "subir_archivos.mensajes.datos_incompletos_de_hablantes",
                    "No se han completado los datos de los hablantes"
                  ) + "\n"
                );
              }
            }
            if (!this.state.mapPoints[0] || this.state.mapPoints[0].length === 0) {
              errores.push(
                t(
                  "subir_archivos.mensajes.no_hay_datos_en_mapa",
                  "No ha seleccionado un área en el mapa"
                ) + "\n"
              );
            }
            if (this.state.localidad_cvegeo === null || this.state.localidad_nombre === null) {
              errores.push(
                t(
                  "subir_archivos.mensajes.no_coinciden_municipios",
                  "No coincide el municipio con la localidad"
                ) + "\n"
              );
            }
          } else {
            errores.push(
              t(
                "subir_archivos.mensajes.no_coinciden_el_numero_de_hablantes",
                "No coincide el número de hablantes"
              ) + "\n"
            );
          }
        } else {
          errores.push(
            t(
              "subir_archivos.mensajes.no_tiene_el_formato",
              "El nombre no cumple con el formato: 04_02_01092022_11_SCY_E_2_1"
            ) + "\n"
          );
        }
      } else {
        errores.push(
          t(
            "subir_archivos.mensajes.formatos_no_coinciden",
            "No coinciden los nombres de los archivos"
          ) + "\n"
        );
      }
    } else {
      errores.push(
        t(
          "subir_archivos.mensajes.campos_eaf_multimedia_vacios",
          "Los campos de Multimedia o Archivo de anotiación estan vacios"
        ) + "\n"
      );
    }
    return errores;
  }

  handleReset = () => {
    Array.from(document.querySelectorAll("input")).forEach(
      input => (input.value = "")
    );
  };

  submit = async e => {
    const { t } = this.props;
    e.preventDefault();

    if (this.checkForm().length === 0) {
      this.setState({ showLoading: true });
      const formData = new FormData();
      formData.append("uuid", this.state.uuid);
      formData.append("projectName", this.state.projectName);
      formData.append("date", this.state.date);
      formData.append("multimedia", this.state.multimedia);
      formData.append("eaf", this.state.eaf);
      formData.append("autorizacion", this.state.autorizacion);
      formData.append("hablantes", JSON.stringify(this.state.formFields));
      formData.append("ubicacion", this.state.mapPoints[0]);
      formData.append("radio", this.state.radius);
      formData.append("circleBounds", JSON.stringify(this.state.circleBounds));
      formData.append("mimeType", this.state.mimeType);
      formData.append("localidad_nombre", this.state.localidad_nombre);
      formData.append("localidad_cvegeo", this.state.localidad_cvegeo);

      for (let key of Object.keys(this.state.images)) {
        if (key !== "length") {
          formData.append("images", this.state.images[key]);
        }
      }
      //formData.append("images", )

      for (const pair of formData.entries()) {
        console.log(`${pair[0]}, ${pair[1]}`);
      }

      const requestOptions = {
        method: "POST",
        body: formData
      };

      fetch("../api/upload/new", requestOptions)
        .then(data => {
          console.log(data);
          if (data.status === 200) {
            this.handleReset();
            this.setState(
              {
                message: "Los datos se han envidado correctamente",
                formFields: [{ nombre: "", edad: "", sexo: "" }],
                date: "",
                multimedia: "",
                eaf: "",
                autorizacion: "",
                mapPoints: [],
                lineEnd: [],
                currentPos: null,
                radius: 0,
                timeOver: false,
                renderCircle: false,
                renderLine: false,
                multimediaName: "",
                eafName: "",
                extendRadius: 0,
                circleBounds: {},
                projectName: "",
                images: [],
                EnableMapEvents: true,
                //linkMessage: "Pegue aquí el enlace",
                linkMessage: t(
                  "subir_archivos.descripciones.ubicacion",
                  "Pegue aquí el enlace"
                ),
                colorlinkMessage: "black",
                showModalImage: false,
                mimeType: null,
                accept: null,
                localidad_cvegeo: null,
                localidad_nombre: null,
              },
              () => {
                this.setState({
                  showLoading: false,
                  showModal: true,
                  centerMap: true
                });
              }
            );
            this.myFormRef.reset();
            this.fechas();
          } else {
            this.setState(
              {
                message: "Ocurrió un error intente nuevamente"
              },
              () => {
                this.setState({
                  showLoading: false,
                  showModal: true
                });
              }
            );
          }
        })
        .catch(error => {
          this.setState(
            {
              message: "Ocurrió un error intente nuevamente " + error
            },
            () => {
              this.setState({
                showLoading: false,
                showModal: true
              });
            }
          );
        });
    } else {
      this.setState(
        {
          message: this.checkForm()[0]
        },
        () => {
          this.setState({ showModal: true });
        }
      );
    }
  };

  handleDateChange = event => {
    this.setState({
      date: event.target.value
    });
  };

  handleFormChange = (event, index) => {
    let data = this.state.formFields;
    data[index][event.target.name] = event.target.value;
    this.setState.formFields = data;
  };

  addFields = () => {
    let actual = this.state.formFields;
    let object = {
      nombre: "",
      edad: "",
      sexo: ""
    };
    actual.push(object);
    this.setState({
      formFields: actual
    });
  };

  removeFields = index => {
    let data = this.state.formFields;
    if (data.length > 1) {
      data.splice(index, 1);
      this.setState({
        formFields: data
      });
    }
  };

  checkNames() {
    const { t } = this.props;
    if (this.state.multimediaName !== "" && this.state.eafName !== "") {
      if (
        this.state.multimediaName.replace(/\.[^/.]+$/, "") ===
        this.state.eafName.replace(/\.[^/.]+$/, "")
      ) {
        this.setState({
          projectName: this.state.multimediaName.replace(/\.[^/.]+$/, "")
        }, () => {
          this.checkLocation();
        });
      } else {
        this.setState(
          {
            message: t(
              "subir_archivos.mensajes.formatos_no_coinciden",
              "No coinciden los nombres de los archivos"
            )
          },
          () => {
            this.setState({
              showLoading: false,
              showModal: true
            });
          }
        );
      }
    }
  }

  handleMultimediaChange = event => {
    console.log(event.target.files[0])
    this.setState(
      {
        multimedia: event.target.files[0],
        multimediaName: event.target.files[0].name
      },
      () => {
        this.checkNames();
      }
    );
  };

  createURL = element => {
    const objectUrl = URL.createObjectURL(element);
    return objectUrl;
  };

  deleteImage = index => {
    let data = this.state.images;
    if (data.length > 0) {
      data.splice(index, 1);
      this.setState({
        images: data
      });
    }
  };

  handleEafChange = async (e) => {
    this.setState({
      mimeType: null,
      errors: []
    })
    var TiersFromXML = new Set();
    var ObjetiveTiers = ['transcripcion literal', 'traduccion libre', 'default-lt'];
    const { t } = this.props;
    e.preventDefault()
    var file = e.target.files[0];
    let mimetipe = null;
    let accept = null;
    const reader = new FileReader()
    reader.onload = async (e) => {
      const text = (e.target.result)

      const parser = new DOMParser();
      const xml = parser.parseFromString(text, 'text/xml')
      const prueba = xml.getElementsByTagName("ANNOTATION_VALUE")
      const TIERS = xml.getElementsByTagName('TIER')

      for (let index = 0; index < TIERS.length; index++) {
        TiersFromXML.add(TIERS[index].getAttribute('LINGUISTIC_TYPE_REF'))
      }
      ObjetiveTiers.sort();
      const sortedTiersFromXML = [...TiersFromXML].sort();

      let checker = (arr, target) => target.every(v => arr.includes(v));
      console.log("checker", checker(ObjetiveTiers, sortedTiersFromXML));
      let arrFaltan = []
      ObjetiveTiers.forEach(element => {
        if (!sortedTiersFromXML.includes(element)) {
          arrFaltan.push(element)
        }
      });
      if (arrFaltan.length > 0) {
        this.setState(
          {
            message:
              "Falta la capa: "
            ,
            errors: arrFaltan
          },
          () => {
            this.setState({
              showLoading: false,
              showModal: true
            });
          }
        );

      }


      const pattern1 = /(&lt;&lt;|&gt;&gt;|<<|>>)/gm;
      let errores = false;
      for (let index = 0; index < prueba.length; index++) {
        if (pattern1.test(prueba[index].textContent)) {
          errores = true;
        }

      }
      if (errores) {
        this.setState(
          {
            message: t(
              "El eaf contiene alguno de los siguientes caracteres NO válidos &lt;&lt;|&gt;&gt;|<<|>>"
            )
          },
          () => {
            this.setState({
              showLoading: false,
              showModal: true
            });
          }
        );
      } else {
        try {
          const mime = xml.querySelector('MEDIA_DESCRIPTOR').getAttribute('MIME_TYPE');
          console.log({ mime })
          if (mime === 'audio/x-wav') {
            mimetipe = 'audio/wav'
            accept = ".wav"
          }
          if (mime === 'video/mp4') {
            mimetipe = 'video/mp4'
            accept = ".mp4"
          }

          this.setState({
            mimeType: mimetipe,
            accept: accept,
            eaf: file,
            eafName: file.name
          }, () => { });
        } catch (error) {
          this.setState(
            {
              message: t(
                "subir_archivos.mensajes.archivo_no_valido",
                "El tipo de archivo no es válido"
              )
            },
            () => {
              this.setState({
                showLoading: false,
                showModal: true
              });
            }
          );
        }
      }


    };
    if (file) {
      reader.readAsText(file)
    }

  }

  handleAuthChange = event => {
    this.setState({
      autorizacion: event.target.files[0]
    });
  };

  handleCloseModalChange = event => {
    this.setState({
      showModal: false
    });
  };

  checkLocation() {
    const { t } = this.props;
    if (this.state.projectName && this.state.mapPoints[0]) {
      console.log('checkLocation')
      const formData = new FormData();
      formData.append("projectName", this.state.projectName);
      formData.append("ubicacion", this.state.mapPoints[0]);

      const requestOptions = {
        method: "POST",
        body: formData
      };

      fetch("../api/upload/validate/location", requestOptions)
        .then((response) => {
          response.json().then((data) => {
            if (response.ok === false) {
              this.setState(
                {
                  message: t(
                    "subir_archivos.mensajes.no_coinciden_municipios",
                    "No coincide el municipio con la localidad"
                  )
                },
                () => {
                  this.setState({
                    showModal: true,
                  });
                }
              );
            } else {
              this.setState({
                localidad_nombre: data[0].localidad_nombre,
                localidad_cvegeo: data[0].localidad_cvegeo
              });
            }

          });
        })
        .catch((error) => {
          console.log({ error });
        });
    }
  }

  handleGoogleLink = event => {
    const { t } = this.props;
    let link = event.target.value;
    let urlreg = /([a-zA-Z]{3}\.)?maps.app.goo.gl(\.\w*)?/;
    const coordsPattern = /([+-]?(?=\.\d|\d)(?:\d+)?(?:\.?\d*))(?:[Ee]([+-]?\d+))?,([+-]?-(?=\.\d|\d)(?:\d+)?(?:\.?\d*))(?:[Ee]([+-]?\d+))?/gm;
    const pattern2 = /3d([+-]?(?=\.\d|\d)(?:\d+)?(?:\.?\d*))(?:[Ee]([+-]?\d+))?!4d([+-]?(?=\.\d|\d)(?:\d+)?(?:\.?\d*))(?:[Ee]([+-]?\d+))?/gm;
    if (urlreg.test(link)) {
      console.log('es short de google')
      let body = {
        link: link,
      };
      const requestOptions = {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
          "Content-Type": "application/json"
        }
      };

      fetch("../api/upload/validate/link", requestOptions)
        .then(response => {
          response.json().then(data => {
            //console.log({ data })
            const pattern3d4d = /3d([+-]?(?=\.\d|\d)(?:\d+)?(?:\.?\d*))(?:[Ee]([+-]?\d+))?!4d([+-]?(?=\.\d|\d)(?:\d+)?(?:\.?\d*))(?:[Ee]([+-]?\d+))?/gm;
            if (pattern3d4d.test(data)) {
              console.log("del back short con 3d y 4d")
              let match = data.match(pattern3d4d);
              let coords = match[0].replace("3d", "").split("!4d")
              let lat = coords[0];
              let lng = coords[1];
              let updatedArray = [...this.state.mapPoints];
              updatedArray[0] = [lat, lng];
              this.setState(
                {
                  EnableMapEvents: false,
                  mapPoints: updatedArray,
                  linkMessage: "Enlace válido",
                  colorlinkMessage: "green"
                },
                () => {
                  console.log(this.state.mapPoints);
                  this.checkLocation();
                }
              );
            } else if (coordsPattern.test(data)) {
              console.log("del back sin 3d y 4d");
              let match = data.match(coordsPattern);
              console.log({ match })
              let coords = match[0].split(",")
              let lat = coords[0];
              let lng = coords[1].replace('+', '');
              let updatedArray = [...this.state.mapPoints];
              updatedArray[0] = [lat, lng];
              this.setState(
                {
                  EnableMapEvents: false,
                  mapPoints: updatedArray,
                  linkMessage: "Enlace válido",
                  colorlinkMessage: "green"
                },
                () => {
                  console.log(this.state.mapPoints);
                  this.checkLocation();
                }
              );
            } else {
              console.log("no valido")
            }
          });
        })
        .catch(error => {
          console.log(error);
        });
    } else if (link.includes(",")) {
      console.log('tiene coordenadas')
      if (pattern2.test(link)) {
        let match = link.match(pattern2);
        console.log("es del tipo 3d, 4d", match);
        let coords = match[0].replace("3d", "").split("!4d")
        let lat = coords[0];
        let lng = coords[1];
        let updatedArray = [...this.state.mapPoints];
        updatedArray[0] = [lat, lng];

        this.setState(
          {
            EnableMapEvents: false,
            mapPoints: updatedArray,
            linkMessage: "Enlace válido",
            colorlinkMessage: "green"
          },
          () => {
            this.checkLocation();
          }
        );
      } else if (coordsPattern.test(link.replace(' ', ''))) {

        console.log("Coordenadas", link);
        let coords = link.split(",")
        let updatedArray = [...this.state.mapPoints];
        updatedArray[0] = [parseFloat(coords[0]),
        parseFloat(coords[1].replace('+', ''))];
        this.setState(
          {
            EnableMapEvents: false,
            mapPoints: updatedArray,
            linkMessage: "Enlace válido",
            colorlinkMessage: "green"
          },
          () => {
            this.checkLocation();
          }
        );
      } else {
        console.log("link no válido")
        this.errorLink = true;
      }
    } else {
      console.log("el enlace no es válido")
      this.setState({
        linkMessage: "El enlace no es válido",
        colorlinkMessage: "red"
      });
    }
    /*if (link.length > 0) {
      let urlreg = /([a-zA-Z]{3}\.)?google\.com(\.\w*)?/;

      if (urlreg.test(link)) {
        const pattern1 = /q=+(-?\d+\.\d+)+%2C+(-?\d+\.\d+)/gm;
        //const pattern2 = /(-?\d+\.\d+)+,+(-?\d+\.\d+)/gm;
        const pattern2 = /3d([+-]?(?=\.\d|\d)(?:\d+)?(?:\.?\d*))(?:[Ee]([+-]?\d+))?!4d([+-]?(?=\.\d|\d)(?:\d+)?(?:\.?\d*))(?:[Ee]([+-]?\d+))?/gm;
        if (pattern1.test(link)) {
          let match = link.match(pattern1);
          console.log("m1", match);
          //https?\:\/\/(maps\.)?google\.[a-z]+\/maps\?q=+(-?\d+\.\d+)+%2C+(-?\d+\.\d+)&+z=+(-?\w{2})
          match[0] = match[0].replace(/[q=]/g, "");
          let lat = match[0].split("%2C")[0];
          let lng = match[0].split("%2C")[1];
          let updatedArray = [...this.state.mapPoints];
          updatedArray[0] = [lat, lng];
          this.setState(
            {
              EnableMapEvents: false,
              mapPoints: updatedArray,
              linkMessage: "Enlace válido",
              colorlinkMessage: "green"
            },
            () => {
              console.log(this.state.mapPoints);
              this.checkLocation();
            }
          );
        } else if (pattern2.test(link)) {
          let match = link.match(pattern2);
          console.log("m2", match);
          let coords = match[0].replace("3d", "").split("!4d")
          let lat = coords[0];
          let lng = coords[1];
          let updatedArray = [...this.state.mapPoints];
          updatedArray[0] = [lat, lng];

          this.setState(
            {
              EnableMapEvents: false,
              mapPoints: updatedArray,
              linkMessage: "Enlace válido",
              colorlinkMessage: "green"
            },
            () => {
              console.log(this.state.mapPoints);
              this.checkLocation();
            }
          );
        } else {
          this.setState({
            linkMessage: "El enlace no es válido",
            colorlinkMessage: "red"
          });
        }
      } else {
        this.setState({
          linkMessage: "El enlace no es válido",
          colorlinkMessage: "red"
        });
      }
    } else {
      this.setState({
        EnableMapEvents: true
      });
    }*/
  };

  handleEnableMapEvents() {
    this.setState({
      EnableMapEvents: true,
      renderCircle: false,
      centerMap: false
    });
  }

  handleDisableMapEvents() {
    this.setState({
      EnableMapEvents: false,
      renderCircle: false,
      centerMap: true
    });
  }

  circleOptions(zoom) {
    let radiusStep;
    // eslint-disable-next-line default-case
    switch (zoom) {
      case 7:
        radiusStep = 774;
        break;
      case 8:
        radiusStep = 662;
        break;
      case 9:
        radiusStep = 500;
        break;
      case 10:
        radiusStep = 375;
        break;
      case 11:
        radiusStep = 187;
        break;
      case 12:
        radiusStep = 125;
        break;
      case 13:
        radiusStep = 62;
        break;
      case 14:
        radiusStep = 33;
        break;
      case 15:
        radiusStep = 12;
        break;
      case 16:
        radiusStep = 6;
        break;
      case 17:
        radiusStep = 3;
        break;
      case 18:
        radiusStep = 1;
        break;
    }
    return radiusStep;
  }

  fly = () => {
    const map = useMap();
    map.flyTo(this.state.mapPoints[0], 16);
  };

  fly2 = () => {
    const map = useMap();
    map.fitBounds(
      [
        [21.575718932458493, -86.55578613281251],
        //north east
        [17.3034434020238, -91.47216796875001]
      ],
      7
    );
  };

  MouseEvents = () => {
    const map = useMap();
    let timerId;
    const myInterval = React.useRef();
    let incremento = 0;
    useMapEvents({
      mousedown: e => {
        incremento = this.circleOptions(map.getZoom());
        timerId = setTimeout(() => {
          map.dragging.disable();
          this.setState(
            {
              renderCircle: false,
              timeOver: true,
              mapPoints: []
            },
            () => {
              let updatedArray = [...this.state.mapPoints];
              updatedArray[0] = [e.latlng.lat, e.latlng.lng];
              this.setState({
                mapPoints: updatedArray
              }, () => {
              });
              let radio = 0;
              myInterval.current = setInterval(() => {
                radio += incremento;
                this.setState({
                  radius: radio,
                  extendRadius: radio * 1.2,
                  renderCircle: true
                });
              }, 10);
            }
          );
        }, 3000);
      },

      mouseup: e => {
        const that = this;
        clearInterval(myInterval.current);
        if (this.state.timeOver === true) {
          this.setState({
            renderCircle: true,
            renderLine: false,
            timeOver: false
          });
          map.dragging.enable();
          clearTimeout(timerId);
          let center = [this.state.mapPoints[0][1], this.state.mapPoints[0][0]];
          let options = {
            steps: 128,
            units: "meters",
            properties: this.state.fillBlueOptions
          };
          let circle = turf.circle(center, this.state.radius, options);
          let bbox = turf.bbox(circle);
          let bounding = [
            [bbox[1], bbox[0]],
            [bbox[3], bbox[2]]
          ];
          that.setState({
            circleBounds: bounding
          }, () => {
            this.checkLocation();
          });
          map.fitBounds(bounding);
        } else {
          clearTimeout(timerId);
        }
      }
    });
  };

  fechas() {
    var curr = new Date();
    curr.setDate(curr.getDate());
    var currdate = curr.toISOString().substring(0, 10);
    this.setState({ date: currdate });
  }

  componentDidMount() {
    this.fechas();
  }











  handleOpenModalImage = () => {
    if (this.state.images.length < 5) {
      this.setState({ showModalImage: true });
    } else {
      this.setState(
        {
          message: "Solo puede seleccionar 5 imágenes"
        },
        () => {
          this.setState({
            showModal: true
          });
        }
      );
    }
  };

  handleCloseModalImage = () => {
    this.setState({ showModalImage: false });
  };

  acceptedImages = incomingImages => {
    console.log(incomingImages);
    var actual = this.state.images;
    console.log(actual);
    if (actual.length + incomingImages.length <= 5) {
      incomingImages.forEach(element => {
        actual.push(element);
      });

      this.setState(
        {
          images: actual,
          showModalImage: false
        },
        () => {
          console.log("images2", this.state.images);
        }
      );
    } else {
      this.setState(
        {
          message: "Solo puede seleccionar 5 imágenes"
        },
        () => {
          this.setState({
            showLoading: false,
            showModal: true,
            showModalImage: false
          });
        }
      );
    }
  };

  handlePicturesChange = event => {
    if (event.target.files.length > 5) {
      this.setState(
        {
          message: "Solo puede seleccionar 5 imágenes"
        },
        () => {
          this.setState({
            showLoading: false,
            showModal: true
          });
        }
      );
    } else {
      var actual = this.state.images;
      var incomingImages = Array.prototype.slice.call(event.target.files);
      console.log(incomingImages);
      if (actual.length + incomingImages.length <= 5) {
        incomingImages.forEach(element => {
          actual.push(element);
        });

        this.setState(
          {
            images: actual,
            showModalImage: false
          },
          () => {
            console.log("images2", this.state.images);
          }
        );
      } else {
        this.setState(
          {
            message: "Solo puede seleccionar 5 imágenes"
          },
          () => {
            this.setState({
              showLoading: false,
              showModal: true
            });
          }
        );
      }
    }
  };

  render() {
    const { t } = this.props;
    const icon = L.icon({
      iconRetinaUrl: iconRetina,
      iconUrl: iconMarker,
      shadowUrl: iconShadow
    });
    return (
      <div className="App p-6 h-full w-full overflow-auto">
        {this.state.showLoading ? (
          <LoadingScreen
            title="Enviando..."
            message="Esto tomará unos segundos, por favor no cierre esta página."
          />
        ) : null}

        <div className=" grid grid-cols-3 gap-4 z-0  h-full">
          <div className="col-span-1 border-current bg-white shadow-md rounded h-full">
            {/* Formulario */}
            <div className="w-full p-px items-center h-full">
              <form
                onSubmit={this.submit}
                className="px-8 pt-6 pb-3 mb-4 content-center  "
                ref={el => (this.myFormRef = el)}
              >
                <label
                  className={`block mb-2 ${this.props.isFontBig ? "text-lg" : "text-sm"} font-medium text-black`}
                  htmlFor="eaf"
                >
                  {t(
                    "subir_archivos.titulos.archivo_de_anotacion",
                    "Archivo de anotación"
                  )}
                </label>
                <input
                  className={`block w-full border-2 rounded-l-full ${this.props.isFontBig ? "text-lg" : "text-sm"} text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 ${this.props.isFontBig ? "file:text-lg" : "file:text-sm"} file:font-semibold file:bg-cdmx-green file:text-white hover:file:bg-cdmx-purple-light file:cursor-pointer`}
                  id="eaf"
                  type="file"
                  name="eaf"
                  accept=".eaf"
                  defaultValue={this.state.eaf}
                  onChange={(e) => this.handleEafChange(e)}
                />

                <label
                  className={`block my-2 ${this.props.isFontBig ? "text-lg" : "text-sm"} font-medium text-black`}
                  htmlFor="multimedia"
                >
                  {t("subir_archivos.titulos.multimedia", "Multimedia")}

                  {this.state.mimeType !== null ? (<span className={`text-red-500 italic font-normal ${this.props.isFontBig ? "text-base" : "text-xs"}`}> (tipo: {this.state.mimeType})</span>) : null}


                </label>
                <input
                  className={`block w-full border-2 rounded-l-full ${this.props.isFontBig ? "text-lg" : "text-sm"} text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 ${this.props.isFontBig ? "file:text-lg" : "file:text-sm"} file:font-semibold file:bg-cdmx-green file:text-white hover:file:bg-cdmx-purple-light file:disabled:bg-gray-500 disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none file:enabled:cursor-pointer`}
                  id="multimedia"
                  type="file"
                  name="multimedia"
                  //accept=".wav"
                  accept={this.state.accept}
                  onChange={this.handleMultimediaChange}
                  defaultValue={this.state.multimedia}
                  disabled={(this.state.mimeType === null || this.state.errors.length > 0) ? true : false}
                />

                <label
                  className={`block my-2 ${this.props.isFontBig ? "text-lg" : "text-sm"} font-medium text-black`}
                  htmlFor="autorizacion"
                >
                  {t(
                    "subir_archivos.titulos.archivo_de_autorizacion",
                    "Archivo de autorización"
                  )}
                </label>
                <input
                  className={`block w-full border-2 rounded-l-full ${this.props.isFontBig ? "text-lg" : "text-sm"} text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 ${this.props.isFontBig ? "file:text-lg" : "file:text-sm"} file:font-semibold file:bg-cdmx-green file:text-white hover:file:bg-cdmx-purple-light file:cursor-pointer`}
                  id="autorizacion"
                  type="file"
                  name="autorizacion"
                  accept="image/*, audio/*, video/*, .pdf, .zip, .rar, .7zip"
                  onChange={this.handleAuthChange}
                  defaultValue={this.state.autorizacion}
                />

                {this.state.projectName !== "" ? (
                  <>
                    <label className={`block text-gray-700 ${this.props.isFontBig ? "text-lg" : "text-sm"} font-bold my-2`}>
                      {t("subir_archivos.titulos.fecha", "Fecha")}
                    </label>
                    <input
                      className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${this.props.isFontBig ? "text-lg" : "text-base"}`}
                      type="date"
                      name="fecha"
                      defaultValue={this.state.date}
                      onChange={this.handleDateChange}
                    />
                    <fieldset
                      className="border border-solid border-gray-300 p-3 overflow-y-auto h-80 mt-8"
                      id="your_div"
                    >
                      <legend>
                        <a
                          className={`bg-infokab-gray hover:bg-infokab-gray/90 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline cursor-pointer ${this.props.isFontBig ? "text-lg" : "text-base"}`}
                          onClick={() => this.addFields()}
                        >
                          {t(
                            "subir_archivos.titulos.agregar_hablante",
                            "Agregar Hablante"
                          )}
                        </a>
                      </legend>
                      {this.state.formFields.map((form, index) => {
                        return (
                          <div
                            key={index}
                            className="lex flex-wrap -mx-3 mb-2  "
                          >
                            <div className="flex flex-wrap  mb-2 p-px">
                              <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                                <label className={`block uppercase tracking-wide text-gray-700 ${this.props.isFontBig ? "text-base" : "text-xs"} font-bold mb-2`}>
                                  {t("subir_archivos.titulos.nombre", "Nombre")}
                                </label>
                                <input
                                  className={`appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 ${this.props.isFontBig ? "text-lg" : "text-base"}`}
                                  name="nombre"
                                  placeholder="Nombre"
                                  onChange={event =>
                                    this.handleFormChange(event, index)
                                  }
                                />
                              </div>

                              <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                                <label className={`block uppercase tracking-wide text-gray-700 ${this.props.isFontBig ? "text-base" : "text-xs"} font-bold mb-2`}>
                                  {t("subir_archivos.titulos.sexo", "Sexo")}
                                </label>
                                <div className="relative">
                                  <select
                                    className={`block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500 ${this.props.isFontBig ? "text-lg" : "text-base"}`}
                                    name="sexo"
                                    onChange={event =>
                                      this.handleFormChange(event, index)
                                    }
                                  >
                                    <option>
                                      {t(
                                        "subir_archivos.opciones_hablantes.sin_especificar",
                                        "Sin especificar"
                                      )}
                                    </option>
                                    <option>
                                      {t(
                                        "subir_archivos.opciones_hablantes.mujer",
                                        "Mujer"
                                      )}
                                    </option>
                                    <option>
                                      {t(
                                        "subir_archivos.opciones_hablantes.hombre",
                                        "Hombre"
                                      )}
                                    </option>
                                  </select>
                                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                    <svg
                                      className="fill-current h-4 w-4"
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 20 20"
                                    >
                                      <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                    </svg>
                                  </div>
                                </div>
                              </div>

                              <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                                <label className={`block uppercase tracking-wide text-gray-700 ${this.props.isFontBig ? "text-base" : "text-xs"} font-bold mb-2`}>
                                  {t("subir_archivos.titulos.edad", "Edad")}
                                </label>
                                <div className="relative">
                                  <select
                                    className={`block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500 ${this.props.isFontBig ? "text-lg" : "text-base"}`}
                                    name="edad"
                                    onChange={event =>
                                      this.handleFormChange(event, index)
                                    }
                                  >
                                    <option>
                                      {t(
                                        "subir_archivos.opciones_hablantes.sin_especificar",
                                        "Sin especificar"
                                      )}
                                    </option>
                                    <option>
                                      {t(
                                        "subir_archivos.opciones_hablantes.infante",
                                        "Infante"
                                      )}
                                    </option>
                                    <option>
                                      {t(
                                        "subir_archivos.opciones_hablantes.joven",
                                        "Joven"
                                      )}
                                    </option>
                                    <option>
                                      {t(
                                        "subir_archivos.opciones_hablantes.adulto",
                                        "Adulto"
                                      )}
                                    </option>
                                    <option>
                                      {t(
                                        "subir_archivos.opciones_hablantes.anciano",
                                        "Anciano"
                                      )}
                                    </option>
                                  </select>
                                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                    <svg
                                      className="fill-current h-4 w-4"
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 20 20"
                                    >
                                      <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                    </svg>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <a
                              className={`text-red-500 background-transparent font-bold uppercase px-6 py-2 outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 cursor-pointer ${this.props.isFontBig ? "text-lg" : "text-sm"}`}
                              onClick={() => this.removeFields(index)}
                            >
                              {t(
                                "subir_archivos.acciones.eliminar",
                                "Eliminar"
                              )}
                            </a>
                            <hr className="mt-3" />
                          </div>
                        );
                      })}
                    </fieldset>
                  </>
                ) : null}
              </form>
              <button
                className={`bg-infokab-gray hover:bg-infokab-gray/90 text-white font-bold py-2 px-2 rounded focus:outline-none focus:shadow-outline mb-3 ${this.props.isFontBig ? "text-lg" : "text-base"}`}
                onClick={this.submit}
              >
                {t("subir_archivos.acciones.enviar", "Enviar")}
              </button>
            </div>
            {/* Formulario */}
          </div>

          <div className="col-span-2 border-current h-full">
            <div className="flex flex-col flex-wrap justify-center h-full">
              {/* Mapa */}
              <div className="flex flex-col h-4/6 rounded-b">
                <div className="bg-white rounded-t pb-2">
                  <label className={`block text-gray-700 ${this.props.isFontBig ? "text-lg" : "text-sm"} font-bold mb-2 mt-1`}>
                    {t("subir_archivos.titulos.ubicacion", "Ubicacion")}
                  </label>

                  <div className="flex relative p-2">
                    <span className="inline-flex items-center px-3 text-sm text-white bg-cdmx-green border border-r-0 border-gray-300 rounded-l-md">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        className="w-6 h-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z"
                        />
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z"
                        />
                      </svg>
                    </span>
                    <input
                      className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${this.props.isFontBig ? "text-lg" : "text-base"}`}
                      type="text"
                      ref={input => (this.inputField = input)}
                      onFocus={() => {
                        this.inputField.value = "";
                        this.handleEnableMapEvents();
                      }}
                      name="googleLink"
                      placeholder="https://maps.app.goo.gl/AH27Ze6zuh8Q1Bwz9"
                      onChange={event => this.handleGoogleLink(event)}
                    />
                    <button
                      onClick={() => {
                        this.inputField.value = "";
                        this.handleEnableMapEvents();
                      }}
                      className="absolute top-2 right-2 p-2 text-sm font-medium text-white bg-red-700 rounded-r-lg border border-red hover:bg-red-800 focus:ring-4 focus:outline-none "
                    >
                      <svg
                        className="h-5 w-5"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </button>
                  </div>
                  <label className={`${this.props.isFontBig ? "text-lg" : "text-sm"}  `} style={{ backgroundColor: this.colorlinkMessage }}>{this.state.linkMessage}</label>
                </div>
                <div className=" map ">
                  <MapContainer
                    center={this.state.center}
                    zoom={7}
                    bounds={[
                      //south west
                      [21.575718932458493, -86.55578613281251],
                      //north east
                      [17.3034434020238, -91.47216796875001]
                    ]}
                    minZoom={7}
                  >
                    <TileLayer
                      attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />

                    {this.state.mapPoints.length === 1 &&
                      this.state.EnableMapEvents === false ? (
                      <Marker position={this.state.mapPoints[0]} icon={icon}>
                        <this.fly />
                      </Marker>
                    ) : null}

                    {this.state.centerMap === true ? (
                      <this.fly2></this.fly2>
                    ) : null}

                    {this.state.EnableMapEvents ? <this.MouseEvents /> : null}

                    {this.state.renderCircle ? (
                      <Circle
                        center={this.state.mapPoints[0]}
                        pathOptions={this.state.fillBlueOptions}
                        radius={this.state.radius}
                      />
                    ) : null}

                    {this.state.renderCircle ? (
                      <Circle
                        center={this.state.mapPoints[0]}
                        pathOptions={this.state.blackOptions}
                        radius={this.state.extendRadius}
                      />
                    ) : null}
                  </MapContainer>
                </div>
              </div>
              {/* Mapa */}
              <div className="flex flex-col h-2/6 pt-4">
                {/* Galería */}
                {this.state.showModalImage ? (
                  <DragNDrop
                    acceptedImages={this.acceptedImages}
                    handleClose={this.handleCloseModalImage}
                    isFontBig={this.props.isFontBig}
                  />
                ) : null}

                <div className="grid grid-cols-1 h-full overflow-y">
                  <div className="flex relative justify-center items-center w-full border-current bg-white shadow-md rounded p-2 h-30 ">
                    <button
                      onClick={this.handleOpenModalImage}
                      type="button"
                      className={`bottom-1 right-1 z-10 absolute dark:focus:ring-[#4285F4]/55 mr-2 mb-2 text-white bg-cdmx-gray hover:bg-cdmx-gray/90 focus:ring-4 focus:outline-none focus:ring-[#4285F4]/50 font-medium rounded-lg px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-[#4285F4]/55 ${this.props.isFontBig ? "text-lg" : "text-sm"}`}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        className="mr-2 -ml-1 w-4 h-4"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M6.827 6.175A2.31 2.31 0 015.186 7.23c-.38.054-.757.112-1.134.175C2.999 7.58 2.25 8.507 2.25 9.574V18a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18V9.574c0-1.067-.75-1.994-1.802-2.169a47.865 47.865 0 00-1.134-.175 2.31 2.31 0 01-1.64-1.055l-.822-1.316a2.192 2.192 0 00-1.736-1.039 48.774 48.774 0 00-5.232 0 2.192 2.192 0 00-1.736 1.039l-.821 1.316z"
                        />
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M16.5 12.75a4.5 4.5 0 11-9 0 4.5 4.5 0 019 0zM18.75 10.5h.008v.008h-.008V10.5z"
                        />
                      </svg>
                      {t(
                        "subir_archivos.acciones.subir_imagenes",
                        "Subir Imagenes"
                      )}
                    </button>
                    {this.state.images.length > 0 ? (
                      <div className="container grid grid-cols-5 gap-2 ">
                        {this.state.images.map((file, index) => (
                          <div
                            key={index}
                            className="w-full relative bg-slate-50 border-2 rounded "
                          >
                            {file.type.split("/")[0] === "image" ? (
                              <>
                                <img
                                  key={index}
                                  alt=""
                                  className="object-contain h-28 w-full  p-1"
                                  src={this.createURL(file)}
                                />
                                <div class=" flex justify-center items-center select-none text-black ">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth="1.5"
                                    stroke="currentColor"
                                    className="w-4 h-4 "
                                  >
                                    <path
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      d="M6.827 6.175A2.31 2.31 0 015.186 7.23c-.38.054-.757.112-1.134.175C2.999 7.58 2.25 8.507 2.25 9.574V18a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18V9.574c0-1.067-.75-1.994-1.802-2.169a47.865 47.865 0 00-1.134-.175 2.31 2.31 0 01-1.64-1.055l-.822-1.316a2.192 2.192 0 00-1.736-1.039 48.774 48.774 0 00-5.232 0 2.192 2.192 0 00-1.736 1.039l-.821 1.316z"
                                    />
                                    <path
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      d="M16.5 12.75a4.5 4.5 0 11-9 0 4.5 4.5 0 019 0zM18.75 10.5h.008v.008h-.008V10.5z"
                                    />
                                  </svg>
                                </div>
                                <button
                                  type="button"
                                  className="absolute rounded-full -top-1 -right-1 focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium text-sm dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900"
                                  onClick={() => this.deleteImage(index)}
                                >
                                  <svg
                                    className="h-3 w-3"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    aria-hidden="true"
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth="2"
                                      d="M6 18L18 6M6 6l12 12"
                                    />
                                  </svg>
                                </button>
                              </>
                            ) : null}
                            {file.type.split("/")[0] === "video" ? (
                              <>
                                <video className="object-contain h-28 w-full  p-1 ">
                                  <source
                                    src={this.createURL(file)}
                                    type="video/mp4"
                                  />
                                </video>
                                <div className=" flex justify-center items-center select-none text-black ">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth="1.5"
                                    stroke="currentColor"
                                    className="w-4 h-4"
                                  >
                                    <path
                                      strokeLinecap="round"
                                      d="M15.75 10.5l4.72-4.72a.75.75 0 011.28.53v11.38a.75.75 0 01-1.28.53l-4.72-4.72M4.5 18.75h9a2.25 2.25 0 002.25-2.25v-9a2.25 2.25 0 00-2.25-2.25h-9A2.25 2.25 0 002.25 7.5v9a2.25 2.25 0 002.25 2.25z"
                                    />
                                  </svg>
                                </div>
                                <button
                                  type="button"
                                  className="absolute rounded-full -top-1 -right-1 focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium text-sm dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900"
                                  onClick={() => this.deleteImage(index)}
                                >
                                  <svg
                                    className="h-3 w-3"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    aria-hidden="true"
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth="2"
                                      d="M6 18L18 6M6 6l12 12"
                                    />
                                  </svg>
                                </button>
                              </>
                            ) : null}
                          </div>
                        ))}
                      </div>
                    ) : (
                      <div className="w-full h-20 relative ">
                        <h2 className={`object-contain mt-8 text-center h-full w-full ${this.props.isFontBig ? "text-xl" : "text-base"}`}>
                          {t(
                            "subir_archivos.mensajes.sin_imagenes",
                            "SIN IMÁGENES AGREGADAS"
                          )}
                        </h2>
                      </div>
                    )}
                  </div>
                </div>
                {/* Galería */}
              </div>
            </div>
          </div>
          {this.state.showModal ? (
            <>
              <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                <div className="relative w-auto my-6 mx-auto max-w-3xl">
                  {/*content*/}
                  <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                    {/*body*/}
                    <div className="relative p-6 flex-auto">
                      <p className={`my-4 text-slate-500 ${this.props.isFontBig ? "text-2xl" : "text-lg"} leading-relaxed`}>
                        <b>{this.state.message}</b>
                        <hr></hr>
                      </p>
                      <ul>
                        {this.state.errors.map((error) => (
                          <li><b>{error} </b></li>
                        ))}
                      </ul>
                      {this.state.errors.length > 0 ? (
                        <p className="text-xs mt-4 font-thin italic">(en minusculas y sin acentos)</p>
                      ) : null}
                    </div>
                    {/*footer*/}
                    <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                      <button
                        className={`text-red-500 background-transparent font-bold uppercase px-6 py-2 ${this.props.isFontBig ? "text-lg" : "text-sm"} outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150`}
                        type="button"
                        onClick={this.handleCloseModalChange}
                      >
                        {t("subir_archivos.acciones.cerrar", "Cerrar")}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
            </>
          ) : null}
        </div>
      </div>
    );
  }
}

export default withTranslation()(UploadForm);

import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { format } from "date-fns";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
//import "./SwiperStyles.css";

// import required modules
import { Autoplay } from "swiper";

import MarkerClusterGroup from "react-leaflet-cluster";
import {
  useMap,
  MapContainer,
  TileLayer,
  Rectangle,
  Marker
} from "react-leaflet";
import L from "leaflet";
import iconMarker from "leaflet/dist/images/marker-icon.png";
import iconRetina from "leaflet/dist/images/marker-icon-2x.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";

import "./uploadForm.css";
import "leaflet/dist/leaflet.css";
import Modal from "./Modal";

class SearchMap extends React.Component {
  constructor() {
    super();
    this.state = {
      searchValue: "",
      focusValue: "",
      documents: [],
      diff_location: [],
      diff_location_points: [],
      showPagination: false,
      currentPage: 1,
      maxPage: 1,
      showDownload: false,
      showConfigMenu: false,
      selectedIndex: "maya",
      showLegend: true,
      queryType: false,
      showModalMap: false,
      lat: 20.97537,
      lng: -89.61696,
      showEmailModal: false,
      downloadUserEmail: "",
      position: [51.505, -0.09],
      bbox: [
        [-91.461182, 21.698265],
        [-91.417236, 21.728886]
      ]
    };

    this.fetchDocs = this.fetchDocs.bind(this);
    this.fetchNextPage = this.fetchNextPage.bind(this);
    this.fetchPreviousPage = this.fetchPreviousPage.bind(this);
    this.fetchPage = this.fetchPage.bind(this);

    this.toggleConfigMenu = this.toggleConfigMenu.bind(this);
    this.downloadResult = this.downloadResult.bind(this);
    this.showDownloadModal = this.showDownloadModal.bind(this);
    this.hideDownloadModal = this.hideDownloadModal.bind(this);
  }

  handleSearchChange = event => {
    this.setState({
      searchValue: event.target.value
    });
  };

  formatDate = dateProject => {
    const date = new Date(dateProject);
    const dateOffset = new Date(
      date.valueOf() + date.getTimezoneOffset() * 60 * 1000
    );

    return format(dateOffset, "dd/MM/yyyy");
  };

  fetchDocs() {
    let payload = {
      text: this.state.searchValue,
      index: this.state.selectedIndex,
      levenshtein: this.state.queryType
    };

    this.setState(
      {
        documents: [],
        focusValue: this.state.searchValue,
        currentPage: 1,
        showConfigMenu: false
      },
      () => {
        fetch("api/search/multiple", {
          method: "POST",
          body: JSON.stringify(payload),
          headers: {
            "Content-Type": "application/json"
          }
        })
          /* .then(response => response.json())
        .then(
          data => {
            console.log(data);
            this.setState({
              documents: data.documents
            });
            let totalHits = data.totalHits;
  
            if (totalHits > 10) {
              let maxPage = Math.ceil(totalHits / 10);
              this.setState({
                showPagination: true,
                maxPage: maxPage
              });
            } else {
              this.setState({
                showPagination: false
              });
            }
          },
          error => {
            console.log(error);
          }
        ); */
          .then(response => {
            if (!response.ok) {
              console.log(response);
              response.json().then(res => {
                console.log(res);
              });
              throw new Error("Not 2xx response", { cause: response });
            } else {
              response.json().then(data => {
                console.log(data);
                let noDupes = data.documents.filter(
                  (arr, index, self) =>
                    index ===
                    self.findIndex(
                      t => t.text === arr.text && t.fileName === arr.fileName
                    )
                );

                let diff_location = [];
                //let diff_location_points = {}
                let diff_location_points = [];
                noDupes.forEach(document => {
                  let text =
                    document.localidad +
                    " " +
                    document.municipio +
                    " " +
                    document.entidad;
                  let r = document.bbox
                    .replace("BOX(", "")
                    .replace(")", "")
                    .split(",");
                  let southWest = r[1].split(" ");
                  let northEast = r[0].split(" ");
                  // diff_location[text] = [
                  //   [southWest[1], southWest[0]],
                  //   [northEast[1], northEast[0]]
                  // ]

                  diff_location.push([
                    [southWest[1], southWest[0]],
                    [northEast[1], northEast[0]]
                  ]);

                  //diff_location_points[text] =  JSON.parse("[" + document.coordinates + "]")
                  let info = {
                    position: JSON.parse("[" + document.coordinates + "]"),
                    properties: document
                  };
                  diff_location_points.push(info);
                });

                this.setState({
                  documents: noDupes,
                  diff_location: diff_location,
                  diff_location_points: diff_location_points
                });

                let totalHits = data.totalHits;

                if (totalHits > 10) {
                  let maxPage = Math.ceil(totalHits / 10);
                  this.setState({
                    showPagination: true,
                    maxPage: maxPage,
                    showDownload: totalHits > 0 ? true : false,
                    showLegend: totalHits === 0 ? true : false
                  });
                } else {
                  this.setState({
                    showPagination: false,
                    showDownload: totalHits > 0 ? true : false,
                    showLegend: totalHits === 0 ? true : false
                  });
                }
              });
            }
          })
          .catch(error => {
            console.log(error.cause);
          });
      }
    );
  }

  fetchPreviousPage() {
    if (this.state.currentPage > 1) {
      let newPage = this.state.currentPage - 1;
      this.setState(
        {
          currentPage: newPage
        },
        this.fetchPage
      );
    }
  }

  fetchNextPage() {
    if (this.state.currentPage < this.state.maxPage) {
      let newPage = this.state.currentPage + 1;
      this.setState(
        {
          currentPage: newPage
        },
        this.fetchPage
      );
    }
  }

  fetchPage() {
    this.setState(
      {
        documents: []
      },
      () => {
        let payload = {
          text: this.state.searchValue,
          page: this.state.currentPage,
          index: this.state.selectedIndex,
          levenshtein: this.state.queryType
        };
        fetch("api/search/multiple/page", {
          method: "POST",
          body: JSON.stringify(payload),
          headers: {
            "Content-Type": "application/json"
          }
        })
          .then(response => {
            response.json().then(data => {
              this.setState({
                documents: data
              });
            });
          })
          .catch(error => {
            console.log(error);
          });
      }
    );
  }

  getImageFolder = (ruta, imagen) => {
    let src = ruta.replace("multimedia", "Images") + imagen;
    return src;
  };

  getDownloadLink = (ruta, archivo, extension) => {
    let src = ruta + archivo + "." + extension;
    return src;
  };

  getHighlightedText(text) {
    const parts2 = text.split(" ");
    const search_parts = this.state.focusValue
      .toLowerCase()
      .replace(/[^a-zA-Z0-9 ]/g, "")
      .split(" ");

    return (
      <span>
        {parts2.map((part, i) => (
          <span
            key={i}
            style={
              search_parts.includes(
                part.toLowerCase().replace(/[^a-zA-Z0-9 ]/g, "")
              )
                ? { fontWeight: "bold", color: "black", fontStyle: "italic" }
                : {}
            }
          >
            {" "}
            {part}{" "}
          </span>
        ))}{" "}
      </span>
    );
  }

  getHighlightedTextV2(text) {
    const parts2 = text.split(" ");
    const search_parts = this.state.focusValue
      .toLowerCase()
      .replace(/[^a-zA-Z0-9 ]/g, "")
      .split(" ");

    let html = "<span>";

    parts2.map((part, i) => {
      if (
        search_parts.includes(part.toLowerCase().replace(/[^a-zA-Z0-9 ]/g, ""))
      ) {
        console.log("data!!!");
        html += `
             <span style="fontWeight: 'bold'; color: 'black'; fontStyle: 'italic'">
              ${part}
              </span>
            `;
      } else {
        html += `
              <span>
              ${part}
              </span>
            `;
      }
    });

    html += "</span>";
    return html;
  }

  getHighlightedText2(text) {
    const parts2 = text.split(" ");
    const search_parts = this.state.focusValue
      .toLowerCase()
      .replace(/[^a-zA-Z0-9 ]/g, "")
      .split(" ");
    return (
      <span>
        {" "}
        {parts2.map((part, i) => (
          <span
            key={i}
            style={
              search_parts.includes(
                part.toLowerCase().replace(/[^a-zA-Z0-9 ]/g, "")
              )
                ? { fontWeight: "bold", color: "yellow" }
                : { color: "white" }
            }
          >
            {part}{" "}
          </span>
        ))}{" "}
      </span>
    );
  }

  toggleConfigMenu = evt => {
    this.setState({
      showConfigMenu: !this.state.showConfigMenu
    });
  };

  handleCheckIndex = evt => {
    let selectedValue = evt.target.value;

    this.setState({
      selectedIndex: selectedValue,
      showConfigMenu: !this.state.showConfigMenu
    });
  };

  handleCheckQuery = evt => {
    let selectedValue = evt.target.checked;

    this.setState({
      queryType: selectedValue,
      showConfigMenu: !this.state.showConfigMenu
    });
  };

  showMiniMap = (coordinates, bbox) => {
    let r = bbox.replace("BOX(", "").replace(")", "").split(",");
    let southWest = r[1].split(" ");
    let northEast = r[0].split(" ");
    let partes = JSON.parse("[" + coordinates + "]");
    this.setState(
      {
        lat: partes[0],
        lng: partes[1],
        bbox: [
          [southWest[1], southWest[0]],
          [northEast[1], northEast[0]]
        ]
      },
      () => {
        this.setState({
          showModalMap: true
        });
      }
    );
  };

  hideModalMap = () => {
    this.setState({
      showModalMap: false,
      center: null
    });
  };

  fly = () => {
    const map = useMap();
    map.fitBounds(this.state.bbox);
  };

  algo = () => {
    console.log(this.state.bbox);
  };

  downloadResult() {
    let payload = {
      text: this.state.searchValue,
      index: this.state.selectedIndex
    };

    fetch("api/download", {
      method: "POST",
      body: JSON.stringify(payload),
      headers: {
        "Content-Type": "application/json"
      }
    })
      .then(response => {
        if (response.ok) {
          this.hideDownloadModal();
        }
      })
      .catch(error => {
        console.log(error);
      });
  }

  showDownloadModal() {
    this.setState({
      showEmailModal: true
    });
  }

  hideDownloadModal() {
    this.setState({
      showEmailModal: false
    });
  }

  updateDownloadUserEmail = e => {
    this.setState({
      downloadUserEmail: e.target.value
    });
  };

  render() {
    const icon = L.icon({
      iconRetinaUrl: iconRetina,
      iconUrl: iconMarker,
      shadowUrl: iconShadow
    });

    return (
      <div className="relative w-full px-4 py-3 h-full bg-cdmx-gray">
        <div className="flex flex-col flex-grow">
          <div className="flex flex-col items-center justify-center pt-8 w-full">
            <h1 className="text-5xl font-bold tracking-wider pb-1 text-white">
              Buscador
            </h1>
            <div className="w-full flex items-center  justify-center pb-2">
              <span className="flex capitalize w-20 items-center rounded-l text-sm  text-zinc-500 italic bg-zinc-300   border-cdmx-green px-3 py-2 border-b-2 border-t-2 border-l-2">
                <span className="text-center w-full">
                  {this.state.selectedIndex}
                </span>
              </span>
              <input
                className="w-1/3 outline-0 bg-white  border-cdmx-green border-b-2 border-t-2 border-r-2 px-2 py-2 leading-tight"
                type="text"
                name="search"
                id="search"
                value={this.state.searchValue}
                onChange={this.handleSearchChange}
              ></input>
              <button
                className="px-4 py-2 bg-cdmx-green text-white font-bold"
                type="button"
                onClick={this.fetchDocs}
              >
                Buscar
              </button>
              <div className="relative">
                <button
                  className="bg-teal-600 px-4 py-2 text-white rounded-r"
                  onClick={this.toggleConfigMenu}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6 fill-current"
                  >
                    <path d="m9.25 22-.4-3.2q-.325-.125-.612-.3-.288-.175-.563-.375L4.7 19.375l-2.75-4.75 2.575-1.95Q4.5 12.5 4.5 12.337v-.675q0-.162.025-.337L1.95 9.375l2.75-4.75 2.975 1.25q.275-.2.575-.375.3-.175.6-.3l.4-3.2h5.5l.4 3.2q.325.125.613.3.287.175.562.375l2.975-1.25 2.75 4.75-2.575 1.95q.025.175.025.337v.675q0 .163-.05.338l2.575 1.95-2.75 4.75-2.95-1.25q-.275.2-.575.375-.3.175-.6.3l-.4 3.2Zm2.8-6.5q1.45 0 2.475-1.025Q15.55 13.45 15.55 12q0-1.45-1.025-2.475Q13.5 8.5 12.05 8.5q-1.475 0-2.488 1.025Q8.55 10.55 8.55 12q0 1.45 1.012 2.475Q10.575 15.5 12.05 15.5Zm0-2q-.625 0-1.062-.438-.438-.437-.438-1.062t.438-1.062q.437-.438 1.062-.438t1.063.438q.437.437.437 1.062t-.437 1.062q-.438.438-1.063.438ZM12 12Zm-1 8h1.975l.35-2.65q.775-.2 1.438-.588.662-.387 1.212-.937l2.475 1.025.975-1.7-2.15-1.625q.125-.35.175-.738.05-.387.05-.787t-.05-.788q-.05-.387-.175-.737l2.15-1.625-.975-1.7-2.475 1.05q-.55-.575-1.212-.963-.663-.387-1.438-.587L13 4h-1.975l-.35 2.65q-.775.2-1.437.587-.663.388-1.213.938L5.55 7.15l-.975 1.7 2.15 1.6q-.125.375-.175.75-.05.375-.05.8 0 .4.05.775t.175.75l-2.15 1.625.975 1.7 2.475-1.05q.55.575 1.213.962.662.388 1.437.588Z" />
                  </svg>
                </button>
                {/* Búsqueda avanzada */}
                <div
                  className={`${
                    this.state.showConfigMenu ? "flex" : "hidden"
                  } flex-col absolute top-0 left-14 bg-white rounded px-4 py-2 z-50 divide-y divide-gray-100 shadow w-32`}
                >
                  {/* <span className="text-lg font-bold">Configuración</span> */}
                  <div className="flex flex-col">
                    <span className="text-lg font-semibold border-b-2 mb-2">
                      Índices:
                    </span>
                    <ul>
                      <li>
                        <div className="flex items-center mb-2">
                          <input
                            id="indexMaya"
                            type="radio"
                            value="maya"
                            name="index"
                            checked={this.state.selectedIndex === "maya"}
                            onChange={this.handleCheckIndex}
                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300"
                          />
                          <label
                            htmlFor="indexMaya"
                            className="ml-2 text-sm font-medium text-gray-900"
                          >
                            Maya
                          </label>
                        </div>
                      </li>
                      <li>
                        <div className="flex items-center mb-2">
                          <input
                            id="indexEspañol"
                            type="radio"
                            value="español"
                            name="index"
                            checked={this.state.selectedIndex === "español"}
                            onChange={this.handleCheckIndex}
                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300"
                          />
                          <label
                            htmlFor="indexEspañol"
                            className="ml-2 text-sm font-medium text-gray-900"
                          >
                            Español
                          </label>
                        </div>
                      </li>
                      <li>
                        <div className="flex items-center mb-2">
                          <input
                            id="indexGlosado"
                            type="radio"
                            value="glosado"
                            name="index"
                            checked={this.state.selectedIndex === "glosado"}
                            onChange={this.handleCheckIndex}
                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300"
                          />
                          <label
                            htmlFor="indexGlosado"
                            className="ml-2 text-sm font-medium text-gray-900"
                          >
                            Glosado
                          </label>
                        </div>
                      </li>
                    </ul>
                  </div>

                  <div className="flex flex-col">
                    <span className="text-lg font-semibold border-b-2 mb-2">
                      Tipo:
                    </span>
                    <ul>
                      <li>
                        <div className="flex items-center mb-2">
                          <input
                            id="queryType"
                            type="checkbox"
                            value="Levenshtein"
                            name="queryType"
                            checked={this.state.queryType}
                            onChange={this.handleCheckQuery}
                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300"
                          />
                          <label
                            htmlFor="queryType"
                            className="ml-2 text-sm font-medium text-gray-900"
                          >
                            Levenshtein
                          </label>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="" style={{ height: "calc(100vh - 15rem)" }}>
            <div
              className={`flex w-full items-center justify-center py-3 relative`}
            >
              <div
                className={`${
                  this.state.showDownload ? "flex" : "hidden"
                } pb-0`}
              >
                <button
                  className="px-4 py-2 font-bold rounded bg-teal-600 text-white mx-2"
                  onClick={this.showDownloadModal}
                >
                  Descargar resultados
                </button>
              </div>
            </div>

            <div className="flex flex-wrap">
              <div
                className={`${
                  this.state.showLegend ? "flex" : "hidden"
                } w-full items-center justify-center`}
              >
                No se encontraron coincidencias.
              </div>
            </div>

            <div className={`w-5/5 h-5/6 z-50 bg-gray-800/60 `}>
              <div className=" map ">
                <MapContainer
                  center={[this.state.lat, this.state.lng]}
                  bounds={this.state.bbox}
                  zoom={11}
                  onClick={this.algo}
                >
                  <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  />
                  {/* {Object.keys(this.state.diff_location).map((location, index) => {
                      return (
                        <>
                          <Rectangle bounds={this.state.diff_location[location]} />
                        </>
                      );
                    })} */}

                  <MarkerClusterGroup
                    chunkedLoading
                    onMouseOver={e => {
                      let layer = e.layer.getAllChildMarkers();
                      let html = "";

                      html += `<div class="overflow-auto">`;

                      layer.forEach((documents, index) => {
                        let document = documents["options"]["data"];
                        console.log(document);
                        html += `
                                        <div
                                        class="px-2 mb-4 w-10/12 mx-auto break-all"
                                        key={index}
                                      >
                                        <div class="md:flex bg-white p-2 rounded-lg border-4 hover:border-cdmx-green">
                                          <div class="flex flex-col w-full pl-4 ">
                                            <div class="flex w-ful justify-end">
                                              <span>
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  fill="none"
                                                  viewBox="0 0 24 24"
                                                  strokeWidth="1.5"
                                                  stroke="currentColor"
                                                  class="w-6 h-6"
                                                >
                                                  <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z"
                                                  />
                                                </svg>
                                              </span>
                                              <span class="ml-2">
                                                ${this.formatDate(
                                                  document.fecha_archivo
                                                )}
                                              </span>
                                            </div>
                                            <div class="flex h-3/5 w-full justify-between items-center">
                                              <span class="font-medium tracking-wide pb-2 text-xl text-center w-full h-full text-zinc-600 items-center break-normal">
                                                ${this.getHighlightedTextV2(
                                                  document.text
                                                )}
                                              </span>
                                            </div>
                                            ${
                                              document.subText !== "" ? (
                                                <div class="flex h-3/5 w-full justify-between items-center ">
                                                  <span class="font-normal tracking-wide p-2 italic text-center w-full bg-cdmx-gray  items-center break-normal">
                                                    <span class="tex-white">
                                                      $
                                                      {this.getHighlightedText2(
                                                        document.subText
                                                      )}
                                                    </span>
                                                  </span>
                                                </div>
                                              ) : null
                                            }
                                            <div class="flex h-1/5 w-full ">
                                              <div class="flex h-1/2 w-full">
                                                <div class="flex flex-row h-1/2 w-1/3 justify-center">
                                                  <div class="justify-center">
                                                    <span class="">
                                                      <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        class="h-6 w-6 mx-auto"
                                                        fill="none"
                                                        viewBox="0 0 24 24"
                                                        strokeWidth="1.5"
                                                        stroke="currentColor"
                                                      >
                                                        <path
                                                          strokeLinecap="round"
                                                          strokeLinejoin="round"
                                                          d="M2.25 18L9 11.25l4.306 4.307a11.95 11.95 0 015.814-5.519l2.74-1.22m0 0l-5.94-2.28m5.94 2.28l-2.28 5.941"
                                                        />
                                                      </svg>
                                                    </span>
                                                    <span class="block text-xs">
                                                      Score: ${document.score}
                                                    </span>
                                                  </div>
                                                </div>
                                                <div class="flex flex-row h-1/2 w-1/3 justify-center">
                                                  <div class="justify-center">
                                                    <span class="">
                                                      <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        class="h-6 w-6 mx-auto"
                                                        fill="none"
                                                        viewBox="0 0 24 24"
                                                        strokeWidth="1.5"
                                                        stroke="currentColor"
                                                      >
                                                        <path
                                                          strokeLinecap="round"
                                                          strokeLinejoin="round"
                                                          d="M18 18.72a9.094 9.094 0 003.741-.479 3 3 0 00-4.682-2.72m.94 3.198l.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0112 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 016 18.719m12 0a5.971 5.971 0 00-.941-3.197m0 0A5.995 5.995 0 0012 12.75a5.995 5.995 0 00-5.058 2.772m0 0a3 3 0 00-4.681 2.72 8.986 8.986 0 003.74.477m.94-3.197a5.971 5.971 0 00-.94 3.197M15 6.75a3 3 0 11-6 0 3 3 0 016 0zm6 3a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0zm-13.5 0a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0z"
                                                        />
                                                      </svg>
                                                    </span>
                                                    <span class="block text-xs">
                                                      hablantes
                                                    </span>
                                                  </div>
                                                </div>
                    
                                              <div class="flex flex-row h-1/2 w-1/3 justify-center">
                                                  <span class="">
                                                    <svg
                                                      xmlns="http://www.w3.org/2000/svg"
                                                      class="h-6 w-6 mx-auto"
                                                      fill="none"
                                                      viewBox="0 0 24 24"
                                                      strokeWidth="1.5"
                                                      stroke="currentColor"
                                                    >
                                                      <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z"
                                                      />
                                                      <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z"
                                                      />
                                                    </svg>
                                                  </span>
                                                  <span class="block text-center items-center text-xs">
                                                    ${document.localidad}, ${
                          document.municipio
                        }
                                                    <br />
                                                    ${document.entidad}
                                                  </span>
                                                </div>
                                              </div>
                                            </div>
                                            <div class="flex w-full justify-center"></div>
                                          </div>
                                        </div>
                                      </div>
                                  `;
                      });

                      html += `</div>`;
                      console.log("LAYER", layer);
                      e.propagatedFrom.bindTooltip(html).openTooltip();
                    }}
                    onMouseOut={e => {
                      //e.propagatedFrom.unbindTooltip();
                    }}
                  >
                    {Object.keys(this.state.diff_location_points).map(
                      (location, index) => {
                        return (
                          <>
                            <Marker
                              data={
                                this.state.diff_location_points[location]
                                  .properties
                              }
                              position={
                                this.state.diff_location_points[location]
                                  .position
                              }
                              icon={icon}
                            ></Marker>
                          </>
                        );
                      }
                    )}
                  </MarkerClusterGroup>

                  {/* <this.fly></this.fly> */}
                </MapContainer>
              </div>
            </div>

            <div
              className={`flex w-full items-center justify-center py-3 relative`}
            >
              <div
                className={`${this.state.showPagination ? "flex" : "hidden"}`}
              >
                <button
                  className="px-4 py-2 font-bold rounded bg-teal-600 text-white mx-2"
                  onClick={this.fetchPreviousPage}
                >
                  Anterior
                </button>
                <div className="px-4 py-2 font-bold rounded bg-teal-600 text-white">
                  {this.state.currentPage}
                </div>
                <button
                  className="px-4 py-2 font-bold rounded bg-teal-600 text-white mx-2"
                  onClick={this.fetchNextPage}
                >
                  Siguiente
                </button>
              </div>
            </div>
            <footer className="footer px-4 py-6">
              <div className="footer-content">
                <p className="text-sm text-gray-600 text-center">
                  © T'aantsil 2023. All rights reserved.{" "}
                  <a href="#">Centrogeo</a>
                </p>
              </div>
            </footer>
          </div>
        </div>

        {/*<MapModal
          show={this.state.showModalMap}
          handleClose={this.hideModalMap}
          lat={this.state.lat}
          lng={this.state.lng}

        >
        </MapModal>*/}

        <Modal
          show={this.state.showEmailModal}
          handleClose={this.hideDownloadModal}
          size="w-1/4"
          actions={[this.downloadResult]}
          labels={["Enviar"]}
        >
          <div className="flex flex-col">
            <span className="text-xl font-bold tracking-wider mb-2">
              Descarga de resultados de búsqueda
            </span>
            <div className="flex flex-col mb-4">
              <label
                htmlFor="download-email"
                className="block text-base font-medium text-gray-700"
              >
                Ingresa tu correo eléctronico:
              </label>
              <input
                type={"text"}
                name="download-email"
                className="mt-2 px-3 py-1.5 text-base font-normal border border-solid border-gray-300 rounded resize-none"
                value={this.state.downloadUserEmail}
                onChange={this.updateDownloadUserEmail}
              />
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

export default SearchMap;
